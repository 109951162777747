module.exports = {
  context: {
    url: '/en/financing/isa-miami',
    lang: 'en',
    breadcrumb: 'ISA Miami',
    isIsa: true,
    hreflangs: {
      en: '/en/financing/isa-miami',
      pt: '/pt/financiamento/isa-lisbon',
    },
    seo: {
      deindexed: true,
      title:
        "Finance your coding bootcamp with Ironhack's ISA. No up-front cost.",
      description:
        'Ironhack\'s ISA "Income Share Agreement" gives you the ability to attend our coding or design bootcamps with no-up-front cost. Pay your tuition only once you\'ve landed a job making $40k/year or more.',
    },
    header: {
      title: 'The leading coding bootcamp',
      titleHighlighted: 'now with no up-front cost',
      backgroundImage: 'isa/header.png',
      paragraph:
        "Take a full-time Ironhack course at no up-front cost. With Ironhack's Income Share Agreements, you pay your tuition only when you've landed a job making $40K/year or more.",
    },
    navbar: {
      text: [
        '· No up-front costs!',
        "· Only pay when you've landed a new job",
        '· #2 world-rated bootcamp courses',
        '· A new career in just 9-weeks!',
      ],
      linkHref:
        'https://www.ironhack.com/en/courses/ux-ui-design-bootcamp-learn-ux-design/apply',
    },
    video: {
      href: 'https://www.youtube.com/embed/OXZWp1eVedo',
    },
    valueProposition: {
      content: [
        {
          title: "What is Ironhack's ISA?",
          text: [
            'ISA stands for "income share agreement" - it is an agreement in which you receive education funding in exchange for a fixed percentage of your post-graduation income over a pre-defined period of time. Ironhack\'s ISA program gives you the opportunity to take an Ironhack bootcamp at no cost until you land a job. Payment only begins once you secure a role making at least $40K/year ($3,333.33/month).',
          ],
        },
        {
          title: "Your journey with Ironhack's ISA",
          text: [
            'Concentrate on class instead of tuition payments.',
            "Once you graduate, you'll start looking for a job with the support of our Outcomes team.",
            'You pay 12% of your monthly earned income for 48 months or up to 1.75x the cost of tuition, whichever happens first.',
          ],
          isList: true,
        },
      ],
    },
    extraText: {
      content: [
        {
          title: 'Who is eligible to apply for an Ironhack ISA?',
          text: [
            "<b>Ironhack's ISA program is available to individuals who are:</b>",
            "· Incoming Full-time Web Dev and Full-time UX students for cohorts in August or October 2019 at Ironhack Miami's campus in the United States.",
            '· At the time of application are at least 18 years of age on the date of entry.',
            '· A legal resident of the United States.',
            '<b>Once students are deemed eligible, they must complete the following additional application steps in order to be approved:</b>',
            '· Participate in the personal interview with the Ironhack team.',
            '· Take and pass a skills assesment.',
            '· Meet credit check criteria.',
            '· Complete WD or UX pre-work.',
            "<small>Please note: Ironhack's ISA is void wherever restricted or prohibited at the terms and conditions specified in the ISA agreement or by law. Applicant’s rights and responsibilities may vary by jurisdiction.</small>",
          ],
        },
        {
          title: 'How can I apply to this program?',
          text: [
            '1. Fill out the application form.',
            '2. Meet the Ironhack team for a personal interivew.',
            '3. Receive your tech assesment study material via email from us.',
            '4. Pass your virtual technical assessment.',
            '5. If you are selected, review and complete your ISA agreement.',
            '6. Get started on your pre-work!.',
          ],
        },
        {
          title: 'What is the deadline to apply to the program?',
          text: [
            'August bootcamps - applications close July 26th, 2019.',
            'October bootcamps - applications close September 27th, 2019.',
          ],
        },
      ],
    },
    courses: {
      title: 'Our Courses',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm',
            },
          ],
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Become a UX/UI designer. Learn user-centered design applying the Design Thinking process and validate your ideas through user research, prototyping, user testing, and heuristic evaluation. Learn UI and IxD to prototype your solutions using visual and interaction design techniques. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm',
            },
          ],
        },
      ],
    },
    testimonials: {
      title: 'Past Students',
      items: [
        {
          image: 'facebook/sherwin-garcia-obregon.jpg',
          name: 'Sherwin Garcia-Obregon',
          position: 'Web Development Part-Time',
          description:
            'A small risk is all it took and as a result, I was rewarded with an amazing community and a fulfilling career that is helping me go further than I could ever imagine. I used to apply to jobs, but now jobs apply to me!',
        },
        {
          image: 'facebook/brenda-matos.jpg',
          name: 'Brenda Matos',
          position: 'UX/UI Design Full-Time',
          description:
            "I couldn't have made a better choice. Ironhack staff treated us like family, were truly invested in our success, and provided a life-changing experience. I am so happy with the decision to attend and am so proud to be an Ironhacker.",
        },
        {
          image: 'facebook/daniel-soraluz.jpg',
          name: 'Daniel Soraluz',
          position: 'Web Development Full-Time',
          description:
            'It is one of the best decisions that I have made to date. I enrolled with the intent to further my skills as a developer and put myself in a better position to enter the market as a professional but I walked away with so much more. Everyone is extremely hands on and will go above and beyond to ensure that your experience is one that promotes your success.',
        },
      ],
    },
    careerSupport: {
      title: 'Career Services',
      text: [
        "We've helped thousands of students land their dream job in tech. Discover how we will help you.",
        "<b>A Proven Methodology</b><br />During our 4+ years helping students achieve success, we've developed a thorough understanding of what it takes to land a job in the digital ecosystem.",
        'Behind our success is a strong network of hiring partners, a dedicated outcomes team, and a deep knowledge of the tech job market and how to navigate it.',
      ],
      logos: [
        {
          name: 'Google',
          image: 'google.png',
        },
        {
          name: 'Magic leap',
          image: 'magic-leap.png',
        },
        {
          name: 'Visa',
          image: 'visa.png',
        },
        {
          name: 'Deloitte',
          image: 'deloite.png',
        },
        {
          name: 'Office Depot',
          image: 'office-depot.jpg',
        },
        {
          name: 'Royal Caribbean',
          image: 'royal-caribbean.png',
        },
        {
          name: 'PGA',
          image: 'pga.jpg',
        },
        {
          name: 'Zumba',
          image: 'zumba.png',
        },
      ],
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'What is an ISA?',
          answer: [
            'An ISA is a contractual agreement in which you receive education funding. In exchange, you agree to share a fixed percentage of your post–graduation income over a defined period of time.',
          ],
        },
        {
          question: 'What are the ways an ISA ends?',
          answer: [
            'An ISA is completed when the first of these events occurs:',
            '- You complete the required number of payments.',
            '- You achieve the payment cap.',
            '- The payment window ends.',
          ],
        },
        {
          question: 'What are the benefits for me?',
          answer: [
            'ISAs offer you an alternative to debt. Debt can create substantial risks to you if you cannot afford your payments during and after education. ISA payments adjust according to levels of income made after you graduate from the bootcamp. The minimum income threshold and payment cap ensure you will not pay if you do not earn a minimum income level and, on the other end, if you earn a substantial amount of income you will not pay above a certain amount.',
          ],
        },
        {
          question: "How do I pay for my Ironhack's ISA?",
          answer: [
            'Monthly payments are calculated by applying the income share to your total monthly earned income. You will receive a monthly bill for this amount, and you can make your payments online, over the phone, or via mail.',
          ],
        },
        {
          question:
            'What happens if my income falls below the minimum income threshold?',
          answer: [
            'The minimum income threshold for this ISA is $3,333.33/month (equivalent to $40,000/year). If your income falls below $40,000/year, your payments will pause. Your payments will resume when your income increases above the threshold.',
          ],
        },
        {
          question: 'Is the ISA a loan?',
          answer: [
            'No. Although some students may pay the full payment cap, others may pay less or even nothing, depending on their monthly earned income. You should compare other types of financing that may be available to you before you decide if an ISA is right for you.',
          ],
        },
        {
          question: 'Is there a way to satisfy my ISA early?',
          answer: [
            'Yes. You may satisfy your ISA at any time by paying the payment cap minus any payments you’ve already made with no penalty at all.',
          ],
        },
        {
          question: 'Do I still have to pay if I don’t finish the program?',
          answer: [
            'If you leave the program before completing 40% of the bootcamp, your ISA will be prorated according to the number of completed program days. If you leave the program after completing 40% of the course, you will share your income according to the full terms of the ISA.',
          ],
        },
        {
          question:
            'Am I required to pay the amount I received as a tuition credit?',
          answer: [
            'You are required to pay the agreed-upon percentage of your income for the prescribed term of the contract. The amount you pay is not tied to the value of the tuition benefit you received.',
          ],
        },
        {
          question: 'Will I be required to pursue a specific career?',
          answer: [
            'Absolutely not. There are no requirements stipulating the nature or type of employment that you choose after graduation.',
          ],
        },
        {
          question: 'What are the key terms of an ISA?',
          answer: [
            '<b>ISA Amount:</b> The amount credited to your account (the net tuition of the program you choose to do).',
            '<b>Income Share:</b> The percentage of monthly income you will pay back.',
            '<b>Required Payments:</b> The number of monthly payments required to fulfill your ISA obligation (also called payment term).',
            '<b>Payment Window:</b> The maximum time for your ISA. An ISA in good standing ends when the payment window is over, even if - based on your monthly earned income - you have been required to pay less than the ISA amount or nothing at all.',
            '<b>Minimum Income Threshold:</b> The income that you must be making in order to make payments. If you are making below that, then payments are paused.',
            '<b>Payment Cap:</b> The maximum amount you can be obligated to share; expressed as a multiple of the ISA amount.',
            '<b>Grace Period:</b> The time period you wait after graduating and before your payments begin.',
          ],
        },
      ],
    },
    campus: {
      title: 'Discover Our Campus',
      campus: {
        name: 'Ironhack Miami Campus',
        video: 'https://www.youtube.com/embed/OXZWp1eVedo',
        address1: '120 SW 8th Street, Miami, FL 33130',
        email: 'mia@ironhack.com',
      },
    },
    moreInfo: {
      text: 'For more information, contact our team!',
      linkText: 'Contact us',
      linkHref: 'mailto:mia@ironhack.com',
    },
    termsAndConditions: {
      text: 'Privacy Policy',
      href:
        'https://s3-eu-west-1.amazonaws.com/ih-web/legal/Ironhack_Privacy_Policy_EN.pdf',
    },
  },
};
