module.exports = {
  template: 'electronicArts',
  context: {
    url: '/de/social-impact/ea-thesims-stipendium',
    lang: 'de',
    breadcrumb: 'The Sims Scholarship',
    seo: {
      title: 'Gestalte deine Zukunft mit The Sims und Ironhack',
      description:
        'Lerne neue Tech-Skills mit The Sims und Ironhack. Bewirb dich hier, um ein Stipendium für ein Tech Bootcamp an einem der neun Standorte von Ironhack zu gewinnen.'
    },
    header: {
      title: 'Nimm deine Zukunft in die Hand -',
      titleHighlighted: 'mit The Sims und Ironhack',
      backgroundImage: 'electronic-arts/header.png',
      alternativeLogo: 'electronic-arts/logo.png',
      alternativeLogoAltText: 'Electronic Arts logo',
      alternativeLogoClass: 'electronic-arts',
      subtitle:
        'Es ist an der Zeit, einen Schritt in deine neue Zukunft zu machen.',
      paragraph:
        'The Sims™ 4 Discover University und Ironhack haben sich zusammengeschlossen, um dich nicht nur virtuell, sondern auch im echten Leben zurück zur Schule zu schicken - mit Stipendien für alle 9-wöchigen und 6-monatigen Ironhack Bootcamps! Während deine Sims am Foxbury Institute lernen, kannst du dasselbe auf einem von neun Ironhack-Campussen weltweit in einem Intensivkurs in Webentwicklung, UX/UI Design oder Data Analytics tun. Dort angekommen, lernst du die gefragtesten Tech-Skills, die heute von allen Unternehmen und Industrien, inklusive der Videospiel-Branche, händeringend gesucht werden!'
    },
    navbar: {
      text: [
        'Wähle deinen eigenen Weg, lerne neue Fähigkeiten, und bewirb dich hier, um ein Stipendium für ein Tech Bootcamp an einem der neun Standorte von Ironhack zu gewinnen.'
      ],
      linkHref: '',
      linkText: 'Bewirb dich'
    },
    video: {
      href: '/landings/electronic-arts/landing-image.png',
      isVideo: false
    },
    valueProposition: {
      title:
        '800 000 € in Stipendien<em>um deine Rolle in der Tech-Revolution zu spielen und die Kontrolle über deine Zukunft zu übernehmen!</em>',
      content: [
        {
          title: 'Welche Kurse sind eingeschlossen?',
          text: [
            'Das The Sims und Ironhack Stipendienprogramm ermöglicht es dir, die Web Development, UX/UI Design oder Data Analytics Kurse zu besuchen, sofern der Campus deiner Wahl den Kurs anbietet. Du kannst das Startdatum wählen und ob du in einen Teilzeit- oder Vollzeitkurs einsteigen möchtest.'
          ]
        },
        {
          title: 'Kann ich an dem Stipendienprogramm teilnehmen?',
          text: [
            'Wenn du 18 Jahre oder älter bist, kannst du dich gerne bewerben. Du brauchst keine Vorkenntnisse in einem bestimmten Bereich, nur viel Motivation und Begeisterung, um neue Fähigkeiten zu lernen!'
          ]
        },
        {
          title: 'Wie kann ich Student werden?',
          text: [
            'Du kannst dich bis zum 17. Dezember 2019 für ein Stipendium bewerben. Der Bewerbungsprozess umfasst ein technisches und ein persönliches Gespräch zur Beurteilung deiner Motivation und Lernfähigkeit. Der Bewerbungsprozess kann aus der Ferne durchgeführt werden, aber alle Ironhack Bootcamps erfordern, dass du in dem Land und der Stadt deiner Wahl präsent bist.',
            'Ausgewählte Teilnehmer gewinnen Teil- oder Vollstipendien für ein Ironhack Bootcamp. Die Stipendien können zwischen 1000€ und 8000€ liegen, je nach Land und Stadt deiner Wahl. Weitere Informationen zur Höhe der Stipendien erhältst du, wenn du den Campus auswählst, der dich interessiert.'
          ]
        },
        {
          title:
            'Kann ich mich bewerben, wenn ich nicht in einem der Länder mit einem Ironhack Campus wohne?',
          text: [
            'Ja, du kannst dich auf das Land und den Campus deiner Wahl bewerben! Für internationale Kandidaten: Falls du ein Stipendium erhältst, musst du daran denken, deinen eigenen Antrag auf ein Reisevisum, deine Unterkunft und deinen Transport zu organisieren.'
          ]
        }
      ]
    },
    courses: {
      title: 'Kurse',
      courses: [
        {
          title: 'Webentwicklung',
          description:
            'Werde Full-Stack Webentwickler. Lerne die Grundlagen von HTML5, CSS3, JavaScript, React, Node.js, Express, MongoDB. Lerne durch Üben in einer aufregenden Umgebung, zusätzlich zu den Kursen wirst du 3 Projekte abschließen, Paarprogrammierungen durchlaufen und die Möglichkeit haben, eine Full-Stack Webanwendung zu erstellen. Wir bieten diesen Track in zwei Formaten an: Vollzeit oder Teilzeit.',
          courseFormats: [
            {
              name: 'Vollzeitprogramm',
              duration: '9 WOCHEN // VOLLZEIT',
              schedule:
                '9 Wochen Intensivprogramme Montag bis Freitag von 9 bis 18 Uhr.'
            },
            {
              name: 'Teilzeitprogramm',
              duration: '24 WOCHEN // TEILZEIT',
              schedule:
                'Zwei Wochentage von 18.30 bis 21.30 Uhr (entweder Montag & Mittwoch oder Dienstag & Donnerstag, je nach Campus) und Samstag von 10 bis 17 Uhr.'
            }
          ]
        },
        {
          title: 'UX/UI Design',
          description:
            'Werde UX/UI-Designer. Lerne benutzerzentriertes Design unter Anwendung von Design Thinking und validiere deine Ideen durch User Research, Prototyping, User Tests und heuristische Bewertung. Lerne UI und IxD kennen, um deine Produktlösungen mit visuellen und interaktiven Designtechniken zu prototypisieren. Wir bieten diesen Track in zwei Modalitäten an: Vollzeit oder Teilzeit.',
          courseFormats: [
            {
              name: 'Vollzeitprogramm',
              duration: '9 WOCHEN // VOLLZEIT',
              schedule:
                '9 Wochen Intensivprogramme Montag bis Freitag von 9 bis 18 Uhr.'
            },
            {
              name: 'Teilzeitprogramm',
              duration: '24 WOCHEN // TEILZEIT',
              schedule:
                'Zwei Wochentage von 18.30 bis 21.30 Uhr (entweder Montag & Mittwoch oder Dienstag & Donnerstag, je nach Campus) und Samstag von 10 bis 17 Uhr.'
            }
          ]
        },
        {
          title: 'Data Analytics',
          description:
            'Lerne wie du eine Vielzahl von Datensätzen bereinigen und untersuchen kannst. Tauche ein in die Datenanalyse mit Pandas, um deine Daten für Storytellingzwecke in erstaunliche Visualisierungen zu verwandeln. Schließe alles mit maschinellem Lernen ab und wende deine Erkenntnisse auf Datensätze in der realen Welt an.',
          courseFormats: [
            {
              name: 'Vollzeitprogramm',
              duration: '9 WOCHEN // VOLLZEIT',
              schedule:
                '9 Wochen Intensivprogramme Montag bis Freitag von 9 bis 18 Uhr.'
            },
            {
              name: 'Teilzeitprogramm',
              duration: '24 WOCHEN // TEILZEIT',
              schedule:
                'Zwei Wochentage von 18.30 bis 21.30 Uhr (entweder Montag & Mittwoch oder Dienstag & Donnerstag, je nach Campus) und Samstag von 10 bis 17 Uhr.'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Bewerbungsprozess',
      steps: [
        {
          title: 'Schritt 1: Fülle das Onlineformular aus',
          description: 'Die Bewerbungen sind bis zum 17. Dezember geöffnet.'
        },
        {
          title: 'Schritt 2: Technisches Interview',
          description:
            'Du bekommst eine E-Mail mit Materialien zur Vorbereitung auf einen technischen Online-Test für den von dir gewählten Kurs. Bereite das Material vor und mache den Test, wenn du bereit bist. Keine Panik, es ist nicht so schwer, wenn man sich vorbereitet. Die technischen Tests sind bis zum 5. Januar 2020 geöffnet.'
        },
        {
          title: 'Schritt 3: Persönliches Interview',
          description:
            'Bestehst du die ersten beiden Schritte, wirst du zu einem 10-minütigen persönlichen Gespräch mit einem lokalen Teammitglied eingeladen, das per Videoanruf oder vor Ort auf dem Campus durchgeführt werden kann. Persönliche Interviews werden am 24. Januar 2020 abgeschlossen.'
        },
        {
          title: 'Schritt 4: Resultate',
          description:
            'Die Gewinner werden in der Woche vom 24. Januar 2020 benachrichtigt.'
        },
        {
          title: 'Ich habe ein Stipendium gewonnen! Was jetzt?',
          description:
            'Wenn du ein Teilstipendium gewinnst, musst du dich entscheiden, ob du den Kurs besuchen möchtest und deine Teilnahme durch eine Anzahlung bestätigen, die von den restlichen Studiengebühren abgezogen wird. Wenn du ein Vollstipendium gewinnst, musst du dich entscheiden, ob du den Kurs besuchen möchtest und deine Teilnahme schriftlich bestätigen. Sobald du deine Teilnahme an einem Bootcamp bestätigt hast, bereite dich auf eine spannende und intensive Lernerfahrung vor!'
        }
      ]
    },
    campuses: {
      selectAnother: 'Wähle anderen Campus',
      noCampusTitle: 'Wähle Land - Campus deiner Wahl',
      campusTitle: 'Du hast Ironhack',
      discover: 'Visit our Campus!',
      apply: 'Bewirb dich',
      items: [
        {
          code: 'ams',
          city: 'Amsterdam',
          name: 'Amsterdam, Netherlands',
          address: 'Prinsengracht 437<br />1016 HM<br />Amsterdam',
          ft: 'Alle Kurse sind auf Englisch',
          pt: 'Alle Kurse sind auf Englisch',
          amounts:
            'Höhe der nationalen Stipendien: <b>7000€, 3000€, 2000€ and 1000€</b><br />Höhe der internationalen Stipendien: <b>3500€, 2500€ and 1500€</b>'
        },
        {
          code: 'bcn',
          city: 'Barcelona',
          name: 'Barcelona, Spain',
          address: 'Carrer de Pamplona 96<br />08018 Barcelona',
          ft:
            'Both courses in English, content in English and courses in Spanish, content in English',
          pt: 'Course always in Spanish, content in English',
          amounts:
            'Höhe der nationalen Stipendien: <b>1250€</b><br />Höhe der internationalen Stipendien: <b>2000€ and 7000€</b>'
        },
        {
          code: 'ber',
          city: 'Berlin',
          name: 'Berlin, Germany',
          address: 'c/o Techspace<br />Lobeckstraße 36-40<br />10969 Berlin',
          ft: 'Alle Kurse sind auf Englisch',
          pt: 'Alle Kurse sind auf Englisch',
          amounts: 'Höhe der Stipendien: <b>1125€, 1875€, 3750€ and 7500€</b>'
        },
        {
          code: 'lis',
          city: 'Lisbon',
          name: 'Lisbon, Portugal',
          address:
            'Heden Waterfront<br />Doca Jardim do Tabaco<br />Terminal de Cruzeiros de Lisboa<br />Edifício NW, 1º Piso, 1100-651 Lisboa',
          ft: 'Alle Kurse sind auf Englisch',
          pt: 'Alle Kurse sind auf Englisch',
          amounts: 'Höhe der Stipendien: <b>6000€ and 2000€</b>'
        },
        {
          code: 'mad',
          city: 'Madrid',
          name: 'Madrid, Spain',
          address: 'Paseo de la Chopera, 14<br />28045 Madrid',
          ft: 'Course in Spanish, contents in English',
          pt: 'Course in Spanish, contents in English',
          amounts:
            'Höhe der nationalen Stipendien: <b>1250€</b><br />Höhe der internationalen Stipendien: <b>1500€</b>'
        },
        {
          code: 'mex',
          city: 'Mexico City',
          name: 'Mexico City, Mexico',
          address:
            'Tonalá 10<br />Colonia Roma Norte<br />06700, Ciudad de México',
          ft: 'Curso impartido en español',
          pt: 'Curso impartido en español',
          amounts:
            'Höhe der nationalen Stipendien: <b>$15,000MXN and $45,000MXN</b><br />Höhe der internationalen Stipendien: <b>$20,000MXN and $45,000MXN</b>'
        },
        {
          code: 'mia',
          city: 'Miami',
          name: 'Miami, United States',
          address: '120 SW 8th St<br />Miami FL 33130<br />United States',
          ft: 'Kurse sind auf Englisch',
          pt: 'Kurse sind auf Englisch',
          amounts:
            'Höhe der nationalen Stipendien: <b>$2,000 - $12,000</b><br />Höhe der internationalen Stipendien: <b>$3,000 - $12,000</b>'
        },
        {
          code: 'par',
          city: 'Paris',
          name: 'Paris, France',
          address: '226 Boulevard Voltaire<br />75011 Paris',
          ft: 'Kurse sind auf Englisch',
          pt: 'Kurse sind auf Französisch',
          amounts:
            'Höhe der nationalen Stipendien: <b>1500€, 2500€ and 8000€</b><br />Höhe der internationalen Stipendien: <b>2500€, 3500€ and 8000€</b>'
        },
        {
          code: 'sao',
          city: 'São Paulo',
          name: 'São Paulo, Brazil',
          address:
            'Alameda Jaú, 1301 - Jardim Paulista<br />São Paulo - SP, 01420-001',
          ft: 'Course in Portuguese, content in English',
          pt: 'Course in Portuguese, content in English',
          amounts:
            'Höhe der nationalen Stipendien: <b>$3.500, $5.000 and $7.000</b><br />Höhe der internationalen Stipendien: <b>$5.500, $7.000 and $9.000</b>'
        }
      ],
      courses: {
        title: 'Upcoming Cohorts',
        noCohorts: 'The campus has no available cohorts'
      }
    },
    pardotForm: {
      sectionId: 'pardot-form',
      title: 'Bewirb dich für ein Stipendium',
      formUrl: 'https://www2.ironhack.com/l/688303/2019-12-02/8vmw4'
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Warum diese Partnerschaft zwischen The Sims und Ironhack?',
          answer: [
            'In The Sims 4 Discover University Expansion Pack können die Sims zur Schule zurück, um die für ihre zukünftige Karriere erforderlichen Skills zu lernen. Mit dem The Sims and Ironhack Stipendium hast du die Möglichkeit, dasselbe zu tun! Wir bieten Spielern die Möglichkeit, ihr Spielerlebnis in die reale Welt zu bringen. Die Stipendien ermöglichen jedem den Zugang zu Intensivkursen in Webentwicklung, UX/UI Design und Data Analytics und dadurch zu aufregenden Karrieren im Tech- oder auch Videospiel-Bereich.',
            'Wir wollen dir helfen, deine Zukunft in die eigenen Hände zu nehmen - innerhalb wie außerhalb der Spielewelt!'
          ]
        },
        {
          question:
            'Was sind die Auswahlkriterien? Benötige ich Vorkenntnisse / Erfahrung, um die Kurse besuchen zu können?',
          answer: [
            'Du benötigst keine Vorkenntnisse, aber wir bewerten deine Lernfähigkeit sowie deine logischen und kreativen Fähigkeiten, um zu beurteilen, ob du in dieser Art von Lernumgebung erfolgreich sein wirst. Wir werden auch deine Motivation und deine Gründe für eine Teilnahme bewerten, um den Erfolg in einer intensiven Lernumgebung sicherzustellen.',
            'Du musst über 18 Jahre alt sein, um dich zu bewerben.'
          ]
        },
        {
          question:
            'Welchen Campus kann ich mit diesem Stipendienprogramm besuchen?',
          answer: [
            'Du kannst zwischen allen Ironhack Campussen in Madrid, Barcelona, Paris, Amsterdam, Berlin, Lissabon, Miami, Mexiko und Sao Paulo wählen. Das Kursangebot, die Anfangsdaten sowie die Unterrichtssprachen können variieren.',
            'Beachte bitte, dass du zum Zeitpunkt des Bootcamps physisch im Land - Stadt deiner Wahl sein musst, wir bieten keine Online- oder Fernkurse an.'
          ]
        },
        {
          question: 'Wie funktioniert der Bewerbungsprozess?',
          answer: [
            '1: Füll das Onlineformular aus',
            '2: Mach den technischen Test online',
            '3: Persönliches Interview'
          ]
        },
        {
          question: 'Muss ich meinen Job aufgeben, um den Kurs zu absolvieren?',
          answer:
            'Kurze Antwort: Nein! Lange Antwort: Es hängt davon ab, welches Kursformat du wählst. Die Teilzeitkurse sind für arbeitende Teilnehmer konzipiert - du musst an zwei Abenden in der Woche sowie an drei Samstagen im Monat am Unterricht teilnehmen. Für die Bootcamps musst du vollzeit verfügbar sein. Viele Alumni erhielten jedoch von ihrem Arbeitgeber die Genehmigung, sich eine Auszeit zu nehmen und zurückzukehren.'
        },
        {
          question: 'Welche Karriereservices bietet ihr an?',
          answer:
            'In den letzten 5 Jahren, in denen wir unseren Schülern geholfen haben, erfolgreich zu sein, haben wir ein tiefes Verständnis dafür entwickelt, was es braucht, um einen Job im digitalen und technologischen Ökosystem zu finden. Hinter unserem Erfolg steht ein starkes Netzwerk von Hiring Partnern, ein engagiertes Outcomes Team und ein tiefes Verständnis über den technischen Arbeitsmarkt. Wir helfen dir mit 1-on-1-Mentoring, Workshops zum Aufbau eines Lebenslaufs, Interviewtrainings und vielem mehr dich auf deinen Job vorzubereiten. Nach jedem unserer Kurse findet eine Hiring Week statt, in der du bei Speed-Recruitings auf erstklassige Unternehmen triffst. Unser Outcomes Team unterstützt dich auch nach deinem Abschluss weiter, damit du deinen Traumjob findest.'
        },
        {
          question:
            'Bietet ihr Studentenvisa oder irgendeine Art von Visaunterstützung an?',
          answer:
            'Leider bieten wir keine Visaunterstützung und können keine Papierarbeit leisten, um Studentenvisa zu erhalten. Internationale Studenten müssen ihre Visa-Arrangements treffen und die Visumausstellung im Voraus planen.'
        },
        {
          question: 'Bietet ihr während des Kurses eine Unterkunft an?',
          answer:
            'Wir bieten keine Unterkunft oder Unterkunftshilfe während der Bootcamps an. Jedem Kandidaten wird empfohlen, seine Unterkunft selbstständig nach seinen Bedürfnissen zu gestalten.'
        },
        {
          question:
            'Ich habe ein Teilstipendium gewonnen, kann ich dieses mit einem anderen Rabatt oder Stipendium verbinden?',
          answer:
            'Nein, ein The Sims und Ironhack Stipendium ist mit keinem anderen Rabatt oder Stipendium kumulierbar.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Allgemeine Geschäftsbedingungen',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/electronic-arts-scholarship/terms-and-conditions-de.pdf'
    }
  }
};
