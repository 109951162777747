import React from 'react';
import { Jumbo1 } from '../atoms/headers';
import { Text2 } from '../atoms/body';
import Section from './Stat';

interface Props {
  title: string;
  value: string;
  key: string;
}

function StatComponent(props: Props): JSX.Element {
  const { value, title = '' } = props;
  return (
    <Section>
      <Jumbo1 as="span">{value}</Jumbo1>
      <Text2>{title.toUpperCase()}</Text2>
    </Section>
  );
}

export default StatComponent;
