import React from 'react';
import Props from '../interfaces/StatsList';
import Section, { Div, SocialStats } from './styles';
import Layout from '../atoms/layout';
import { Title2 } from '../atoms/headers';
import { Text3 } from '../atoms/body';
import StatsList from '../StatsList';
import { withTranslation } from 'react-i18next';

const OurImpact: React.SFC<any> = (props: Props) => {
  const { t } = props;
  return (
    <Layout extend={SocialStats}>
      <Section>
        <StatsList context="social_impact" columns={2} />
      </Section>
      <Div>
        <Title2 as="h2">{t('social-impact:socialImpact.talent.title')}</Title2>
        <section>
          <Text3 as="p">
            {t('social-impact:socialImpact.talent.description')}
          </Text3>
        </section>
      </Div>
    </Layout>
  );
};

export default withTranslation()(OurImpact);
