import landingPages from '../../../build-utils/landing-pages';
import { LandingHeader } from '../Landing/interfaces';
interface PropsLanding {
  context: Landing;
}
interface Landing {
  lang: string;
  url: string;
  header: LandingHeader;
  isIsa: boolean;
}
interface LandingCards {
  title: string;
  text?: string;
  image: string;
  url: string;
}

const URL_BLACKLIST = ['/en/web-development/selina'];

const landingsForLanguage = (language: string, isIsa?: boolean) => {
  const restructureLanding = (landing: Landing) => {
    const title = `${landing.header.title} ${landing.header.titleHighlighted ||
      ''}`;
    return {
      title,
      text: landing.header.paragraph,
      image: landing.header.backgroundImage,
      url: landing.url
    };
  };
  const checkIsIsa = (landing: Landing) => {
    return landing.isIsa === isIsa;
  };

  return Object.values<PropsLanding>(landingPages).reduce(
    (landings: LandingCards[], { context }: PropsLanding) => {
      if (
        context.lang === language &&
        checkIsIsa(context) &&
        !URL_BLACKLIST.includes(context.url)
      ) {
        landings.push(restructureLanding(context));
      }
      return landings;
    },
    []
  );
};

export { landingsForLanguage };
