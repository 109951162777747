import React from 'react';
import { Text2, Text4 } from '../atoms/body';
import Button from './styles';
import Props from '../interfaces/OurSocialActionItems';

function OurSocialActionsItem(props: Props) {
  const { title, text, url, image } = props;

  return (
    <article>
      <img src={`/landings/${image}`} alt={`Ironhack scholarship: ${title}`} />
      <Text2 dangerouslySetInnerHTML={{ __html: title }} />
      <Text4 as="p">{text}</Text4>
      <Button href={url} dangerouslySetInnerHTML={{ __html: title }} />
    </article>
  );
}

export default OurSocialActionsItem;
