import styled from 'styled-components';
import config from '../config';

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6.4rem;

  & article {
    width: 47%;
    min-width: 300px;
    padding-bottom: 6.4rem;

    & p {
      opacity: 0.6;
      margin-top: 1.1rem;
      margin-bottom: 3.2rem;
    }

    & img {
      height: 206px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 2.7rem;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 4.8rem;
  }
`;

const BrowseSection = styled.section`
  text-align: center;

  &.hide {
    display: none;
  }

  & p:nth-child(2) {
    width: 50%;
    margin: 1.1rem auto 2.4rem auto;
    opacity: 0.6;
  }
`;

const BrowseMore = styled.button`
  width: 40%;
  background-color: ${config.palette.white};
  border-radius: 0.6rem;
  padding: 2.4rem 0;
  color: ${config.palette.lightBlue};
  font-size: 1.9rem;
  cursor: pointer;
  line-height: 168%;
  text-align: center;
  text-decoration: none;
  border: 0.2rem solid ${config.palette.lightBlue};

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    line-height: 125%;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export { BrowseMore, BrowseSection };
