module.exports = {
  context: {
    url: '/en/financing/isa-amsterdam',
    lang: 'en',
    breadcrumb: 'ISA Amsterdam',
    isIsa: true,
    seo: {
      deindexed: true,
      title: 'ISA - Ironhack Income Share Agreements, Amsterdam',
      description:
        'The Ironhack Income Sharing Agreement (ISA) gives you the opportunity to participate in our coding or design bootcamps at no upfront cost.',
    },
    header: {
      title: 'Change your life with the Ironhack Bootcamp,',
      titleHighlighted: 'now at no up-front costs',
      backgroundImage: 'isa/header.png',
      paragraph:
        "Take a full-time Ironhack course in Amsterdam, at no up-front cost. With Ironhack's Income Share Agreements, you have the opportunity to access quality education, change your life and only pay when you've landed a job making €25K/year or more.",
    },
    navbar: {
      text: [
        '· Access to education without the up-front costs!',
        "· Only pay when you've started earning",
        '· World leader in bootcamp courses',
        '· Change your life and career in just 9-weeks!',
      ],
      linkHref: 'https://ironhack.typeform.com/to/hjHE7E',
    },
    video: {
      href: 'https://www.youtube.com/embed/ahoc9MZE38Y',
    },
    valueProposition: {
      content: [
        {
          title: "What is Ironhack's Income Share Agreement (ISA)?",
          text: [
            'ISA stands for "income share agreement" - it is an agreement in which you defer tuition payment until after completing your studies. After the bootcamp, ISA payments are a fixed percentage of your post-graduation income over a pre-defined period of time. Ironhack\'s ISA program gives you the opportunity to take an Ironhack bootcamp at no upfront cost. Payment only begins once you start earning a role making at least €25K/year (€2,083.33/month).',
          ],
        },
        {
          title: "Your journey with Ironhack's Income Share Agreements (ISA)",
          text: [
            'Instead of worrying about tuition payments, focus on what really matters: the learning process.',
            "Once you graduate, you'll start looking for a job with the support of our Career Development  team.",
            'Once you have found a new job, you will pay 10% of your monthly earned income for 48 months or up to 1.6x the cost of tuition, whichever happens first.',
          ],
          isList: true,
        },
      ],
    },
    extraText: {
      content: [
        {
          title: 'Who is eligible to apply for the Ironhack Amsterdam ISA?',
          text: [
            "<b>Ironhack's ISA program is available to individuals who are:</b>",
            "· Incoming Full-time Web Dev and Full-time UX students at Ironhack Amsterdam's campus in the Netherlands.",
            '· At the time of application are at least 18 years of age.',
            '· You are a EU citizen, permanent resident or non-permanent resident alien in the Netherlands holding a long term work permit.',
            '· You have a citizen service number (BSN)',
            '<b>Once students are deemed eligible, they must complete the following additional application steps in order to be approved:</b>',
            '· Participate in the personal interview with the Ironhack team.',
            '· Take and pass a skills assessment.',
            '· Complete WD or UX pre-work.',
            "<small>Please note: Ironhack's ISA is void wherever restricted or prohibited at the terms and conditions specified in the ISA agreement or by law. Applicant’s rights and responsibilities may vary by jurisdiction.</small>",
          ],
        },
        {
          title: 'How can I apply to this program?',
          text: [
            '1. Fill out the application form.',
            '2. Meet the Ironhack Amsterdam team for a personal interivew.',
            '3. Receive your tech assesment study material via email from us.',
            '4. Pass your technical assessment.',
            '5. If you are selected, review and complete your ISA agreement.',
            '6. Get started on your pre-work!.',
          ],
        },
      ],
    },
    courses: {
      title: 'Our Courses',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm',
            },
          ],
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Become a UX/UI designer. Learn user-centered design applying the Design Thinking process and validate your ideas through user research, prototyping, user testing, and heuristic evaluation. Learn UI and IxD to prototype your solutions using visual and interaction design techniques. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm',
            },
          ],
        },
      ],
    },
    testimonials: {
      title: 'Our Students',
      items: [
        {
          image: 'isa/amsterdam/matt-hamers.jpg',
          name: 'Matt Hamers',
          position: 'Web Development',
          description:
            'I was very skeptical of learning web development in just 9 weeks, but after reading some alumni stories I was convinced that it was worth the shot. Now that I have done I can tell you it is tough but with the awesome support from the Ironhack team and its community, it is possible. After graduating, you will have a solid foundation to start at a position as a junior developer at a company or further develop your skills autonomously.',
        },
        {
          image: 'isa/amsterdam/eva-van-uden.jpg',
          name: 'Eva van Uden',
          position: 'UX/UI Design Track',
          description:
            'The best start of an amazing journey! It only took a little bit of courage above fear, and a great Community at Ironhack to change my career path! It has been an exciting adventure and I’m proud to call myself an Ironhacker!',
        },
      ],
    },
    careerSupport: {
      title: 'Career Services',
      text: [
        "Worldwide we've helped thousands of students land their dream job in tech. Find out how we will help you.",
        "During our 5+ years helping students achieve success, we've developed a thorough understanding of what it takes to land a job in the digital ecosystem.",
        'Behind our success is a strong network of local and global hiring partners, a dedicated outcomes team, and a deep knowledge of the tech job market and how to navigate it.',
        'Our career guidance is characterized by one-on-one mentorship and exposure to our local tech ecosystems. This service includes optimizing your digital profiles, preparing you for interviews, and helping you identify suitable options. We will make sure that you are ready to enter the job market and will support you until your first day at work!',
      ],
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'What is an Income Share Agreement (ISA)?',
          answer: [
            'An Income Share Agreement (ISA) is a contractual agreement in which you defer tuition payment until after completing your studies. In exchange, you agree to share a fixed percentage of your post–graduation income over a defined period of time.',
          ],
        },
        {
          question: 'What are the ways an Income Share Agreement (ISA) ends?',
          answer: [
            'An Income Share Agreement (ISA) is completed when the first of these events occurs:',
            '- You complete the required number of payments.',
            '- You achieve the payment cap.',
            '- The payment window ends.',
          ],
        },
        {
          question: 'What are the benefits for me?',
          answer: [
            'ISAs offer you an alternative to student loans. Income Share Agreements (ISA) payments adjust according to your levels of income after you graduate from the bootcamp. The minimum income threshold protects students who are struggling so they only pay when meeting a minimum income (€2,088.33/month) level and, on the other end, if you earn a substantial amount of income you will not pay more than the payment cap (1.6x your tuition amount).',
          ],
        },
        {
          question:
            'How do I pay for my Ironhack Income Share Agreement (ISA)?',
          answer: [
            'Monthly payments are calculated by applying the income share to your total monthly earned income. You will set up automatic ACH payments via the EdAid platform, and payment will be initiated on the 1st of each month.',
          ],
        },
        {
          question:
            'What happens if my income falls below the minimum income threshold?',
          answer: [
            'The minimum income threshold for this Income Share Agreement (ISA) is €2,083.33/month (equivalent to €25,000/year). If your income falls below €25,000/year, your payments will pause. Your payments will resume when your income increases above the threshold.',
          ],
        },
        {
          question:
            'Is there a way to satisfy my Income Share Agreement (ISA) early?',
          answer: [
            'Yes. You may satisfy your ISA at any time by paying the payment cap minus any payments you’ve already made with no penalty at all.',
          ],
        },
        {
          question: 'Do I still have to pay if I don’t finish the program?',
          answer: [
            'If you leave the program before completing 40% of the bootcamp, your ISA will be prorated according to the number of completed program days. If you leave the program after completing 40% of the course, you will share your income according to the full terms of the ISA.',
          ],
        },
        {
          question:
            'Am I required to pay the amount I received as a tuition credit?',
          answer: [
            'You are required to pay the agreed-upon percentage of your income for the prescribed term of the contract. The total amount you pay is dependent on your income.',
          ],
        },
        {
          question: 'Will I be required to pursue a specific career?',
          answer: [
            'Absolutely not. There are no requirements stipulating the nature or type of employment that you choose after graduation.',
          ],
        },
        {
          question:
            'What are the key terms of an Income Share Agreement (ISA)?',
          answer: [
            '<b>ISA Amount:</b> The net tuition of the program you choose to do.',
            '<b>Income Share:</b> The percentage of monthly income you will pay (10%).',
            '<b>Required Payments:</b> The number of monthly payments required to fulfill your ISA obligation (48 monthly payments).',
            '<b>Payment Window:</b> The maximum time for your ISA. An ISA in good standing ends when the payment window is over, even if - based on your monthly earned income - you have been required to pay less than the ISA amount or nothing at all (72 months).',
            '<b>Minimum Income Threshold:</b> The income that you must be making in order to make payments. If you are making below that, then payments are paused (€2,083.33/month or €25,000/year).',
            '<b>Payment Cap</b>: The maximum amount you may pay; expressed as a multiple of the ISA amount (1.6x).',
            'For more information on the ISA terms, please click <a href="https://edaid.com/university/ironhack?campus=Amsterdam" target="_blank" rel="noreferrer noopener">here</a>.',
          ],
        },
        {
          question: 'Is the ISA a loan?',
          answer: [
            'No. Although some students may pay the full payment cap, others may pay less or even nothing, depending on their monthly earned income. You should compare other types of financing that may be available to you before you decide if an ISA is right for you.',
          ],
        },
      ],
    },
    campus: {
      title: 'Discover Our Campus',
      campus: {
        name: 'Ironhack Amsterdam Campus',
        video: 'https://www.youtube.com/embed/ahoc9MZE38Y',
        address1: 'Prinsengracht 437, 1016 HM',
        email: 'amsterdam@ironhack.com',
      },
    },
    moreInfo: {
      text: 'For more information, contact our team!',
      linkText: 'Contact us',
      linkHref: 'mailto:amsterdam@ironhack.com',
    },
    termsAndConditions: {
      text: 'Privacy Policy',
      href: 'https://www.ironhack.com/en/legal-notice',
    },
  },
};
