module.exports = {
  template: 'electronicArts',
  context: {
    url: '/nl/sociale-impact/ea-thesims-studiebeurs',
    lang: 'nl',
    breadcrumb: 'The Sims Scholarship',
    seo: {
      title: 'Neem controle over je toekomst met De Sims en Ironhack',
      description:
        'Leer nieuwe tech-skills met De Sims en Ironhack. Meld je hier aan om een studiebeurs te winnen voor een bootcamp op 1 van de 9 Ironhack locaties.'
    },
    header: {
      title: 'Neem controle over je toekomst met De Sims en Ironhack',
      titleHighlighted: 'The Sims and Ironhack',
      backgroundImage: 'electronic-arts/header.png',
      alternativeLogo: 'electronic-arts/logo.png',
      alternativeLogoAltText: 'Electronic Arts logo',
      alternativeLogoClass: 'electronic-arts',
      subtitle: 'Maak nu de stap naar jouw nieuwe toekomst.',
      paragraph:
        'Om de lancering van de nieuwe Sims Sims™ 4 Discover University, hebben De Sims en Ironhack hun krachten gebundeld, en sturen we je terug naar school! Terwijl je sims lekker aan het studeren is op het Foxbury Instituut, kan jij hetzelfde doen op 1 van de 9 Ironhack locaties, wereldwijd. Volg een intensieve opleiding in Web Development, UX/UI Design of Data Analytics. Daar leer je als student, de meest in-demand tech-skills!'
    },
    navbar: {
      text: [
        'Kies je eigen weg, leer nieuwe skills, meld je hier aan om een scholarship voor een tech bootcamp te winnen, bij een van de 9 Ironhack locaties.'
      ],
      linkHref: '',
      linkText: 'Meld je aan'
    },
    video: {
      href: '/landings/electronic-arts/landing-image.png',
      isVideo: false
    },
    valueProposition: {
      title:
        'We geven in totaal €800.000 euro<em>weg aan studiebeurzen, om jouw te laten doorleren en een nieuwe toekomst te beginnen!</em>',
      content: [
        {
          title: 'Welke opleidingen doen mee?',
          text: [
            "Het 'De Sims en Ironhack' studiebeurs-programma geeft jou de mogelijkheid om Web Development, UX/UI Design of Data Analytics te volgen, mits je campus naar keuze, deze opleiding aanbiedt. Je kan zelf kiezen voor welke startdatum je gaat en of je het part-time of full-time wil volgen."
          ]
        },
        {
          title: 'Kom ik in aanmerking voor een scholarship?',
          text: [
            'Als je 18 jaar en ouder bent, kun je je aanmelden om mee te doen. Je hebt geen voorgaande ervaring of kennis nodig, maar alleen een heleboel motivatie en enthousiasme om nieuwe skills te leren!'
          ]
        },
        {
          title: 'Hoe word ik een Ironhack student?',
          text: [
            'Je kan je tot 17 December aanmedlen. Het aanmeldingsproces bevat een technisch- en persoonlijk interview, om je motivatie en mogelijkheid tot leren, te testen. Dit proces kan op afstand worden doornomen, maar wanneer de bootcamp van start gaat, moet je je op de locatie van de bootcamp vestigen. Geselecteerde deelnemers kunnen een complete of gedeeltelijke studiebeurs winnen. De studiebeurzen die we weggeven, lopen van €1000 op tot €8000, afhankelijk van de campus + locatie die je gekozen hebt.',
            'Voor de exacte bedragen selecteer je de campus naar keuze:'
          ]
        },
        {
          title:
            'Kan ik me aanmelden voor een andere campus, dan waar ik woon?',
          text: [
            'Dat kan! Maar als je een internationale kandidaat bent en je krijgt een studiebeurs toegewezen, vergeet dan niet dat zelf verantwoordelijk bent voor je reis naar je locatie, je visa en je accommodatie.'
          ]
        }
      ]
    },
    courses: {
      title: 'Studierichtingen',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Word een Full Stack Web Developer, leer de basis van HTML5, CSS5, JavaScript, React, Node.js, Express, MOngoDB. Leer door te oefenen in een stimulerende omgeving. Naast alle programmeerlessen, werk je aan drie projecten, heb je pair programming sessies en bouw je je full-stack web applicaties. Deze opleiding is beschikbaar in zowel part- als full-time.',
          courseFormats: [
            {
              name: 'Full-Time Programma',
              duration: '9 WEKEN // FULL TIME',
              schedule:
                '9 weken, intensief programma op Maandag t/m Vrijdag, van 09.00-18.00'
            },
            {
              name: 'Part-time programma',
              duration: '24 WEKEN // PART TIME',
              schedule:
                'Twee werkdagen van 18.30 - 21.30 (Maandag & Woensdag of Dinsdag & Donderdag, afhankelijk van de campus) en op Zaterdag van 10.00 tot 17.00.'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Word een UX/UI Designer. Leer user-centered design, pas Design Thinking toe en valideer je ideeën door user research, prototyping, user testing en heuristische evaluatie. Leer UI en IxD om prototypes te maken waarbij je visuele en interactie designtechnieken gebruikt. Deze opleiding is beschikbaar in zowel part- als full-time.',
          courseFormats: [
            {
              name: 'Full-Time Programma',
              duration: '9 WEKEN // FULL TIME',
              schedule:
                '9 weken, intensief programma op Maandag t/m Vrijdag, van 09.00-18.00'
            },
            {
              name: 'Part-time programma',
              duration: '24 WEKEN // PART TIME',
              schedule:
                'Twee werkdagen van 18.30 - 21.30 (Maandag & Woensdag of Dinsdag & Donderdag, afhankelijk van de campus) en op Zaterdag van 10.00 tot 17.00.'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Leer hoe je data verzamelt, opschoont en analyseer en interpreteer een grote verscheidenheid aan datasets. Ga dieper in op trends en voorspellingen, door de data te visualiseren met bijvoorbeeld Pandas. Combineer alles wat je geleerd hebt met machine learning, en pas je kennis toe op real-world datasets.',
          courseFormats: [
            {
              name: 'Full-Time Programma',
              duration: '9 WEKEN // FULL TIME',
              schedule:
                '9 weken, intensief programma op Maandag t/m Vrijdag, van 09.00-18.00'
            },
            {
              name: 'Part-time programma',
              duration: '24 WEKEN // PART TIME',
              schedule:
                'Twee werkdagen van 18.30 - 21.30 (Maandag & Woensdag of Dinsdag & Donderdag, afhankelijk van de campus) en op Zaterdag van 10.00 tot 17.00.'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Applicatie proces',
      steps: [
        {
          title: 'Stap 1: Vul het aanmeldingsformulier in',
          description: 'Aanmelden kan tot 17 December 2019'
        },
        {
          title: 'Stap 2: Voltooi de technische test',
          description:
            'Je ontvangt een email, met daarin materiaal en een link om je voor te bereiden op een technische uitdaging, die gelinkt is aan het ondewerp van je gekozen bootcamp. Bereid je goed voor met het materiaal en maak de test wanner je er klaar voor bent. En geen zorgen; het is niet al te moeilijk als je je goed hebt voorbereid. De technische test kan je maken tot 5 Januari 2020.'
        },
        {
          title: 'Stap 3: Persoonlijk interview',
          description:
            'Als je geslaagd bent voor de technische test, wordt je uitgenodigd voor een 10-minuten durend persoonlijk interview met een lokaal teammember van de campus. Persoonlijke interviews zijn mogelijk tot 24 Januari 2020.'
        },
        {
          title: 'Stap 4: De uitslag',
          description: 'Winnaars krijgen bericht in de week van 24 Januari'
        },
        {
          title: 'Ik heb een studiebeurs gewonnen! Wat nu?',
          description:
            'Als je een gedeeltelijke studiebeurs hebt gewonnen, moet je de keuze maken of je deze wil accepteren en je deelname bevestigen door het betalen van een deposit, die later afgetrokken word van de resterende studiekosten. De resterende studiekosten (het verschil tussen je studiebeurs en de kosten van de bootcamp) moet je zelf betalen. Als je een complete scholarship gewonnnen hebt, moet je je deelname bevestigen door middel van een geschreven bevestiging. Wanneer je dit hebt gedaan, is het tijd om je klaar te maken voor een spannend en intensief avontuur met Ironhack.'
        }
      ]
    },
    campuses: {
      selectAnother: 'Kies een andere campus',
      noCampusTitle: 'Kies in welk land en op welke campus je wil studeren:',
      campusTitle: 'Je hebt',
      discover: 'Visit our Campus!',
      apply: 'Meld je aan',
      items: [
        {
          code: 'ams',
          city: 'Amsterdam',
          name: 'Amsterdam, Netherlands',
          address: 'Prinsengracht 437<br />1016 HM<br />Amsterdam',
          ft: 'Alle lessen zijn in het Engels',
          pt: 'Alle lessen zijn in het Engels',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>7000€, 3000€, 2000€ and 1000€</b><br />Te winnen bedragen voor de internationale studiebeurs <b>3500€, 2500€ and 1500€</b>'
        },
        {
          code: 'bcn',
          city: 'Barcelona',
          name: 'Barcelona, Spain',
          address: 'Carrer de Pamplona 96<br />08018 Barcelona',
          ft:
            'Both courses in English, content in English and courses in Spanish, content in English',
          pt: 'Course always in Spanish, content in English',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>1250€</b><br />Te winnen bedragen voor de internationale studiebeurs <b>2000€ and 7000€</b>'
        },
        {
          code: 'ber',
          city: 'Berlin',
          name: 'Berlin, Germany',
          address: 'c/o Techspace<br />Lobeckstraße 36-40<br />10969 Berlin',
          ft: 'Alle lessen zijn in het Engels',
          pt: 'Alle lessen zijn in het Engels',
          amounts: 'Studiebeurs bedragen: <b>1125€, 1875€, 3750€ and 7500€</b>'
        },
        {
          code: 'lis',
          city: 'Lisbon',
          name: 'Lisbon, Portugal',
          address:
            'Heden Waterfront<br />Doca Jardim do Tabaco<br />Terminal de Cruzeiros de Lisboa<br />Edifício NW, 1º Piso, 1100-651 Lisboa',
          ft: 'Alle lessen zijn in het Engels',
          pt: 'Alle lessen zijn in het Engels',
          amounts: 'Studiebeurs bedragen: <b>6000€ and 2000€</b>'
        },
        {
          code: 'mad',
          city: 'Madrid',
          name: 'Madrid, Spain',
          address: 'Paseo de la Chopera, 14<br />28045 Madrid',
          ft: 'Course in Spanish, contents in English',
          pt: 'Course in Spanish, contents in English',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>1250€</b><br />Te winnen bedragen voor de internationale studiebeurs <b>1500€</b>'
        },
        {
          code: 'mex',
          city: 'Mexico City',
          name: 'Mexico City, Mexico',
          address:
            'Tonalá 10<br />Colonia Roma Norte<br />06700, Ciudad de México',
          ft: 'Curso impartido en español',
          pt: 'Curso impartido en español',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>$15,000MXN and $45,000MXN</b><br />Te winnen bedragen voor de internationale studiebeurs <b>$20,000MXN and $45,000MXN</b>'
        },
        {
          code: 'mia',
          city: 'Miami',
          name: 'Miami, United States',
          address: '120 SW 8th St<br />Miami FL 33130<br />United States',
          ft: 'Alle lessen zijn in het Engels',
          pt: 'Alle lessen zijn in het Engels',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>$2,000 - $12,000</b><br />Te winnen bedragen voor de internationale studiebeurs <b>$3,000 - $12,000</b>'
        },
        {
          code: 'par',
          city: 'Paris',
          name: 'Paris, France',
          address: '226 Boulevard Voltaire<br />75011 Paris',
          ft: 'Cursussen zijn in het Engels',
          pt: 'Cursussen zijn in het Frans',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>1500€, 2500€ and 8000€</b><br />Te winnen bedragen voor de internationale studiebeurs <b>2500€, 3500€ and 8000€</b>'
        },
        {
          code: 'sao',
          city: 'São Paulo',
          name: 'São Paulo, Brazil',
          address:
            'Alameda Jaú, 1301 - Jardim Paulista<br />São Paulo - SP, 01420-001',
          ft: 'Course in Portuguese, content in English',
          pt: 'Course in Portuguese, content in English',
          amounts:
            'Te winnen bedragen voor de nationale studiebeurs <b>$3.500, $5.000 and $7.000</b><br />Te winnen bedragen voor de internationale studiebeurs <b>$5.500, $7.000 and $9.000</b>'
        }
      ],
      courses: {
        title: 'Upcoming Cohorts',
        noCohorts: 'The campus has no available cohorts'
      }
    },
    pardotForm: {
      sectionId: 'pardot-form',
      title: 'Meld je aan voor onze studiebeurzen',
      formUrl: 'https://www2.ironhack.com/l/688303/2019-12-02/8vmh6'
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Waarom deze samenwerking tussen Ironhack en De Sims?',
          answer: [
            'Met De Sims 4™ Discover University Expansion Pack, kunnen Sims naar school gaan om de meest in-demand skills te leren, voor hun toekomstige carrières. En met De Sims en Ironhack, is het nu jouw kans om hetzelfde te doen. We bieden spelers de mogelijkheid om hun in-game leven, naar de echte wereld te brengen. Deze studiebeurs moedigt je aan om spannende carrières binnen de video game- en tech industrie te ontdekken, door middel van Web Development, UXUI Design of Data Analytics.',
            'We willen jou helpen om je toekomst in de hand te nemen, binnen en buiten het Sims spel -- meld je nu aan voor een studiebeurs en mis deze kans niet!'
          ]
        },
        {
          question: 'Wat zijn de selectie criteria?',
          answer: [
            'Je hebt geen voorkennis nodig, maar we testen je logisch denkvermogen en je kunnen om te leren en je creativiteit. Dit om zeker te zijn dat jij als persoon aansluit voor dit type leeromgeving. We kijken ook naar je motivatie en beredenering om mee te doen, zodat je succes verzekerd is.',
            'Daarnaast moet je 18 jaar of ouder zijn.'
          ]
        },
        {
          question: 'Uit welke campussen kan ik kiezen voor dit programma?',
          answer: [
            'Je kan kiezen uit de Ironhack campussen in: Madrid, Barcelona, Parijs, Amsterdam, Berlijn, Lissabon, Miami, Mexico en Sao Paulo. De opledingen, startdata, lestalen en prijzen kunnen variëren.',
            'Wees je ervan bewust dat je fysiek aanwezig moet zijn op de bestemming van jouw keuze tijdens de bootcamp, lessen kunnen NIET online/op afstand gevolgd worden.'
          ]
        },
        {
          question: 'Hoe ziet het aanmeldingsproces er uit?',
          answer: [
            '1; Vul het aanmeldingsformulier in',
            '2: Voltooi de technische test online',
            '3. Voltooi het persoonlijke interview online'
          ]
        },
        {
          question:
            'Moet ik mijn huidige baan opzeggen om de opleiding te volgen?',
          answer:
            'Het korte antwoord: Nee! Het lange antwoord: dit hangt af van de opleiding die je kiest. De part-time bootcamps zijn ontworpen om naast een baan te kunnen afronden: je volgt twee lessen per week in de avond, en een lesdag op zaterdag. Voor de full-time variant, moet je echter full-time beschikbaar zijn gedurende de bootcamp. Kijk met je werkgever, of je een vrije periode kan aanvragen.'
        },
        {
          question: 'Welke Career Services bieden jullie aan?',
          answer:
            'Tijdens onze 5+ jaar dat we studenten helpen bij het bereiken van sucessverhalen en hun droombaan, hebben we een speciaal programma ontworpen die studenten ondersteunt bij het vinden van een nieuwe baan. Hiervoor hebben we een sterk netwerk aan Hiring Partners, een toegewijd Outcomes Team en een compleet begrip van waar de tech markt naar op zoek is. We helpen je door middel van een-op-een coaching, workshops en CV/portfolio building en het voorbereiden van interviews. Elke bootcamp wordt afgesloten met een Career Week, waarin je top-notch bedrijven zult ontmoeten en je eerste sollicitatiegesprekken aflegt tijdens de speed dates. Ons Outcomes Team, helpt je zelfs na je graduation nog verder om je droombaan te vinden.'
        },
        {
          question: 'Bieden jullie studenten visums, of ondersteuning hierbij?',
          answer:
            'Helaas, niet; we kunnen geen ondersteuning bij het aanvraagproces bieden of kandidaten voorzien van visums. Internationale studenten zijn zelf verantwoordelijk voor het correct aanvragen van een visum.'
        },
        {
          question: 'Bieden jullie accommodatie tijdens de opleiding?',
          answer:
            'Nee, we bieden helaas geen accommodatie tijdens de bootcamp. Elke kandidaat is hier zelf voor verantwoordelijk.'
        },
        {
          question:
            'Ik heb een studiebeurs gewonnnen, kan ik dit combineren met een andere korting of actie?',
          answer:
            'Studiebeurzen kunnen niet in combinatie met andere acties en/of kortingen worden gebruikt.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Algemene voorwaarden',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/electronic-arts-scholarship/terms-and-conditions-nl.pdf'
    }
  }
};
