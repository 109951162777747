module.exports = {
  context: {
    url: '/en/social-impact/facebook-scholarship',
    lang: 'en',
    breadcrumb: 'Facebook Scholarship',
    seo: {
      title: 'Ironhack Web Development Bootcamp & UX/UI Design Bootcamp',
      description:
        'Learn to code and UX/UI design through one of our full time or part time courses. Web Development Bootcamp and UX/UI Design Bootcamp in Madrid, Barcelona, Miami, Paris, Mexico City, Berlin and Lisbon.'
    },
    hreflangs: {},
    header: {
      title: 'The Digital Creators Fund',
      titleHighlighted: 'Powered by Facebook',
      backgroundImage: 'facebook/header.png',
      alternativeLogo: 'facebook/logo.svg',
      alternativeLogoAltText: 'Facebook logo',
      paragraph:
        'Facebook and Ironhack are excited to announce a partnership in our effort to make education accessible and practical for everyone. Facebook has pledged $250,000 for full and partial scholarships to one of our career-changing courses.'
    },
    navbar: {
      text: [
        "Facebook is partnering with Ironhack to give away $250K in scholarships to help YOU launch your new career in tech. Want to change your life? We'll help you make it happen. #createwithFB"
      ],
      linkHref: 'https://ironhack.typeform.com/to/Qtih8w'
    },
    video: {
      href: 'https://www.youtube.com/embed/KNxfcUUTK6Y'
    },
    valueProposition: {
      content: [
        {
          title: 'Why do Ironhack & Facebook want to fund your education?',
          text: [
            'We believe that everyone should have the opportunity to fulfill their dream and reinvent themself regardless of their background. Ironhack Miami has a history of educating individuals from diverse backgrounds and Facebook wants to encourage that even further. Our shared goal in this partnership is to help eliminate the financial barrier so you can launch yourself into a future career that you are passionate about and develop products that will help give more people the power to connect.'
          ]
        },
        {
          title: 'How it Works',
          text: [
            'Fill out the scholarship application',
            'Receive study materials and take a technical test',
            'Participate in a personal interview'
          ],
          isList: true
        },
        {
          title: 'Deadlines',
          text: [
            'Summer Session Deadline: May 10th, 2019',
            'Fall Session Deadline: May 31, 2019',
            'Summer Session Courses: Any course starting in June',
            'Fall Session Courses: Any course in August or October'
          ],
          isList: true
        }
      ]
    },
    courses: {
      title: 'Our Courses',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Become a UX/UI designer. Learn user-centered design applying the Design Thinking process and validate your ideas through user research, prototyping, user testing, and heuristic evaluation. Learn UI and IxD to prototype your solutions using visual and interaction design techniques. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Become a full fledged data analyst. Learn to clean and explore a wide variety of datasets. Dive deep into serious works in data analytics using Pandas to turn your data into amazing visualizations for story-telling purposes. Wrap everything up with machine learning and apply your learnings to datasets in the real world. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        }
      ]
    },
    testimonials: {
      title: 'Past Scholarship Winners',
      items: [
        {
          image: 'facebook/sherwin-garcia-obregon.jpg',
          name: 'Sherwin Garcia-Obregon',
          position: 'Web Development Part-Time',
          description:
            'A small risk is all it took and as a result, I was rewarded with an amazing community and a fulfilling career that is helping me go further than I could ever imagine. I used to apply to jobs, but now jobs apply to me!'
        },
        {
          image: 'facebook/brenda-matos.jpg',
          name: 'Brenda Matos',
          position: 'UX/UI Design Full-Time',
          description:
            "I couldn't have made a better choice. Ironhack staff treated us like family, were truly invested in our success, and provided a life-changing experience. I am so happy with the decision to attend and am so proud to be an Ironhacker."
        },
        {
          image: 'facebook/daniel-soraluz.jpg',
          name: 'Daniel Soraluz',
          position: 'Web Development Full-Time',
          description:
            'It is one of the best decisions that I have made to date. I enrolled with the intent to further my skills as a developer and put myself in a better position to enter the market as a professional but I walked away with so much more. Everyone is extremely hands on and will go above and beyond to ensure that your experience is one that promotes your success.'
        }
      ]
    },
    careerSupport: {
      title: 'Land Your Dream Job',
      text:
        'Ironhack is commited to helping you secure a new job after finishing your bootcamp. Ironhack alumni work at companies like:',
      logos: [
        {
          name: 'Google',
          image: 'google.png'
        },
        {
          name: 'Magic leap',
          image: 'magic-leap.png'
        },
        {
          name: 'Visa',
          image: 'visa.png'
        },
        {
          name: 'Deloitte',
          image: 'deloite.png'
        },
        {
          name: 'Office Depot',
          image: 'office-depot.jpg'
        },
        {
          name: 'Royal Caribbean',
          image: 'royal-caribbean.png'
        },
        {
          name: 'PGA',
          image: 'pga.jpg'
        },
        {
          name: 'Zumba',
          image: 'zumba.png'
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Who can apply to the scholarship?',
          answer: [
            'Applicants must:',
            '· Be 18 years of age or older',
            '· Be fluent in English (all of our courses are taught in English)',
            '· Have their own working laptop (Macbook required for the UX/UI course)'
          ]
        },
        {
          question: 'What courses can I apply to?',
          answer:
            "This scholarship will allow you to study Web Development, Data Analytics, or UX/UI Design at Ironhack Miami's campus in 2019. Please note that minority applicants will be given first priority (Black, Latino/Hispanic, Native American/Native Alaskan & Women) but all are welcome and encouraged to apply!"
        },
        {
          question: 'What is the application process like?',
          answer:
            "After you submit your application, we'll send you study materials with a technical test. If you pass the technical test, we'll invite you to participate in a personal interview."
        },
        {
          question: 'What are the deadlines?',
          answer: [
            'We have 2 deadlines: one for the summer classes (any bootcamp in June) and one for the fall classes (any bootcamp in August or October).',
            'Application deadline for the summer session: May 10th, 2019',
            'Application deadline for the fall session: May 31, 2019',
            "We'll make scholarship award decisions on a rolling basis, so the sooner you apply, the better!"
          ]
        },
        {
          question:
            'What are Ironhack and Facebook looking for in a scholarship winner?',
          answer:
            "The scholarship recipients must have curiosity, passion, and drive. We also expect applicants to know which course they'd be interested in taking and why (you can request the syllabus for each course online). For web development scholarship recipients, we will be prioritizing applicants who are Black, Latino/Hispanic, Native American/Native Alaskan and/or Women."
        }
      ]
    },
    campus: {
      title: 'Discover Our Campus',
      campus: {
        name: 'Ironhack Miami Campus',
        video: 'https://www.youtube.com/embed/OXZWp1eVedo',
        address1: '120 SW 8th Street, Miami, FL 33130'
      }
    },
    moreInfo: {
      text: 'Any more questions?',
      linkText: 'Get in touch!',
      linkHref: 'mailto:scholarships@ironhack.com'
    },
    termsAndConditions: {
      text: 'Terms and Conditions',
      href: 'https://s3-eu-west-1.amazonaws.com/ih-web/facebook-scholarship.pdf'
    }
  }
};
