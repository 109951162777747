import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import Section, { BrowseMore, BrowseSection } from './styles';
import OurSocialActionsProps from '../interfaces/OurSocialActions';
import OurSocialActionsItemsProps from '../interfaces/OurSocialActionItems';
import OurSocialActionItem from '../OurSocialActionsItem';
import { Text2, Text3, Text4 } from '../atoms/body';

const NUMBER_OF_PROJECTS_TO_SHOW = 4;
function OurSocialActions(props: OurSocialActionsProps) {
  const [showingScolarships, setShowingScolarships] = useState();
  const [showMore, setShowMore] = useState(true);
  const [timesClicked, setTimesClicked] = useState(1);
  const { title, subtitle, scolarships, buttonCta, ...other } = props;

  const browseClass = classNames({ hide: !showMore });

  useEffect(() => {
    setShowingScolarships(
      scolarships.slice(0, NUMBER_OF_PROJECTS_TO_SHOW * timesClicked)
    );
    if (NUMBER_OF_PROJECTS_TO_SHOW * timesClicked >= scolarships.length) {
      setShowMore(false);
    }
  }, [timesClicked]);

  function generateCards() {
    return (
      showingScolarships &&
      showingScolarships.map(
        (card: OurSocialActionsItemsProps, index: number) => {
          return <OurSocialActionItem key={index} {...card} />;
        }
      )
    );
  }

  const showMoreScolarships = () => {
    setTimesClicked(timesClicked + 1);
  };

  return (
    <Layout {...other}>
      <Title1 as="h2">{title}</Title1>
      <Text3 as="p">{subtitle}</Text3>
      <Section>{generateCards()}</Section>

      <BrowseSection className={browseClass}>
        <BrowseMore onClick={showMoreScolarships}>{buttonCta}</BrowseMore>
      </BrowseSection>
    </Layout>
  );
}

export default OurSocialActions;
