module.exports = {
  context: {
    url: '/pt/financiamento/isa-lisbon',
    lang: 'pt',
    breadcrumb: 'ISA Lisbon',
    isIsa: true,
    hreflangs: {
      en: '/en/financing/isa-miami',
      pt: '/pt/financiamento/isa-lisbon',
    },
    seo: {
      title: 'Acordo de Rendimento Partilhado (ISA) da Ironhack Lisbon',
      description:
        'O Acordo de Rendimento Partilhado da Ironhack oferece a possibilidade de estudares em um dos nossos bootcamps de web-development ou design sem nenhum custo inicial.',
    },
    header: {
      title: 'Um dos 3 melhores bootcamps do mundo',
      titleHighlighted:
        'lança uma opção de pagamento diferido sem custos iniciais',
      backgroundImage: 'isa/header.png',
      paragraph:
        'Agora podes estudar num dos bootcamps full-time da Ironhack, sem nenhum custo inicial. Com o nosso programa de ISA só começas a pagar mensalidades quando tiveres obtido um emprego e ganhares mais de € 15.000/ano ou € 1.250/mês.',
    },
    navbar: {
      text: [
        '· Sem custos iniciais!',
        '· Paga apenas quando tiveres um novo emprego',
        '· Um dos 3 melhores bootcamps do mundo',
        '· Uma nova carreira em apenas 9 semanas',
        '· Bootcamps full-time de Web-Development',
      ],
      linkText: 'Inscreve-te agora',
      linkHref: 'https://ironhack.typeform.com/to/y5gFNG',
    },
    video: {
      href: 'https://www.youtube.com/embed/-XZO1nJ-1UA',
    },
    valueProposition: {
      content: [
        {
          title: 'O que é Acordo de Rendimento Partilhado da Ironhack?',
          text: [
            'O Income Sharing Agreement (ISA), ou em português Acordo de Rendimento Partilhado (ARP) é um contrato em que o aluno recebe fundos para o seu bootcamp em troca de uma percentagem fixa do seu salário bruto mensal durante um período predefinido. O programa Ironhack ISA oferece a oportunidade de estudares num bootcamp da Ironhack sem nenhum custo até encontrares emprego. O aluno só começará a pagar assim que conseguir um emprego em que ganhe, pelo menos, 15.000 €/ano ou 1.250 €/mês.',
          ],
        },
        {
          title: 'Não pagues nada até começares a trabalhar',
          text: [
            'Foca-te no curso e não te preocupes em pagar a taxa de matrícula.',
            'Depois de acabar a formação, começarás a procurar trabalho com o apoio da nossa equipa de ajuda profissional.',
            'Só pagarás 12% do teu salário mensal durante 48 meses ou até 1,5 vezes o custo da mensalidade, o que ocorrer primeiro.',
          ],
          isList: true,
        },
      ],
    },
    extraText: {
      content: [
        {
          title:
            'Quem pode solicitar um "Acordo de Rendimento Partilhado" (ISA)?',
          text: [
            '· Candidatos aos bootcamps full-time de Web-Development no campus da Ironhack Lisboa.',
            '· Que no momento da inscrição tenham, pelo menos, 18 anos de idade.',
            '· E que sejam residentes legais em Portugal.',
            '<b>Depois de considerados elegíveis, os alunos devem concluir as seguintes etapas para serem aprovados:</b>',
            '· Participar em uma entrevista pessoal com a equipa da Ironhack.',
            '· Participar e ser aprovado em uma entrevista técnica.',
            '· Cumprir os critérios de verificação de crédito.',
            '· Realizar 100% do "pre-work"',
            '<small>Deve-se ter em conta que o plano ISA está sujeito aos termos e condições especificados no contrato e / ou lei. Os direitos e responsabilidades do solicitante podem variar de acordo com a jurisdição.</small>',
          ],
        },
        {
          title:
            'Como posso me candidatar para o Acordo de Rendimento Partilhado (ISA)?',
          text: [
            '1. Preenche o formulário de inscrição.',
            '2. Faz uma entrevista pessoal com a equipa da Ironhack Lisboa.',
            '3. Receberás o material de estudo para a prova técnica no teu e-mail.',
            '4. Faz a entrevista técnica para seres aprovado.',
            '5. Se fores selecionado, revê e completa o teu Acordo de Renda Partilhada (ISA).',
            '6. Completa o "pre-work".',
            '<small>Todas as fases são eliminatórias</small>',
          ],
        },
      ],
    },
    courses: {
      title: 'Os nossos cursos',
      courses: [
        {
          name: 'Web Development',
          description:
            'Torna-te um Full Stack Web Developer. Aprende as bases de HTML5, CSS3, JavaScript, React, Express, MongoDB e de experimentação com metodologias ágeis.',
          courseFormats: [
            {
              duration: '9 SEMANAS // FULL TIME',
              name: 'Full Time',
              schedule: 'Segunda a Sexta, das 9:00 às 18:00',
            },
          ],
        },
      ],
    },
    testimonials: {
      title: 'Os nossos alunos',
      items: [
        {
          image: 'zomato/testimonials/guilherme-carmona.jpg',
          name: 'Guilherme Carmona',
          position: 'Web Development Full-Time',
          description:
            'Uma das primeiras coisas que ouves quando te inscreves na Ironhack é:" Quanto mais esforço  colocares, maior será a recompensa ". Recebemos as ferramentas, os recursos e um processo a seguir. E era exatamente isto que eu precisava para ir de alguém que mal tinha visto código na vida, para estar pronto para os próximos desafios como programador full-stack. Tudo isso cercado por uma equipa incrível e pessoas com os mesmos objetivos, uma grande comunidade da qual tenho orgulho de fazer parte.',
        },
        {
          image: 'isa/lisbon/mariana-vargas.jpg',
          name: 'Mariana Vargas',
          position: 'Web Development Full-Time',
          description:
            'A Ironhack foi uma das experiências mais significativas e desafiadoras que tive como developer. Nesta jornada de 9 semanas, aprendi a trabalhar com várias ferramentas atualmente usadas na indústria e recebi treinamento personalizado para minha carreira. O ritmo acelerado foi equilibrado com uma equipa paciente e um sólido senso de comunidade. Vindo de uma formação em tecnologia, o bootcamp foi perfeito para aprimorar minhas habilidades, mas o processo é adequado para todos os níveis iniciais e diferentes objetivos profissionais.',
        },
      ],
    },
    careerSupport: {
      title: 'Apoio Profissional',
      text: [
        '<b>Uma metodologia comprovada</b>',
        'Ironhack tem como foco ajudar os nossos estudantes a conseguir um novo emprego assim que concluírem o curso. Com o teu empenho e o nosso apoio estamos certos que conseguirás alcançar teus objetivos profissionais.',
        'Durante os mais de 5 anos em que ajudámos os nossos alunos a alcançar o sucesso, desenvolvemos uma compreensão plena sobre o que é preciso para conseguir um emprego no ecossistema digital.',
        'Atrás deste sucesso, está uma forte rede de parcerias com empresas, uma equipa dedicada a resultados e um profundo conhecimento do mercado tecnológico e de como se movimentar nele.',
      ],
      logos: [
        {
          name: 'Google',
          image: 'google.png',
        },
        {
          name: 'Onfido',
          image: 'onfido.png',
        },
        {
          name: 'Twitter',
          image: 'twitter.png',
        },
        {
          name: 'Cabify',
          image: 'cabify.png',
        },
        {
          name: 'Landing Jobs',
          image: 'landing-jobs.png',
        },
        {
          name: 'Visa',
          image: 'visa.png',
        },
        {
          name: 'Deloitte',
          image: 'deloite.png',
        },
        {
          name: 'Everis',
          image: 'everis.jpg',
        },
      ],
    },
    faq: {
      title: "FAQ's",
      items: [
        {
          question: 'O que é um Acordo de Rendimento Partilhado (ISA)?',
          answer: [
            'O ISA é um Acordo de Rendimento Partilhado, no qual o aluno recebe fundos para o seu bootcamp. Em troca, o aluno concorda em partilhar uma porcentagem fixa do seu reindimento após a graduação por um período definido.',
          ],
        },
        {
          question: 'Quando é que termina a obrigação com o ISA?',
          answer: [
            'Um ISA termina quando ocorre uma das seguintes condições:',
            '- O aluno completa o número necessário de pagamentos (48).',
            '- O aluno atinge o limite máximo de pagamento (1,5 vezes).',
          ],
        },
        {
          question:
            'Quais são os benefícios de um Acordo de Rendimentos Partilhados (ISA) para mim?',
          answer: [
            'Este programa oferece uma alternativa aos créditos. O crédito pode criar custos adicionais para caso não puderes cobrir os pagamentos durante e após o campo de inicialização. Os pagamentos de um acordo de rendimento partilhado (ISA) são ajustados de acordo com o nível de rendimento que obtens após acabares a formação e obteres um emprego. O limite mínimo de rendimento e o limite de pagamento garantem que não pagues se não acessares o nível mínimo de rendimento (15.000 €/ano ou 1.250 €/mês); por outro lado, se obtiveres uma quantia significativa de renda, não pagaras mais 1,5 vezes o custo da aula.',
          ],
        },
        {
          question: 'Como pago meu Acordo de Rendimento Partilhado (ISA)?',
          answer: [
            'As taxas mensais são calculadas aplicando uma porcentagem fixa (12%) sobre o teu rendimento mensal total. Receberas as faturas mensais desse valor e essas taxas podem ser pagas on-line, por telefone ou por e-mail.',
          ],
        },
        {
          question:
            'O que acontece se não consigo um emprego ou ganhar menos que o limite mínimo?',
          answer: [
            'O limite mínimo de renda para o ISA é de 15.000 €/ano ou 1.250 €/mês. Se não conseguires trabalho ou a tua renda cai abaixo de 15.000 €/ano ou 1.250 €/mês, os teus pagamentos serão pausados. Os pagamentos serão retomados quando a tua renda exceder esse limite.',
          ],
        },
        {
          question:
            'Existe alguma maneira de liquidar meu Acordo de Rendimentos Partilhados (ISA) com antecedência?',
          answer: [
            'Sim. Podes pagar antecipadamente o ISA pagando o pagamento máximo (1,5 vezes a taxa de matrícula) menos todos os pagamentos efetuados até aquele momento. Não há penalidades para efetuar um pagamento antecipado.',
          ],
        },
        {
          question: 'Preciso pagar mesmo que não complete o bootcamp?',
          answer: [
            'Se saires do programa antes de completar 40% do campo de inicialização, o teu Contrato de Rendimentos Partilhados (ISA) será rateado de acordo com o número de dias concluídos no curso. Se saires do programa após ter participado de 40% do curso, deveras cumprir os termos do contrato ISA de acordo com a tua renda.',
          ],
        },
        {
          question:
            'Tenho que pagar o valor que recebi pela inscrição no bootcamp?',
          answer: [
            'A obrigação de pagamento é igual à porcentagem da renda acordada no contrato (12%). O valor que deves pagar não está vinculado ao valor do benefício que recebas.',
          ],
        },
        {
          question: 'Devo seguir uma profissão específica?',
          answer: [
            'Não. Não existe nenhuma obrigação sobre o tipo de trabalho ou trabalho escolhido após a formatura.',
          ],
        },
        {
          question:
            'Quais são os principais termos do acordo de partilha de rendimento (ISA)?',
          answer: [
            '<b>Valor do acordo de partilha de renda (ISA):</b> o valor financiado, por exemplo, o valor da taxa de matrícula (6.500 €).',
            '<b>Rendimento a partilhar:</b> a porcentagem mensal do salário que pagaras (12%).',
            '<b>Prazo:</b> Número de pagamentos mensais necessários para cobrir a obrigação ISA acordada (48 meses).',
            '<b>Limite mínimo de rendimento:</b> salário abaixo do qual os pagamentos são retidos (15.000 €/ano ou 1.250 €/mês).',
            '<b>Pagamento máximo:</b> o valor máximo que podes precisar pagar, expresso como um múltiplo do valor do ISA (1,5 vezes).',
          ],
        },
        {
          question:
            'Um Acordo de Rendimentos Partilhados (ISA) é o mesmo que um crédito?',
          answer: [
            'Não. Os elementos de um Acordo de Rendimento Partilhado (ISA) são diferentes dos de um empréstimo tradicional, todos os elementos são fixados e limitados pelos níveis de rendimento do aluno. Se o rendimento do aluno não permitir cobrir a sua obrigação porque está abaixo do limite mínimo, ela será interrompida.',
          ],
        },
      ],
    },
    campus: {
      title: 'O nosso novo campus (abertura em 2020):',
      campus: {
        name: 'Ironhack Lisbon',
        image: 'zomato/lisbon-campus.jpg',
        address: 'Heden Waterfront, Doca Jardim do Tabaco',
        address2: 'Terminal de Cruzeiros de Lisboa, Edifício NW, 1º Piso',
        address3: '1100-651 Lisboa',
        email: 'lisbon@ironhack.com',
      },
    },
    moreInfo: {
      title: 'Ainda tens dúvidas?',
      linkText: 'Contacta-nos',
      linkUrl: 'mailto:lisbon@ironhack.com',
    },
    termsAndConditions: {
      text: 'Termos e Condições',
      href: 'https://www.ironhack.com/pt/legal/aviso-legal',
    },
  },
};
