module.exports = {
  template: 'electronicArts',
  context: {
    url: '/fr/impact-social/bourse-ea-thesims',
    lang: 'fr',
    breadcrumb: 'Bourse Les Sims',
    seo: {
      title: 'Prenez en main votre avenir avec la bourse Les Sims et Ironhack',
      description:
        "Apprenez des compétences digitales avec Les Sims et Ironhack. Postulez ici pour gagner une bourse pour une formation tech dans l'un des neuf campuses d'Ironhack dans le monde."
    },
    header: {
      title: 'Prenez en main votre avenir avec la collaboration',
      titleHighlighted: 'Les Sims et Ironhack',
      backgroundImage: 'electronic-arts/header.png',
      alternativeLogo: 'electronic-arts/logo.png',
      alternativeLogoAltText: 'Electronic Arts',
      alternativeLogoClass: 'electronic-arts',
      subtitle: 'Passez au prochain niveau de votre carrière !',
      paragraph:
        "A l'occasion de la sortie du jeu The Sims™ 4 Discover University, Les Sims et Ironhack s'associent pour vous encourager à retourner à l'école. Vous pouvez postuler pour gagner une bourse pour participer à l'un des bootcamps d'Ironhack à temps plein ou à temps partiel. Pendant que vos Sims vont étudier à l'institut Foxbury, faites en de même en suivant un bootcamp de Web Development, UX/UI Design ou Data Analyse  dans l'un des 9 campus d'Ironhack à travers le monde. A l'issue de cette formation intensive de 9 semaines les diplômés auront appris des compétences recherchées et utilisées dans le secteur de la tech, notamment dans l'industrie du jeu vidéo."
    },
    navbar: {
      text: [
        "Prenez le contrôle de votre carrière et apprenez de nouvelles compétences ! Postulez ici pour tenter de gagner une bourse pour participer à un bootcamp sur l'un des 9 campus d'Ironhack."
      ],
      linkHref: '',
      linkText: 'Postuler'
    },
    video: {
      href: '/landings/electronic-arts/landing-image.png',
      isVideo: false
    },
    valueProposition: {
      title:
        '800 000€ de bourses<em>pour jouer un rôle dans la tech et prendre le contrôle de votre futur !</em>',
      content: [
        {
          title: 'Quels cursus sont concernés par la bourse ?',
          text: [
            'Le programme de bourses Les Sims et Ironhack concerne tous les cursus proposés par Ironhack: Développement Web, Design UX/UI et Data Analyse. Vous pouvez choisir la date disponible qui vous convient le mieux, ainsi que le format : à Temps Plein (en Anglais) ou à Temps Partiel (en français).'
          ]
        },
        {
          title: 'Suis-je éligible à une bourse ?',
          text: [
            "Vous êtes éligible si vous êtes vous êtes majeur.e ! Pas besoin d'avoir une experience ou un background spécifique dans un certain domaine, juste une réelle motivation et volonté d'apprendre de nouvelles compétences!"
          ]
        },
        {
          title: 'Comment devenir étudiant.e Ironhack ?',
          text: [
            "En participant sur ce site jusqu'au 17 Décembre ! Remplissez le formulaire et choisissez la formation pour laquelle vous aimeriez gagner une bourse ! Nous faisons ensuite une sélection parmi les candidat.e.s qui auront l'occasion de passer un test technique et un entretien de motivation avec nous..",
            "Pas de panique, ce n'est pas si compliqué, il suffit de se préparer. Tout le processus d'admission peut se faire à distance, en revanche les bootcamps demandent d'être présent.e.s physiquement dans le pays et la ville de votre choix."
          ]
        },
        {
          title:
            "Est ce que je peux déposer ma candidature si je n'habite pas dans un des pays où il y a un campus Ironhack?",
          text: [
            "Oui, vous pouvez postuler dans le pays - campus de votre choix! Cependant, pour les candidat.e.s internationaux, en cas d'obtention d'une bourse, vous devrez vous organiser de votre coté pour les éventuels visas, transport, et logement."
          ]
        }
      ]
    },
    courses: {
      title: 'Les Cursus',
      courses: [
        {
          name: 'Développement Web',
          description:
            'Vous avez toujours voulu apprendre à coder ? La formation en Développement Web de Ironhack vous permet de développer toutes les compétences nécessaires pour développer des sites et des applications web en utilisant plusieurs langages de programmation (HTML, CSS, JavaScript). Nous offrons ce cours en 2 formats : à temps plein ou à temps partiel.',
          courseFormats: [
            {
              name: 'Temps Plein',
              duration: '9 SEMAINES // TEMPS PLEIN',
              schedule:
                '9 semaines intensives, du lundi au vendredi de 9h00 à 18h30.'
            },
            {
              name: 'Temps Partiel',
              duration: '24 SEMAINES // Temps Partiel',
              schedule:
                '2 soirs / semaine de 18h30 à 21h30, et les samedis de 10h00 à 17h00.'
            }
          ]
        },
        {
          name: 'UX/UI Design',
          description:
            "Analyser le comportement des utilisateurs, comprendre les problèmes qu’ils rencontrent, puis concevoir et designer des solutions qui y répondent, ça vous parle ? Apprenez à concevoir un design orienté utilisateur en utilisant le procédé de Design Thinking et validez vos idées grâce à des enquêtes utilisateurs, des prototypages, des tests utilisateur ainsi que des évaluations heuristiques. Apprenez l’UI, et l'IxD pour prototyper vos solutions en utilisant des techniques de conception visuelle et d'interaction. Nous offrons ce cours en 2 formats : à temps plein ou à temps partiel.",
          courseFormats: [
            {
              name: 'Temps Plein',
              duration: '9 SEMAINES // TEMPS PLEIN',
              schedule:
                '9 semaines intensives, du lundi au vendredi de 9h00 à 18h30.'
            },
            {
              name: 'Temps Partiel',
              duration: '24 SEMAINES // Temps Partiel',
              schedule:
                '2 soirs / semaine de 18h30 à 21h30, et les samedis de 10h00 à 17h00.'
            }
          ]
        },
        {
          name: 'Data Analyse',
          description:
            'Apprenez à nettoyer, explorer et analyser une large variété de datasets. Entrez en immersion dans le travail de data analyste en utilisant Python, et apprenez à mettre en forme vos données pour des présentations remarquables, dans une optique de story-telling. Le bootcamp se conclura avec une introduction au machine learning et vous appliquerez ce que vous avez appris à des datasets utilisés dans le secteur pour différents projets.',
          courseFormats: [
            {
              name: 'Temps Plein',
              duration: '9 SEMAINES // TEMPS PLEIN',
              schedule:
                '9 semaines intensives, du lundi au vendredi de 9h00 à 18h30.'
            },
            {
              name: 'Temps Partiel',
              duration: '24 SEMAINES // Temps Partiel',
              schedule:
                '2 soirs / semaine de 18h30 à 21h30, et les samedis de 10h00 à 17h00.'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Process de participation',
      steps: [
        {
          title: 'Étape 1: Remplir le formulaire de candidature',
          description: "Candidatures ouvertes jusqu'au 17 Décembre 2019"
        },
        {
          title: 'Étape 2: Faire le test technique',
          description:
            "Vous recevrez un mail avec du contenu pour préparer un test technique à faire en ligne, relatif au cursus choisi. Lisez le contenu, et faites le test lorsque vous êtes prêt.e. Pas de panique, ce n'est pas si difficile si vous vous préparez. Tests techniques ouverts jusqu'au 5 Janvier 2020."
        },
        {
          title: 'Étape 3: Entretien de motivation',
          description:
            "Suite au test technique, vous recevrez une invitation pour un entretien de motivation de 10-15 minutes avec un.e membre de l'équipe Ironhack locale. Cela peut être fait à distance par téléphone, ou sur le site si vous vivez proche du campus de votre choix. Entretiens de motivation ouverts jusqu'au 24 Janvier 2020."
        },
        {
          title: 'Étape 4: Résultats',
          description:
            'Les gagnants seront annoncés la semaine du 24 Janvier 2020.'
        },
        {
          title: "J'ai gagné une bourse ! Et maintenant ?",
          description:
            "D'abord, il faut confirmer la date à laquelle vous souhaitez effectuer votre formation. Si vous avez remporté une bourse partielle et que vous choisissez de nous rejoindre, le solde résiduel du prix de la formation sera dû et vous devrez confirmer votre inscription en payant un dépot de garantie. Si vous avez gagné une bourse complète, alors il ne vous reste rien à payer concernant les frais de formation, il vous suffit juste de nous confirmer par écrit votre présence à la session de votre choix."
        }
      ]
    },
    campuses: {
      selectAnother: 'Choisir un autre campus',
      noCampusTitle: 'Choisissez votre Pays - Campus',
      campusTitle: 'Vous avez sélectionné',
      discover: 'Visit our Campus!',
      apply: 'Postuler',
      items: [
        {
          code: 'ams',
          city: 'Amsterdam',
          name: 'Amsterdam, Netherlands',
          address: 'Prinsengracht 437<br />1016 HM<br />Amsterdam',
          ft: 'Tous les cours et contenus sont anglais',
          pt: 'Tous les cours et contenus sont anglais',
          amounts:
            'Montants bourses nationale: <b>7000€, 3000€, 2000€ and 1000€</b><br />Montants bourses internationale: <b>3500€, 2500€ and 1500€</b>'
        },
        {
          code: 'bcn',
          city: 'Barcelona',
          name: 'Barcelona, Spain',
          address: 'Carrer de Pamplona 96<br />08018 Barcelona',
          ft:
            'Both courses in English, content in English and courses in Spanish, content in English',
          pt: 'Course always in Spanish, content in English',
          amounts:
            'Montants bourses nationale: <b>1250€</b><br />Montants bourses internationale: <b>2000€ and 7000€</b>'
        },
        {
          code: 'ber',
          city: 'Berlin',
          name: 'Berlin, Germany',
          address: 'c/o Techspace<br />Lobeckstraße 36-40<br />10969 Berlin',
          ft: 'Cours en anglais, contenu en anglais',
          pt: 'Cours en anglais, contenu en anglais',
          amounts: 'Montants bourses: <b>1125€, 1875€, 3750€ and 7500€</b>'
        },
        {
          code: 'lis',
          city: 'Lisbon',
          name: 'Lisbon, Portugal',
          address:
            'Heden Waterfront<br />Doca Jardim do Tabaco<br />Terminal de Cruzeiros de Lisboa<br />Edifício NW, 1º Piso, 1100-651 Lisboa',
          ft: 'Tous les cours et contenus sont anglais',
          pt: 'Tous les cours et contenus sont anglais',
          amounts: 'Montants bourses: <b>6000€ and 2000€</b>'
        },
        {
          code: 'mad',
          city: 'Madrid',
          name: 'Madrid, Spain',
          address: 'Paseo de la Chopera, 14<br />28045 Madrid',
          ft: 'Course in Spanish, contents in English',
          pt: 'Course in Spanish, contents in English',
          amounts:
            'Montants bourses nationale: <b>1250€</b><br />Montants bourses internationale: <b>1500€</b>'
        },
        {
          code: 'mex',
          city: 'Mexico City',
          name: 'Mexico City, Mexico',
          address:
            'Tonalá 10<br />Colonia Roma Norte<br />06700, Ciudad de México',
          ft: 'Curso impartido en español',
          pt: 'Curso impartido en español',
          amounts:
            'Montants bourses nationale: <b>$15,000MXN and $45,000MXN</b><br />Montants bourses internationale: <b>$20,000MXN and $45,000MXN</b>'
        },
        {
          code: 'mia',
          city: 'Miami',
          name: 'Miami, United States',
          address: '120 SW 8th St<br />Miami FL 33130<br />United States',
          ft: 'Cours en anglais, contenu en anglais',
          pt: 'Cours en anglais, contenu en anglais',
          amounts:
            'Montants bourses nationale: <b>$2,000 - $12,000</b><br />Montants bourses internationale: <b>$3,000 - $12,000</b>'
        },
        {
          code: 'par',
          city: 'Paris',
          name: 'Paris, France',
          address: '226 Boulevard Voltaire<br />75011 Paris',
          ft: 'Cours en anglais, contenu en anglais',
          pt: 'Cours en français, contenu en anglais',
          amounts:
            'Montants bourses nationale: <b>1500€, 2500€ and 8000€</b><br />Montants bourses internationale: <b>2500€, 3500€ and 8000€</b>'
        },
        {
          code: 'sao',
          city: 'São Paulo',
          name: 'São Paulo, Brazil',
          address:
            'Alameda Jaú, 1301 - Jardim Paulista<br />São Paulo - SP, 01420-001',
          ft: 'Course in Portuguese, content in English',
          pt: 'Course in Portuguese, content in English',
          amounts:
            'Montants bourses nationale: <b>$3.500, $5.000 and $7.000</b><br />Montants bourses internationale: <b>$5.500, $7.000 and $9.000</b>'
        }
      ],
      courses: {
        title: 'Upcoming Cohorts',
        noCohorts: 'The campus has no available cohorts'
      }
    },
    pardotForm: {
      sectionId: 'pardot-form',
      title: 'Postuler à la Bourse',
      formUrl: 'https://www2.ironhack.com/l/688303/2019-11-28/82wt4'
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Pourquoi ce partenariat entres Les Sims et Ironhack',
          answer: [
            "Dans The Sims 4 Discover University Expansion Pack, les Sims peuvent retourner à l'école pour apprendre des compétences recherchées pour leurs futures carrières. Avec la bourse Les Sims et Ironhack, vous avez l'opportunité de faire la même chose. Nous offrons aux joueur.euse.s. la possibilité d'étendre leur expérience de jeu dans le monde réel.",
            "Les bourses encouragent les fans à apprendre et découvrir des métiers passionnants dans les secteurs du jeu vidéo et de la technologie, à travers des formations au Développement Web, à l'UXUI Design et à la Data Analyse."
          ]
        },
        {
          question:
            "Quels sont les critères de sélection ? Ai-je besoin d'expérience préalable",
          answer: [
            'Vous devez être majeur.e (18 ans révolus), c’est tout ! Il n’y a pas besoin d’expérience ou de diplômes particuliers, mais simplement de la motivation !',
            "Nous évaluerons votre capacité à apprendre, votre logique et votre motivation, car les bootcamps sont des formats d'apprentissage intenses, et il faut être motivé.e pour réussir."
          ]
        },
        {
          question:
            'Dans quel campus puis-je postuler pour ce programme de bourses?',
          answer: [
            "Il vous est aussi possible de déposer votre candidature pour l'un des campus suivant: Paris, Barcelone, Amsterdam, Lisbonne, Berlin, Miami, Mexico ou Sao Paulo. Les dates de début des bootcamps et les langues des cours varient selon chaque campus. Cependant vous devez faire un choix ! Nous n’offrons pas de formation en ligne ou à distance."
          ]
        },
        {
          question: 'Comment participer?',
          answer: [
            '1: Remplir le questionnaire de participation',
            '2: Test technique en ligne',
            '3: Entretien de motivation'
          ]
        },
        {
          question: 'Dois-je quitter mon emploi pour faire la formation ?',
          answer:
            "Réponse courte : non ! Réponse longue : ça dépend du format (temps plein ou temps partiel) auquel vous postulez. Le format temps partiel est adapté aux personnes qui ont une activité à temps plein (2 soirs / semaine + les samedi). En revanche, si vous souhaitez suivre un format temps plein, il faudra alors vous mettre d'accord avec votre employeur ou bien quitter votre emploi."
        },
        {
          question: "M'aidez-vous à trouver un emploi après la formation ?",
          answer:
            "Oui ! Nous avons une équipe dédiée pour nos Services Carrières dont le rôle est de vous préparer pour le marché de l'emploi à travers du coaching (présence en ligne, portfolio, entretiens) et des mises en relation avec des entreprises. La dernière semaine de formation est entièrement dédiée à la recherche d'emploi dans le milieu de la tech."
        },
        {
          question:
            "M'aidez-vous à obtenir un visa pour aller suivre une formation?",
          answer:
            "Malheureusement nous n'aidons pas les candidats dans l'obtention de visas et ne pouvons pas fournir de documents pour obtenir des visas étudiants."
        },
        {
          question:
            'Fournissez-vous une aide au logement pendant les formations?',
          answer:
            "Nous ne fournissons pas de logement ou d'aide au logement pendant les formations. Chaque participant.e doit s'organiser pour avoir un hébergement local."
        },
        {
          question:
            "J'ai gagné une bourse partielle, est-ce que je peux la cumuler avec une autre bourse ou une autre réduction?",
          answer:
            'Les bourses gagnées sont non cumulables avec toutes autres bourses ou réductions éventuelles des frais de scolarité pour une formation Ironhack.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Termes et Conditions',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/electronic-arts-scholarship/terms-and-conditions-fr.pdf'
    }
  }
};
