module.exports = {
  context: {
    url: '/en/social-impact/landing-jobs',
    lang: 'en',
    breadcrumb: 'Landing Jobs Festival',
    seo: {
      title:
        'Become a digital creator with one of the scholarships provided by Landing.Jobs and Ironhack',
      description:
        'Change your career with an intensive bootcamp in Web Develoment, UX/UI Design or Data Analytics in Lisbon, Madrid, Barcelona or Remotely. Join the digital revolution today and land a tech job tomorrow!'
    },
    hreflangs: {},
    header: {
      title: 'Change your career, ',
      titleHighlighted: 'land a tech job',
      backgroundImage: 'landing-jobs/header.jpg',
      paragraph:
        'Ironhack and Landing.Jobs will award 300.000€ in scholarships to help you transition into a career in tech.'
    },
    navbar: {
      text: [
        "Awarded scholarships will cover partial or full tuition for Ironhack bootcamps in Lisbon, Madrid, Barcelona and Remote programs. There's no better time to become a Web-Developer, UX/UI Designer or Data Analyst. Are you ready to change your life?"
      ],
      linkHref: 'https://ironhack.typeform.com/to/yGT6VqBk#source=landing'
    },
    video: {
      href: 'https://youtube.com/embed/XrBoQjm0WKE'
    },
    valueProposition: {
      content: [
        {
          title: 'Why are we doing this?',
          text: [
            'With job losses in the millions, the COVID-19 pandemic has rendered one of the most unstable employment scenarios in recent history. Ironhack and Landing.Jobs are joined by a shared belief that the post- pandemic life will be even more digitalized, that tech ecosystem will provide the best employment opportunities moving forward and that individuals must have ownership of their own careers.',
            'Rather than a threat, both Ironhack and Landing.Jobs see this as a huge opportunity for people to reconsider and kickstart their new career path in tech. Providing access through scholarships is the best way to get closer to this purpose and create tangible impact, by enabling capable people to change their lives and transition into a career they love. '
          ]
        },
        {
          title: 'How it Works',
          text: [
            'Fill out the Landing.Jobs scholarship application.',
            'Receive study materials and take an online technical test. No previous coding or design experience is required, we expect a begginer level.',
            'If your test is successful, participate in an online personal video-interview.'
          ],
          isList: true
        },
        {
          title: 'Deadlines',
          text: [
            'All applications have to be submitted before the 28th of July.'
          ]
        }
      ]
    },
    courses: {
      title: 'Our Courses',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Become a UX/UI designer. Learn user-centered design applying the Design Thinking process and validate your ideas through user research, prototyping, user testing, and heuristic evaluation. Learn UI and IxD to prototype your solutions using visual and interaction design techniques. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Become a data analyst. Learn to load, clean, explore and extract valuable insights from a wide range of datasets as well as cultivate tools and languages such as Python, SQL and Tableau.',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full-Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm.'
            },
            {
              duration: '24 WEEKS // PART TIME',
              name: 'Part-Time Program',
              schedule:
                'Two weekdays from 6.30pm to 9.30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm.'
            }
          ]
        }
      ]
    },
    testimonials: {
      title: 'Past Scholarship Winners',
      items: [
        {
          image: 'landing-jobs/angela-sousa.jpg',
          name: 'Angela Sousa',
          position: 'UX/UI Design Full-Time',
          description:
            'I knew what I needed: real projects to work on, teachers that are passionate and available, an amazing group of people whom I can brainstorm ideas with. To sum up: I wanted to feel prepared for my career switch. I found all of this at Ironhack. I was expecting to be ready to work as a UX/UI Designer. I ended up with that and a new "family".'
        },
        {
          image: 'landing-jobs/mariana-vargas.jpg',
          name: 'Mariana Vargas',
          position: 'Web Development Full-Time',
          description:
            'Ironhack was one of the most meaningful and challenging experiences I have as a developer. In this 9-week journey, I’ve learned how to work with several tools currently used in the industry and received personalised coaching for my career. The demanding fast-paced rhythm was balanced with the most patient staff and a solid sense of community. Coming from a tech background, the bootcamp was perfect to level up my skills, but the process is suitable for every starting level and different professional goals.'
        },
        {
          image: 'landing-jobs/edmundo-miranda.jpg',
          name: 'Edmundo Miranda',
          position: 'UX/UI Design Full-Time',
          description:
            'The student scholarship was great for updating my skills and my professional growth. As a graphic designer I felt that I needed to shift to the digital world and move towards UX/UI Design. Apart from gaining new knowledge, which is always necessary, I am more prepared for the new challenges that might come in a world, that is more and more a digital one.'
        },
        {
          image: 'landing-jobs/jose-nogueira.jpg',
          name: 'José Nogueira',
          position: 'Web-Development Full-Time',
          description:
            'If I had to describe Ironhack in 3 words I would say “fun, life changing, unity”. It’s FUN, there is never a dull day. It’s life changing, because, it changes your career in 3 months, and unity because everyone at Ironhack do the impossible to help you achieve your goals.'
        }
      ]
    },
    careerSupport: {
      title: 'Find a job after the bootcamp',
      text:
        'At Ironhack we are career services focused. We have a commitment to help you secure a new job after you finish the bootcamp. Ironhackers are now working in companies like:',
      logos: [
        {
          name: 'Landing Jobs',
          image: 'landing-jobs.png'
        },
        {
          name: 'Google',
          image: 'google.png'
        },
        {
          name: 'Visa',
          image: 'visa.png'
        },
        {
          name: 'Deloite',
          image: 'deloite.png'
        },
        {
          name: 'Twitter',
          image: 'twitter.png'
        },
        {
          name: 'Capgemini',
          image: 'capgemini.png'
        },
        {
          name: 'Everis',
          image: 'everis.jpg'
        },
        {
          name: 'Cabify',
          image: 'cabify.png'
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Who can apply to the scholarship?',
          answer: [
            'Applicants must:',
            '· Be 18 years of age or older',
            '· Be fluent in the course language',
            '· Have their own working laptop'
          ]
        },
        {
          question: 'What courses can I apply to?',
          answer: [
            'You can choose between all the courses taught at Ironhack campuses in Lisbon, Madrid and Barcelona, or our Remote programs in Europe and LATAM. The courses offered, the starting dates, and the teaching languages might vary.',
            'Keep in mind that some courses are in-person, so you have to be based in the country or city of your choice at the time of the bootcamp. In the case of the Remote Programs, you can take the course from anywhere, but bear in mind tha you will still have to attend a fixed class schedule.'
          ]
        },
        {
          question: 'What is the application process like?',
          answer:
            "After you submit your application, we'll send a link with technical test. If you pass the technical test, we'll invite you to participate in an online personal video-interview."
        },
        {
          question: 'What are the deadlines?',
          answer:
            'All applications have to be submitted before the 28th of July.'
        },
        {
          question:
            'What are Ironhack and Landing.Jobs looking for in a scholarship winner?',
          answer:
            "The scholarship recipients must have curiosity, passion, and drive. We also expect applicants to know which course they'd be interested in taking and why (you can request the syllabus for each course online).  Applicants need to have a can do attitude and be motivated to change their lives."
        },
        {
          question:
            'I won a partial scholarship, can I combine this with another discount or scholarship?',
          answer:
            'All the scholarships awarded are not cumulative with any other discount or scholarship for an Ironhack course.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Terms and Conditions',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/landing-jobs-terms-and-conditions.pdf'
    }
  }
};
