import styled, { css } from 'styled-components';
import config from '../config';

const SectionTitle = styled.section`
  display: flex;
  flex-direction: column;
  margin-right: 3.2rem;
  max-width: 75%;

  & > span {
    color: ${config.palette.lightBlue};
  }

  & > p {
    margin-top: 0.8rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  & > b {
    font-size: 1.9rem;
    line-height: 168%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    max-width: 100%;
    width: calc(100% - 2.4rem);

    & > b {
      font-weight: 500;
      font-size: 2rem;
      line-height: 130%;
    }
  }
`;

const SectionButtons = styled.section`
  align-items: flex-end;
  display: flex;

  & > a:last-child {
    margin-left: auto;
    margin-rigth: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
    width: 100%;

    &:last-child {
      flex-direction: column;

      & > a:last-child {
        margin-left: 0rem;
      }

      a,
      button {
        margin-top: 1.6rem;
      }
    }
  }
`;

const Contextual = css`
  align-items: center;
  background-color: ${config.palette.skyBlueTransparent('0.1')};
  display: flex;
  padding-bottom: 3.2rem;
  padding-top: 3.2rem;
  justify-content: space-between;
  margin-bottom: 3.2rem;

  &.womenInTech {
    background: ${config.palette.orangeTransparent('0.1')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
  }
`;

const EyebrowCss = css`
  color: ${config.palette.lightBlue};
  margin-bottom: 0.8rem;

  &.womenInTech {
    color: ${config.palette.orange};
  }
`;

const Buttons = css`
  font-size: 1.8rem;
  line-height: 168%;
  padding: 2.4rem 4.4rem;
  width: 25.6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 31.2rem;
    padding: 1.4rem 0;
    text-align: center;
  }
`;

const Contact = styled.a`
  background-color: ${config.palette.lightBlue};
  border-radius: 0.6rem;
  border: 0.2rem solid ${config.palette.lightBlue};
  color: ${config.palette.white};
  font-size: 1.9rem;
  line-height: 168%;
  padding: 2.4rem 5.5rem;
  text-align: center;
  text-decoration: none;

  &.womenInTech {
    background: ${config.palette.orange};
    border-color: ${config.palette.orange};
    color: ${config.palette.white};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    line-height: 125%;
  }
`;

export {
  SectionTitle,
  SectionButtons,
  Contextual,
  EyebrowCss,
  Buttons,
  Contact
};
