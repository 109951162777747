module.exports = {
  template: 'electronicArts',
  context: {
    url: '/en/social-impact/ea-thesims-scholarship',
    lang: 'en',
    breadcrumb: 'The Sims Scholarship',
    seo: {
      title:
        'Take control of your future with The Sims and Ironhack Scholarship',
      description:
        "Learn new Tech skills with The Sims and Ironhack. Apply here to win a scholarship for a tech bootcamp at one of Ironhack's nine campuses."
    },
    header: {
      title: 'Take control of your future with',
      titleHighlighted: 'The Sims and Ironhack',
      backgroundImage: 'electronic-arts/header.png',
      alternativeLogo: 'electronic-arts/logo.png',
      alternativeLogoAltText: 'Electronic Arts logo',
      alternativeLogoClass: 'electronic-arts',
      subtitle: "It's time to step forward into your future today.",
      paragraph:
        'In honor of The Sims™ 4 Discover University, The Sims and Ironhack have teamed up to send you to school. You can apply to win a scholarship for a full time or part time bootcamp. While your Sims head off to learn at the Foxbury Institute, you can do the same at one of nine Ironhack campuses worldwide with an intensive course in Web Development, UX/UI Design, or Data Analytics. Once there, students will gain high-demand skills utilized in all tech-related industries, including video games!'
    },
    navbar: {
      text: [
        "Choose your own path, learn new skills, apply here to win a scholarship for a tech bootcamp at one of Ironhack's nine campuses."
      ],
      linkHref: '',
      linkText: 'Apply'
    },
    video: {
      href: '/landings/electronic-arts/landing-image.png',
      isVideo: false
    },
    valueProposition: {
      title:
        'Over $800,000 in scholarships<em>to play your role in tech and take control of your future!</em>',
      content: [
        {
          title: 'Which courses are included?',
          text: [
            'The Sims and Ironhack scholarship program allows you to take the Web Development, UX/UI Design, or Data Analytics courses, provided that the campus of your choice offers the course. You can choose the starting date and whether you want to join a part-time or full-time course.'
          ]
        },
        {
          title: 'Am I eligible for a scholarship?',
          text: [
            'If you are 18 years or older, you are welcome to apply. You don’t need to have any previous experience in a specific field, only a lot of motivation and enthusiasm to learn new skills!'
          ]
        },
        {
          title: 'How can I become a student?',
          text: [
            'You can apply for a scholarship until December 17th, 2019. The application process includes a technical and a personal interview to assess your motivation and ability to learn. The application process can be done remotely, but all Ironhack bootcamp classes require you to be present in the country and city of your choice.',
            'Selected participants will win partial or full scholarships for an Ironhack bootcamp. Scholarships can range from $1,200 to $12,000 depending on country and city of your choice. For more details on scholarship amounts, select the campus you are interested in.'
          ]
        },
        {
          title:
            "Can I apply if I don't live in one of the countries with an Ironhack Campus?",
          text: [
            'Yes, you can apply to the country and campus of your choice! However, for international candidates, in case you are awarded a scholarship, please keep in mind you will need to organize your own travel visa application, accommodation and transportation.'
          ]
        }
      ]
    },
    courses: {
      title: 'Our Courses',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Become a UX/UI designer. Learn user-centered design applying the Design Thinking process and validate your ideas through user research, prototyping, user testing, and heuristic evaluation. Learn UI and IxD to prototype your solutions using visual and interaction design techniques. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Become a full fledged data analyst. Learn to clean and explore a wide variety of datasets. Dive deep into serious works in data analytics using Pandas to turn your data into amazing visualizations for story-telling purposes. Wrap everything up with machine learning and apply your learnings to datasets in the real world. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Two weekdays from 6:30pm to 9:30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Application Process',
      steps: [
        {
          title: 'Step 1: Fill in the application form',
          description: 'Application are open until December 17th'
        },
        {
          title: 'Step 2: Take the technical test',
          description:
            'You will receive an email with materials to prepare for an online technical test related to the course you chose. Prepare the material and take the test when you are ready. No need to panic—it is not that hard if you prepare. Technical tests are open until January 5th, 2020.'
        },
        {
          title: 'Step 3: Personal interview',
          description:
            'If successful, you will be invited to a 10- to 15-minute personal interview with a local team member, which can be done remotely by video call or on-site if you live close to the selected campus. Personal interviews will close on January 24th, 2020.'
        },
        {
          title: 'Step 4: Results',
          description:
            'Winners will be notified during the week of 24th of January 2020.'
        },
        {
          title: 'I won a scholarship! Now what?',
          description: [
            'If you win a partial scholarship, you will need to decide if you want to attend the course and confirm your attendance by paying a deposit, which will be deducted from the remaining tuition fees.',
            'If you win a full scholarship, you will need to decide if you want to attend the course and confirm your attendance by written confirmation. Once you have confirmed your attendance to a bootcamp, get ready for an exciting, intense, and full-on learning experience!'
          ]
        }
      ]
    },
    campuses: {
      selectAnother: 'Select another campus',
      noCampusTitle: 'Choose your Country - Campus',
      campusTitle: 'You have selected',
      discover: 'Visit our Campus!',
      apply: 'Apply',
      items: [
        {
          code: 'ams',
          city: 'Amsterdam',
          name: 'Amsterdam, Netherlands',
          address: 'Prinsengracht 437<br />1016 HM<br />Amsterdam',
          ft: 'All courses are in English',
          pt: 'All courses are in English',
          amounts:
            'National scholarship amounts: <b>7000€, 3000€, 2000€ and 1000€</b><br />International scholarship amounts: <b>3500€, 2500€ and 1500€</b>'
        },
        {
          code: 'bcn',
          city: 'Barcelona',
          name: 'Barcelona, Spain',
          address: 'Carrer de Pamplona 96<br />08018 Barcelona',
          ft:
            'Both courses in English, content in English and courses in Spanish, content in English',
          pt: 'Course always in Spanish, content in English',
          amounts:
            'National scholarship amounts: <b>1250€</b><br />International scholarship amounts: <b>2000€ and 7000€</b>'
        },
        {
          code: 'ber',
          city: 'Berlin',
          name: 'Berlin, Germany',
          address: 'c/o Techspace<br />Lobeckstraße 36-40<br />10969 Berlin',
          ft: 'All courses and curriculum are in English',
          pt: 'All courses and curriculum are in English',
          amounts: 'Scholarship amounts: <b>1125€, 1875€, 3750€ and 7500€</b>'
        },
        {
          code: 'lis',
          city: 'Lisbon',
          name: 'Lisbon, Portugal',
          address:
            'Heden Waterfront<br />Doca Jardim do Tabaco<br />Terminal de Cruzeiros de Lisboa<br />Edifício NW, 1º Piso, 1100-651 Lisboa',
          ft: 'All courses are in English',
          pt: 'All courses are in English',
          amounts: 'Scholarship amounts: <b>6000€ and 2000€</b>'
        },
        {
          code: 'mad',
          city: 'Madrid',
          name: 'Madrid, Spain',
          address: 'Paseo de la Chopera, 14<br />28045 Madrid',
          ft: 'Course in Spanish, contents in English',
          pt: 'Course in Spanish, contents in English',
          amounts:
            'National scholarship amounts: <b>1250€</b><br />International scholarship amounts: <b>1500€</b>'
        },
        {
          code: 'mex',
          city: 'Mexico City',
          name: 'Mexico City, Mexico',
          address:
            'Tonalá 10<br />Colonia Roma Norte<br />06700, Ciudad de México',
          ft: 'Curso impartido en español',
          pt: 'Curso impartido en español',
          amounts:
            'National scholarship amounts: <b>$15,000MXN and $45,000MXN</b><br />International scholarship amounts: <b>$20,000MXN and $45,000MXN</b>'
        },
        {
          code: 'mia',
          city: 'Miami',
          name: 'Miami, United States',
          address: '120 SW 8th St<br />Miami FL 33130<br />United States',
          ft: 'All courses and curriculum are in English',
          pt: 'All courses and curriculum are in English',
          amounts:
            'National scholarship amounts: <b>$2,000 - $12,000</b><br />International scholarship amounts: <b>$3,000 - $12,000</b>'
        },
        {
          code: 'par',
          city: 'Paris',
          name: 'Paris, France',
          address: '226 Boulevard Voltaire<br />75011 Paris',
          ft: 'Course in English, content in English',
          pt: 'Course in French, content in English',
          amounts:
            'National scholarship amounts: <b>1500€, 2500€ and 8000€</b><br />International scholarship amounts: <b>2500€, 3500€ and 8000€</b>'
        },
        {
          code: 'sao',
          city: 'São Paulo',
          name: 'São Paulo, Brazil',
          address:
            'Alameda Jaú, 1301 - Jardim Paulista<br />São Paulo - SP, 01420-001',
          ft: 'Course in Portuguese, content in English',
          pt: 'Course in Portuguese, content in English',
          amounts:
            'National scholarship amounts: <b>$3.500, $5.000 and $7.000</b><br />International scholarship amounts: <b>$5.500, $7.000 and $9.000</b>'
        }
      ],
      courses: {
        title: 'Upcoming Cohorts',
        noCohorts: 'The campus has no available cohorts'
      }
    },
    pardotForm: {
      sectionId: 'pardot-form',
      title: 'Apply to our Scholarships',
      formUrl: 'https://www2.ironhack.com/l/688303/2019-11-19/7q63g'
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Why are The Sims and Ironhack partnering?',
          answer: [
            'With The Sims 4™ Discover University Expansion Pack, Sims can go to school to learn the in-demand skills needed for their future careers. And with The Sims and Ironhack scholarship, it’s your chance to do the same. We’re offering players the opportunity to extend their in-game experience to the real world. This scholarship encourages applicants to learn and discover some of the exciting careers within the video game and technology industries through courses like Web Development, UX/UI Design, and Data Analytics.',
            'We want to help you take control of your future, in game and out—apply for a scholarship today for a chance to do just that.'
          ]
        },
        {
          question:
            'What are the selection criteria? Do I need previous experience/knowledge to access the courses?',
          answer: [
            "You don't need previous experience; however, we will evaluate your ability to learn as well as your logic and creative skills to assess if you would be successful in this type of learning environment. We will also assess your motivation and reasons to join to ensure success in an intense learning environment.",
            'You need to be over 18 years old to apply.'
          ]
        },
        {
          question: 'Which campus can I join in this scholarship program?',
          answer: [
            'You can choose between all Ironhack campuses in Madrid, Barcelona, Paris, Amsterdam, Berlin, Lisbon, Miami, Mexico and Sao Paulo. The courses offered, the starting dates, and the teaching languages might vary.',
            'Keep in mind you have to be physically present in the country or city of your choice at the time of the bootcamp—we do not offer online or remote courses.'
          ]
        },
        {
          question: 'How does the application process work?',
          answer: [
            '1: Fill in the application form',
            '2: Take the technical test online',
            '3: Personal interview'
          ]
        },
        {
          question: 'Do I have to quit my job to take the course?',
          answer:
            'Short answer: no! Long answer: it depends on the course modality you choose. The part-time courses are designed for the employed participants—you will have to join classes on two evenings during the week as well as three Saturdays per month. For the bootcamps, you will have to be available full-time. However, many alumni get approval from their employer to take time off and return.'
        },
        {
          question: 'Which Career Services do you offer?',
          answer:
            "During our 5+ years helping students achieve success, we've developed a thorough understanding of what it takes to land a job in the digital and tech ecosystem. Behind our success is a strong network of hiring partners, a dedicated outcomes team, and a deep knowledge of the tech job market and how to navigate it. We will help you get job-ready with one-on-one mentorship, workshops on CV building, interview trainings and more. Each of our courses is followed by a Hiring Week, in which you’ll be meeting top-notch companies in speed recruiting sessions. Our outcomes team will support you even after your graduation to help you land your dream job."
        },
        {
          question:
            'Do you provide student visas, or any sort of visa support?',
          answer:
            'Unfortunately, we do not provide visa support and cannot provide paperwork to obtain student visas. International students have to make their visa arrangement and plan visa obtention ahead.'
        },
        {
          question: 'Do you provide accommodation during the course?',
          answer:
            'We do not provide accomodation or accomodation help during the bootcamps. Each candidate is advised to arrange their accommodation on their own according to their needs.'
        },
        {
          question:
            'I won a partial scholarship, can I combine this with another discount or scholarship?',
          answer:
            'All the scholarships awarded are not cumulative with any other discount or scholarship for an Ironhack course.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Terms and Conditions',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/electronic-arts-scholarship/terms-and-conditions-en.pdf'
    }
  }
};
