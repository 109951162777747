import styled from 'styled-components';

import { ComponentProps } from '../interfaces/StatsList';
import config from '../config';

const size = (length: number) => 100 / length;

export default styled('section')<ComponentProps>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6.4rem;

  & > section {
    width: ${(props) => size(props.length)}%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 3.2rem;
    & > section {
      margin-top: 1.6rem;
      width: ${(props) => (props.length > 3 ? 50 : size(props.length))}%;
    }
  }

  ${(props) => props.extend};
`;
