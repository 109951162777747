module.exports = {
  context: {
    url: '/pt/impacto-social/bolsas-zomato',
    lang: 'pt',
    forcedLocale: 'en',
    breadcrumb: 'Bolsas Zomato',
    disableCovidBanner: true,
    seo: {
      title:
        'Participa de um bootcamp de tecnologia com Zomato e Ironhack e aprenda as habilidades mais requisitadas pelo mercado em Desenvolvimento Web, UX/UI Design, Cibersecurity e Data Analytics',
      description: '',
    },
    hreflangs: {},
    header: {
      title:
        'A Ironhack e a Zomato unem-se na formação de novos talentos da tecnologia em Portugal',
      backgroundImage: 'zomato/header.png',
      paragraph:
        '130,000 euros serão investidos em bolsas integrais e parciais de estudo (Programação, UX/UI Design, Data Analytics e Cibersecurança) na Ironhack Lisboa em 2021',
      alternativeLogo: 'zomato/logo.png',
      alternativeLogoAltText: 'Zomato logo',
    },
    navbar: {
      text: [
        'As inscrições para esta bolsa estão encerradas, entretanto sempre estamos a procura de novas parcerias para continuarmos a democratizar o acesso aos nossos cursos. Subscreve a nossa newsletter e mantém-te a par das próximas bolsas.',
      ],
      linkText: 'Subscrever',
      linkHref: 'https://www2.ironhack.com/scholarships',
    },
    video: {
      href: 'https://www.youtube.com/embed/iWUXsJB23K0',
    },
    valueProposition: {
      content: [
        {
          title:
            'Por que é que a Ironhack e a Zomato querem contribuir para a educação?',
          text: [
            'Há 7 anos que a Zomato em Portugal representa inovação e criatividade, criando memórias ao ligar os restaurantes a utilizadores (foodies). Num contexto tão diferente e exigente como o que vivemos hoje, é ainda mais importante continuar a inovar e permitir que mais pessoas o façam. Arriscar sempre fez parte de nós, e agora queremos que também tu possas agarrar oportunidades diferentes e, com isso, te sintas realizado.',
            'Aliamo-nos uma vez mais à Ironhack para ajudar todos os que devido à pandemia queiram mudar de rumo ou melhorar as suas competências, da mesma forma que nós na Zomato nos superamos diariamente. O nosso objectivo é dinamizar a educação e apostar no talento em Portugal. E nós escolhemos apostar em ti.',
            'Na Ironhack acreditamos que a educação em tecnologia deve ser acessível para qualquer pessoa independentemente da sua experiência previa. Durante o ultimo ano, vimos mais de 200 pessoas que foram atingidas directamente pela pandemia mudarem o seu rumo atravez da Ironhack e o nosso objetivo nesta parceria é ajudar a reduzir a barreira financeira para que quem sonhe com uma carreira no mundo da tecnologia possa cumpri-lo connosco.',
          ],
        },
        {
          title: 'Como funciona?',
          text: [
            'Preenche o formulário com os teus dados e o bootcamp para o qual queres concorrer.',
            'Se fores pré-seleccionado (primeira fase), irás receber um email com um convite para um teste de aptitude técnica (segunda fase), e após seres aprovado, um convite para uma entrevista pessoal online.',
            'Uma vez entrevistados todos os candidatos que passaram a segunda fase, iremos anunciar os vencedores até dia 10 de maio.',
          ],
          isList: true,
        },
        {
          title: 'Até quando posso candidatar-me?',
          text: [
            'Todos os candidatos deverão apresentar formulários de candidatura antes do dia 05 de Maio de 2021',
          ],
        },
        {
          title: 'Requisitos para a candidatura:',
          text: [
            'Ser maior de 18 anos.',
            'Dominio minimo de lingua Inglesa: intermédio.',
          ],
          isList: true,
        },
      ],
    },
    courses: {
      title: 'Os Nossos Cursos',
      courses: [
        {
          name: 'Web Development',
          description:
            'Torna-te um Full-Stack Web Developer. Aprende as bases de HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB e de experimentação com metodologias ágeis. Oferecemos esta opção em duas modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // FULL-TIME',
              name: 'Programa de Bootcamp',
              schedule: 'Segunda a Sexta, das 9:00 às 18:00',
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Programa a Part-Time',
              schedule:
                'Terça & Quinta, das 18:30 às 21:30, e Sábado das 10:00 às 17:00',
            },
          ],
        },
        {
          name: 'UX/UI Design',
          description:
            'Torna- te um UX/UI Designer. Aprenda design centrado no usuário aplicando o processo de Design Thinking e valide suas ideias através de pesquisas, protótipos, testes de utilizador e avaliações heurística. Aprenda UI e IxD para prototipar suas soluções usando técnicas de design visual e interactivo. Oferecemos este curso em duas modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // FULL-TIME',
              name: 'Programa de Bootcamp',
              schedule: 'Segunda a Sexta, das 9:00 às 18:00',
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Programa a Part-Time',
              schedule:
                'Terça & Quinta, das 18:30 às 21:30, e Sábado das 10:00 às 17:00',
            },
          ],
        },
        {
          name: 'Data Analytics',
          description:
            'Torna-te um Data Analyst. Aprende a limpar, explorar e extrair conhecimentos valiosos a partir de uma grande variedade de bases de dados e linguagens como Python, SQL e Tableau. Desenvolve conhecimentos analíticos e aplique machine learning ao conjuntos de dados do mundo real! Oferecemos este curso em duas modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // FULL-TIME',
              name: 'Programa de Bootcamp',
              schedule: 'Segunda a Sexta, das 9:00 às 18:00',
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Programa a Part-Time',
              schedule:
                'Terça & Quinta, das 18:30 às 21:30, e Sábado das 10:00 às 17:00',
            },
          ],
        },
        {
          name: 'Cybersecurity',
          description:
            'Torna-te profissional de cibersegurança. Aprende sobre sistemas operacionais, administração de redes, análise de malwares, White Hat Hacking e análise de risco. Aprofunde-se através de projetos a proteger ataques cibernéticos em tempo real, realizando uma análise de segurança e avaliação de arquitetura, e ainda, executando análises estáticas e dinâmicas de diferentes fontes de dados.',
          courseFormats: [
            {
              duration: '12 SEMANAS // FULL-TIME',
              name: 'Programa de Bootcamp',
              schedule: 'Segunda a Sexta, das 9:00 às 18:00',
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Programa a Part-Time',
              schedule:
                'Terça & Quinta, das 18:30 às 21:30, e Sábado das 10:00 às 17:00',
            },
          ],
        },
      ],
    },
    testimonials: {
      title: 'Vencedores de ediçoēs passadas',
      items: [
        {
          image: 'zomato/testimonials/guilherme-carmona.jpg',
          name: 'Guilherme Carmona',
          position: 'Web Development Full-Time',
          description:
            'Uma das primeiras coisas que ouves quando te inscreves na Ironhack é:" Quanto mais esforço colocares, maior será a recompensa ". Recebemos as ferramentas, os recursos e um processo a seguir. E era exatamente isto que eu precisava para ir de alguém que mal tinha visto código na vida, para estar pronto para os próximos desafios como programador full-stack. Tudo isso cercado por uma equipa incrível e pessoas com os mesmos objetivos, uma grande comunidade da qual tenho orgulho de fazer parte.',
        },
        {
          image: 'zomato/testimonials/cristina-barreto.jpg',
          name: 'Cristiana Barreto',
          position: 'UX/UI Design Full-Time',
          description:
            'Um dos aspectos mais valiosos deste bootcamp para mim é o facto dele proporcionar uma transição rápida para o mundo do UX/UI. Enquanto estudantes, estamos constantemente imersos em práticas padrão da indústria. Ao contrário de alguns programas do Ensino Superior, o curriculo é cuidadosamente estruturado para proporcionar uma educação contemporânea e orientada para o mercado.',
        },
        {
          image: 'zomato/testimonials/filipa-lopes.jpg',
          name: 'Filipa Lopes',
          position: 'Data Analytics Full-Time',
          description:
            'Imagina uma licenciatura de informática. Tira-lhe decorar fórmulas matemáticas de 5 linhas e conceitos teóricos desnecessários. Reduz às ferramentas essenciais. Tira-lhe a procrastinação, os professores inacessíveis. Acrescenta-lhe 8-10h de código por dia, projectos realistas, exemplos aplicáveis e mentores dedicados à tua disposição. Cereja no topo: orientação profissional até garantires a posição.',
        },
        {
          image: 'zomato/testimonials/goncalo-costa.jpg',
          name: 'Gonçalo Costa Santos',
          position: 'Web-Development Part-Time',
          description:
            'Candidatei-me à bolsa sem nenhuma certeza do que iria acontecer e assim que soube que ganhei, fiquei em êxtase! Ainda mais quando tive as primeiras aulas e soube que tinha feito a escolha certa de me aplicar. Super intuitivas e desafiantes. A melhor combinação para virar um developer !',
        },
      ],
    },
    careerSupport: {
      title: 'Apoio Profissional',
      text:
        'Ironhack tem como foco ajudar aos nossos estudantes a conseguir um novo emprego assim que concluírem o curso. Com o teu trabalho e nosso apoio temos certeza que conseguirás alcançar teus objetivos profissionais!',
      logos: [
        {
          name: 'Google',
          image: 'google.png',
        },
        {
          name: 'Onfido',
          image: 'onfido.png',
        },
        {
          name: 'Twitter',
          image: 'twitter.png',
        },
        {
          name: 'Cabify',
          image: 'cabify.png',
        },
        {
          name: 'Landing Jobs',
          image: 'landing-jobs.png',
        },
        {
          name: 'Visa',
          image: 'visa.png',
        },
        {
          name: 'Deloite',
          image: 'deloite.png',
        },
        {
          name: 'Everis',
          image: 'everis.jpg',
        },
      ],
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'O que acontece se ganho uma das bolsas?',
          answer:
            'Caso o candidato receba uma das bolsas, deve comunicar que a aceita num prazo de 5 dias. Após esta comunicação, irá receber o Student Agreement que deverá assinar e proceder ao pagamento da matrícula (no caso da bolsa parcial).',
        },
        {
          question: 'A que cursos posso candidatar-me?',
          answer:
            'Esta bolsa pemite-te participar nos cursos em formato full-time ou part-time de Web Development , UX/UI Design, Cybersegurança ou Data Analytics na Ironhack Lisboa em 2021.',
        },
        {
          question: 'Como funciona o processo de seleção?',
          answer:
            'Os participantes, para serem elegíveis, devem preencher todos os dados solicitados no formulário de inscrição. Após a submissão, a Ironhack avaliará a tua candidatura (primeira etapa do processo de seleção). Se o candidato for avaliado positivamente, receberá no e-mail instruções para aceder à prova de aptitude técnica de acesso, e após seres aprovado no teste, receberás um convite para uma entrevista pessoal online. Os aprovados nas duas etapas serão vencedores de uma das bolsas parciais ou integrais.',
        },
        {
          question: 'Qual é a data limite para candidatar-me?',
          answer:
            'Todos os candidatos deverão inscrever-se no período entre 19 de abril de 2021 a 05 de Maio de 2021.',
        },
        {
          question: 'O que é que a Ironhack e a Zomato estão à procura?',
          answer:
            'Os vencedores da bolsa deverão demonstrar curiosidade, proactividade, paixão e sobretudo, motivação. Estamos à procura de candidatos que saibam com clareza qual é o curso para o qual querem se candidatar e o porquê.',
        },
        {
          question:
            'Caso eu ganhe uma bolsa parcial, é possível financiar o restante do valor em falta?',
          answer: [
            'Sim! Temos duas formas diferentes de financiamento:',
            '- Pagamento direto: o aluno pode pagar diretamente em até seis mensalidades, sem nenhuma alteração no valor da propina.',
            '- Financiamento Quotanda: financiamento obtido através de uma plataforma estudantil, permitindo ao aluno financiar em 12 ou 18 meses. É cobrada uma taxa de 350€ (incluída no financiamento) porém não são cobrados juros.',
          ],
        },
        {
          question: 'É possível candidatar-me para mais de um curso?',
          answer:
            'Não, esta é uma decisão que deve ser tomada antes da inscrição.',
        },
        {
          question:
            'Caso ganhe, é possível mudar a minha bolsa de curso ou modalidade?',
          answer:
            'Não, esta é uma decisão que deve ser tomada antes da inscrição.',
        },
      ],
    },
    campus: {
      title: 'O nosso campus:',
      campus: {
        name: 'Ironhack Lisbon',
        image: 'zomato/lisbon-campus.jpg',
        address1: 'Heden Waterfront',
        address2: 'Doca Jardim do Tabaco',
        address3: 'Terminal de Cruzeiros de Lisboa',
        address4: 'Edifício NW, 1º Piso, 1100-651 Lisboa',
      },
    },
    moreInfo: {
      text: 'Ainda tens dúvidas?',
      linkText: 'Contacta-nos',
      linkHref: 'mailto:zomato.scholarship@ironhack.com',
    },
    termsAndConditions: {
      text: 'Termos e Condições',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/zomato-terms-and-conditions.pdf',
    },
  },
};
