import { css } from 'styled-components';
import config from '../../components/config';

const LayoutCarousel = css`
  & > p {
    opacity: 0.6;
  }

  & > h4 {
    font-size: 4rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    background-color: rgba(45, 53, 76, 0.05);
    padding-top: 4.2rem;
    padding-bottom: 4.2rem;
    & > h4 {
      font-size: 2.8rem;
    }
  }
`;

const LayoutCards = css`
  margin-top: 8rem;
  & > p {
    padding-top: 1.6rem;
    opacity: 0.6;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 5.6rem;
  }
`;

export { LayoutCarousel, LayoutCards };
