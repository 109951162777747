const bnextEs = require('./bnext-es');
const deliverooEs = require('./deliveroo-es');
const electronicArtsBr = require('./electronic-arts-br');
const electronicArtsDe = require('./electronic-arts-de');
const electronicArtsEn = require('./electronic-arts-en');
const electronicArtsEs = require('./electronic-arts-es');
const electronicArtsFr = require('./electronic-arts-fr');
const electronicArtsNl = require('./electronic-arts-nl');
const facebookEn = require('./facebook-en');
const isaAmsterdam = require('./isa-amsterdam');
const isaLisbon = require('./isa-lisbon');
const isaMiami = require('./isa-miami');
const landingJobs = require('./landing-jobs');
const kleiderkreiselDe = require('./kleiderkreisel-de');
const rappiBr = require('./rappi-br');
const rappiEs = require('./rappi-es');
const selinaEn = require('./selina-en');
const zomato = require('./zomato');
const bunqScholarship = require('./bunq-scholarship');

module.exports = {
  kleiderkreiselDe,
  selinaEn,
  bnextEs,
  deliverooEs,
  electronicArtsBr,
  electronicArtsDe,
  electronicArtsEn,
  electronicArtsEs,
  electronicArtsFr,
  electronicArtsNl,
  facebookEn,
  isaAmsterdam,
  isaLisbon,
  isaMiami,
  landingJobs,
  rappiBr,
  rappiEs,
  zomato,
  bunqScholarship
};
