module.exports = {
  context: {
    url: '/de/social-impact/kleiderkreisel',
    lang: 'de',
    breadcrumb: 'Kleiderkreisel',
    seo: {
      title:
        'Lerne neue Technologien mit dem Kleiderkreisel x Ironhack-Stipendium',
      description:
        'Europas Tech-Industrie wird immer noch von Männern dominiert. Da wir glauben, dass die Zukunft der Tech-Industrie weiblich ist, haben wir uns zum dritten Mal mit Kleiderkreisel zusammengetan, um Frauen mit Stipendien in Höhe von insgesamt 86.000€ zu unterstützen (nur für den Berlin Campus).'
    },
    hreflangs: {
      en: '/en/social-impact/vinted-scholarship',
      de: '/de/social-impact/kleiderkreisel',
      es: '/es/impacto-social/beca-vinted',
      fr: '/fr/impact-social/bourse-vinted',
      nl: '/nl/sociale-impact/vinted-scholarship'
    },
    header: {
      title: 'Die Zukunft ist',
      titleHighlighted: 'weiblich',
      backgroundImage: 'vinted/header.jpg',
      alternativeLogo: 'kleiderkreisel/kleiderkreisel.png',
      alternativeLogoAltText: 'Kleiderkreisel logo',
      paragraph:
        'Die europäische Tech-Industrie hat ein Problem - sie wird immer noch mehrheitlich von Männern dominiert. Da wir glauben, dass die Zukunft der Technologie auch weiblich ist, möchten wir Frauen mit unterschiedlichem Hintergrund dabei unterstützen, erfolgreich den Übergang in die Tech-Industrie zu wagen.'
    },
    navbar: {
      text: [
        'Kleiderkreisel & Ironhack schließen sich zum dritten Mal zusammen, um Frauen im technischen Bereich mit 86.000 € in Form von Stipendien zu unterstützen.'
      ],
      linkHref: 'https://www2.ironhack.com/l/688303/2020-03-12/fcj91',
      linkText: 'Jetzt bewerben'
    },
    video: {
      href: 'https://www.youtube.com/embed/4zC3RXj8pa4'
    },
    valueProposition: {
      content: [
        {
          title: '86.000 € in Form von Stipendien',
          text: [
            'Ausgewählte Teilnehmerinnen in Berlin erhalten von 15% bis 50% Stipendium Möglichkeiten für ein Ironhack-Bootcamp (die Höhe hängt vom Campus ab).'
          ]
        },
        {
          title: 'Welche Kurse sind enthalten?',
          text: [
            'Mit diesem Stipendienprogramm hast du den Chance an den Kursen Web Development, UX/UI Design oder Data Analytics in der Berlin Campus teilzunehmen'
          ]
        },
        {
          title: 'Bin ich für ein Kleiderkreisel-Stipendium berechtigt?',
          text: [
            'Wenn du eine Frau und 18 Jahre oder älter bist, kannst du dich gerne bewerben. Du brauchst keine Vorkenntnisse, nur viel Motivation und Begeisterung, um neue Fähigkeiten zu erlernen!'
          ]
        },
        {
          title: 'Wie werde ich Studentin bei Ironhack?',
          text: [
            'Du kannst dich bis zum 5. April 2020 für ein Kleiderkreisel-Stipendium bewerben. Das Bewerbungsverfahren besteht aus einem technischen Test und einem persönlichen Interview, um deine Motivation und dein Potenzial besser einschätzen zu können.'
          ]
        },
        {
          title: 'Ich habe ein Stipendium gewonnen - was nun?',
          text: [
            'Mach dich bereit für eine intensive und lebensverändernde Erfahrung! Melde dich für einen Kurs deiner Wahl an und lerne die gefragtesten technischen Fähigkeiten.'
          ]
        }
      ]
    },
    extraText: {
      content: [
        {
          title: 'Dein Ironhack Campus',
          text: [
            '· <strong>Berlin, Deutschland</strong>: Data Analytics, UX/UI Design, Web Development Stipendien in Höhe von 15% bis 50% Discount.'
          ]
        }
      ]
    },
    courses: {
      title: 'Kursrichtungen',
      courses: [
        {
          name: 'Web Development',
          description:
            'Werde Full-Stack Web Developer. Lerne die Grundlagen von HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB und testen mit agilen Methoden. Wir bieten diesen Kurs in zwei unterschiedlichen Modalitäten an:',
          courseFormats: [
            {
              duration: '9 WOCHEN // Vollzeit Bootcamp',
              name: 'Vollzeitprogramm',
              schedule:
                '9 Wochen Intensivprogramm von Montag bis Freitag von 9:00 bis 18:00 Uhr.'
            },
            {
              duration: '24 WOCHEN // Teilzeit Programm',
              name: 'Teilzeitprogramm',
              schedule:
                'Zwei Wochentage von 18:30 bis 21:30 Uhr (je nach Campus entweder Montag und Mittwoch oder Dienstag und Donnerstag) und Samstags von 10 bis 17 Uhr.'
            }
          ]
        },
        {
          name: 'UX/UI Design',
          description:
            'Werde UX/UI Designerin! Lerne benutzerzentriertes Design, indem du den Design Thinking-Prozess anwendest und deine Ideen durch Benutzerforschung, Prototyping, Benutzertests und heuristische Evaluation validierst. Lerne UI und IxD, um deine Lösungen mithilfe visueller und interaktiver Designtechniken zu prototypisieren. Wir bieten diesen Kurs in zwei unterschiedlichen Modalitäten an:',
          courseFormats: [
            {
              duration: '9 WOCHEN // Vollzeit Bootcamp',
              name: 'Vollzeitprogramm',
              schedule:
                '9 Wochen Intensivprogramm von Montag bis Freitag von 9:00 bis 18:00 Uhr.'
            },
            {
              duration: '24 WOCHEN // Teilzeit Programm',
              name: 'Teilzeitprogramm',
              schedule:
                'Zwei Wochentage von 18:30 bis 21:30 Uhr (je nach Campus entweder Montag und Mittwoch oder Dienstag und Donnerstag) und Samstags von 10 bis 17 Uhr.'
            }
          ]
        },
        {
          name: 'Data Analytics',
          description:
            'Werde ein vollwertiger Datenanalyst. Erfahre, wie du eine Vielzahl von Datensätzen bereinigen und untersuchen kannst. Tauche mit Pandas in komplexe Datensätze ein, um deine Daten in hochwertige Visualisierungen für das story-telling zu verwandeln. Schließe all das mit einem Modul zum machinellen Lernen ab und wende deine Ergebnisse auf Datensätze in der realen Welt an. Wir bieten diesen Kurs in zwei unterschiedlichen Modalitäten an:',
          courseFormats: [
            {
              duration: '9 WOCHEN // Vollzeit Bootcamp',
              name: 'Vollzeitprogramm',
              schedule:
                '9 Wochen Intensivprogramm von Montag bis Freitag von 9:00 bis 18:00 Uhr.'
            },
            {
              duration: '24 WOCHEN // Teilzeit Programm',
              name: 'Teilzeitprogramm',
              schedule:
                'Zwei Wochentage von 18:30 bis 21:30 Uhr (je nach Campus entweder Montag und Mittwoch oder Dienstag und Donnerstag) und Samstags von 10 bis 17 Uhr.'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Bewerbungsprozess',
      steps: [
        {
          title: 'Schritt 1: Füll das Antragsformular aus',
          description:
            'Die Bewerbung für das Kleiderkreisel-Stipendium ist bis zum 5. April möglich'
        },
        {
          title: 'Schritt 2: Mach den technischen Test',
          description:
            'Du bekommst eine E-Mail mit Vorbereitungsmaterialien für einen technischen Online-Test, für den Kurs deiner Wahl. Bereite das Material vor und mache den Test, wenn du bereit bist. Keine Panik - der Test ist nicht schwer, wenn du dich gut vorbereitest. Technische Tests sind bis zum 5. April 2020 geöffnet. *'
        },
        {
          title: 'Schritt 3: Persönliches Interview',
          description:
            'Warst du erfolgreich, wirst du zu einem 5 bis 10-minütigen, persönlichen Interview mit einem lokalen Teammitglied eingeladen. Das Interview kann per Videoanruf oder auch vor Ort durchgeführt werden, wenn du in der Nähe des ausgewählten Campus wohnst. Persönliche Interviews werden am 14. April 2020 abgeschlossen.'
        },
        {
          title: 'Schritt 4: Bekanntgabe',
          description:
            'Die Gewinnerinnen des Kleiderkreisel-Stipendiums werden am 17. April 2020 benachrichtigt!'
        },
        {
          title: 'Ich habe ein Kleiderkreisel-Stipendium gewonnen - was nun?',
          description: [
            'Wenn du ein Teilstipendium gewonnen hast, musst du dich entscheiden, ob du an dem Kurs teilnehmen möchtest, und deine Teilnahme durch Überweisung der Anzahlung bestätigen. Diese wird natürlich von den verbleibenden Studiengebühren abgezogen.',
            'Sobald du deine Teilnahme an einem Bootcamp bestätigt hast, bereite dich auf eine aufregende, intensive und umfassende Lernerfahrung vor!'
          ]
        }
      ]
    },
    testimonials: {
      title: 'Absolventinnen von 2018 und 2019',
      items: [
        {
          image: 'kleiderkreisel/lena-lau.jpg',
          name: 'Lena Lau',
          position: 'Web Development Part-Time',
          description:
            'Als Linguistin und Anthropologin hatte ich schon länger den Wunsch meine Berufsperspektiven zu erweitern und zu ändern. Das Stipendium war eine willkommene Ermutigung, in einen Bereich zu wechseln, den ich mir vorher nicht zugetraut hätte. Das hat mich unheimlich weitergebracht und ich sehe mich jetzt vor ganz neuen Möglichkeiten.'
        },
        {
          image: 'kleiderkreisel/nelly-charlott-schneider.jpg',
          name: 'Nelly-Charlott Schneider',
          position: 'Web Development Full-Time',
          description:
            'Da ich mit meinem Studium unzufrieden und schon länger am Programmieren interessiert war, hatte ich mich für das Kleiderkreisel Scholarship beworben. Das Teilstipendium was darauf folgte hat mir viele Türen geöffnet, denn kurz nach dem Bootcamp habe ich eine Stelle als Junior Fullstack Developer bei einem Startup gefunden. Es hätte nicht besser laufen können!'
        },
        {
          image: 'kleiderkreisel/julia-beck.jpg',
          name: 'Julia Beck',
          position: 'UX/UI Part-Time',
          description:
            'Gerade ist eine Zeit in der wir die Zukunft der Technologie und wie Menschen mit ihr interagieren, formen können. Aus diesem Grund habe ich mich entschieden das UX/UI Design Boot Camp zu machen, um ein Teil dieser Zukunft zu sein. Das Kleiderkreisel Scholarship war hierbei der Auslöser der den Stein ins Rollen gebracht hatte, da ich Ironhack bis zu diesem Zeitpunkt nicht kannte und ich nichts unversucht lassen wollte meine Karriere auf das nächste Level zu bringen.'
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question:
            'Was sind die Auswahlkriterien für das Kleiderkreisel-Stipendium? Benötige ich Vorkenntnisse, um die Kurse erfolgreich meistern zu können?',
          answer: [
            'Du benötigst keinerlei Vorkenntnisse, um dich für das Kleiderkreisel-Stipendium zu bewerben. Wir werden jedoch deine Lernfähigkeit sowie deie logischen und kreativen Fähigkeiten bewerten, um zu beurteilen, ob du in dieser Art von Lernumgebung erfolgreich sein kannst. Auch werden wir deine Motivation und Beweggründe für die Kursteilnahme bewerten, um deinen Erfolg in einer intensiven Lernumgebung sicherstellen zu können.',
            'Du musst mindestens 18 Jahre alt sein, um dich bewerben zu können. *',
            '* Ziel dieser Partnerschaft ist es, die Kursteilnahme von Frauen in der Tech-Industrie, durch die Kleiderkreisel-Community zu fördern. Der Stipendienantrag steht jedoch auch transgendern and non-binary Menschen offen.'
          ]
        },
        {
          question:
            'An welchem Campus kann ich am Kleiderkreisel-Stipendienprogramm teilnehmen?',
          answer: [
            'Das Kleiderkreisel-Stipendium ist nur für den Ironhack Berlin Campus gültig.',
            'Bitte beachte, dass es sich bei allen Kursen um Präsenzkurse handelt. Das bedeutet, dass du dich für die Gesamtdauer des Bootcamps in dem Land oder der Stadt deiner Wahl, also Ironhack Berlin,befinden musst. Wir bieten keine Online- oder Remote-Kurse an.'
          ]
        },
        {
          question:
            'Wie funktioniert das Bewerbungsverfahren für das Kleiderkreisel-Stipendium?',
          answer: [
            '1: Füll das Antragsformular aus',
            '2: Mach den technischen Test online',
            '3: Persönliches Interview (online oder vor Ort) '
          ]
        },
        {
          question: 'Was kann ich von den Kursen erwarten?',
          answer:
            'Du kannst erwarten, dass du wirklich hart arbeiten wirst! Jeder Ironhack-Kurs verfügt über einen soliden Lehrplan, der interaktive Vorlesungen, praktische Übungen und herausfordernde Projekte umfasst. Aber keine Sorge - Du kannst auf dem Weg mit Hilfe rechnen. Du wirst in einer Klasse mit Mitstudierenden sein, die ähnliche Ziele wie du haben. Erfahrene Lehrkräfte stehen dir jederzeit zur Seite. Unser lokales Team wird dich sowohl während des Kurses unterstützen, als auch danach, bei deiner Jobsuche.'
        },
        {
          question:
            'Muss ich meinen Job kündigen, um an dem Kurs teilzunehmen?',
          answer: [
            'Kurze Antwort: nein! Lange Antwort: Das hängt von deiner gewählten Kursmodalität ab. Die Teilzeitkurse richten sich vor allem an berufstätige Teilnehmerinnen. In dieser Modalität wirst du an zwei Abenden während der Woche sowie drei Samstagen pro Monat den Teilzeitkurs besuchen.',
            'Für die 9-wöchigen Bootcamps musst du Vollzeit verfügbar sein. Viele Alumni erhalten von ihrem Arbeitgeber die Genehmigung, sich frei stellen zu lassen und nach dem Bootcamp in ihren Job zurückzukehren.'
          ]
        },
        {
          question:
            'Für welche Kurse kann ich mich mit diesem Stipendien-Programm bewerben?',
          answer:
            'Das Kleiderkreisel-Stipendium gilt für alle Kurse (Web Development, UX/UI Design und Data Analytics), die vom Campus deiner Wahl angeboten werden. Bitte beachte, dass aufgrund der hohen Nachfrage der Kurs deiner Wahl möglicherweise zu deinem gewünschten Startzeitpunkt ausgebucht sein kann.'
        },
        {
          question: 'In welcher Sprache sind die Bootcamps?',
          answer:
            'Auch das hängt von deinem Wahl Campus ab. Ironhack Amsterdam und Barcelona bieten derzeit nur Kurse auf Englisch an. Ironhack Paris bietet Teilzeitprogramme auf Französisch und Vollzeitprogramme auf Englisch an. Ironhack Madrid bietet nur Kurse auf Spanisch an. Hier bei Ironhack Berlin bieten wir alle Kurse nur auf Englisch an.'
        },
        {
          question: 'Welche Karrieredienstleistungen bietet ihr an?',
          answer:
            'Während unserer mehr als fünf-jährigen Erfahrung, haben wir unseren Studierenden zum Erfolg verholfen und dabei ein gründliches Verständnis dafür entwickelt, was es bedarf, um einen Job im digitalen und technischen Ökosystem zu finden. Hinter unserem Erfolg steht ein starkes Netzwerk von Einstellungspartnern, ein engagiertes Karriereteam und ein fundiertes Wissen über den Arbeitsmarkt. Wir unterstützen dich bei der Vorbereitung auf die Jobsuche mit Einzel-Mentoring, Workshops zum Erstellen von Lebensläufen, Interview-Schulungen und vielem mehr. Auf jeden unserer Kurse folgt eine Karriere-Woche, in der du erstklassige Unternehmen in Schnellrekrutierungsmeetings treffen wirst. Unser Karriereteam unterstützt dich auch nach deinem Bootcamp Abschluss dabei, deinen Traumjob zu finden.'
        },
        {
          question: 'Bietet ihr für die Dauer des Kurses Unterkünfte an?',
          answer:
            'Wir bieten keine Unterkünfte, während der Bootcamps an. Jeder Kandidatin wird empfohlen ihre Unterkunft selbständig und nach ihren Bedürfnissen zu arrangieren.'
        },
        {
          question:
            'Ich habe ein Teilstipendium von Kleiderkreisel gewonnen. Kann ich dies mit einem anderen Rabatt oder Stipendium kombinieren?',
          answer:
            'Nein, es ist nicht möglich das Kleiderkreisel-Stipendium mit anderen Rabatten oder Stipendien zu kombinieren.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Geschäftsbedingungen',
      href: 'https://ih-web.s3-eu-west-1.amazonaws.com/kleiderkreisel-2020.pdf'
    }
  }
};
