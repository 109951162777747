module.exports = {
  context: {
    url: '/es/impacto-social/becas-deliveroo',
    lang: 'es',
    breadcrumb: 'Becas Deliveroo',
    customColor: 'rgb(0, 204, 188)',
    partnershipType: 'partnership-1',
    seo: {
      title: 'Fórmate en tecnología con las becas de Ironhack y Deliveroo',
      description:
        'Expande tus conocimientos digitales y aprende Desarrollo web, diseño UX/UI o Análisis de datos con nuestros bootcamps'
    },
    hreflangs: {},
    header: {
      title: 'Cómete el mundo',
      backgroundImage: 'deliveroo/header.jpg',
      alternativeLogo: 'deliveroo/logo.svg',
      alternativeLogoAltText: 'Logo Deliveroo',
      paragraph:
        'Deliveroo y Ironhack se unen para desarrollar talento tecnológico en España. Se destinarán 300.000€ a becas para formación en Desarrollo Web, Diseño UX/UI o Data Analytics con el objetivo de formar perfiles técnicos que puedan incorporarse a empresas referentes del sector, como Deliveroo.'
    },
    navbar: {
      text: [
        'Los aspirantes a las becas Deliveroo optan a 8 becas ‘Full Scholarship’ valoradas en 7.500€ cada una y a más de 200 becas ‘Partial Scholarship’ de 1.000€ cada una, hasta completar los 300.000€ que se repartirán en los campus de Madrid y Barcelona'
      ],
      linkText: 'Solicita tu beca',
      linkHref: 'https://ironhack.typeform.com/to/gfbWIR?source=landing'
    },
    video: {
      href: 'https://www.youtube.com/embed/AhwF7ZFJMo4'
    },
    valueProposition: {
      content: [
        {
          title: 'Deliveroo busca formar talento digital',
          text: [
            '¡Alimenta tu pasión!',
            '¿Cuántas veces has pensado cambiar de sector, dedicarte a lo que te apasiona y te has quedado con las ganas?',
            'Queremos que esta vez te atrevas a dar el salto. Te lo ponemos en bandeja con estas becas de formación tecnológica.',
            'Vamos a alimentar tu pasión. Vamos a proponerte que llegues más allá de tus límites y descubras tu talento digital. No hacen falta conocimientos previos, sólo las ganas de comerte el mundo.',
            'Puedes solicitar estas becas de formación y, una vez finalices el bootcamp, Deliveroo puede darte la oportunidad de formar parte de su equipo técnico para seguir transformando el futuro de la comida a domicilio.'
          ]
        },
        {
          title: '¿Cómo funciona?',
          text: [
            'Rellena el formulario para poder solicitar una de las becas ofrecidas.',
            'Recibirás el material para poder prepararte la prueba técnica',
            'En caso de ser seleccionado, participaras en una entrevista personal'
          ],
          isList: true
        },
        {
          title: 'Fechas clave',
          text: [
            'Todas las solicitudes deben realizarse antes del 15 de Octubre',
            'El 16 de Octubre se anunciarán los que pasen a la siguiente ronda',
            'El periodo de entrevistas será entre el 23 y 31 de Octubre',
            'La resolución de los ganadores se hará el 5 de Noviembre'
          ],
          isList: true
        }
      ]
    },
    courses: {
      title: 'Nuestros cursos',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Conviértete en Programador Web Full-Stack. Aprende diseño front-end y arquitectura back-end, además de tecnologías punteras como React, Express, MongoDB y metodologías ágiles. Ofrecemos estas dos modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Conviértete en un diseñador UX/UI. Aprende User Centered Design aplicando técnicas de Design Thinking. Estudia cómo validar tus ideas a través de la investigación de usuarios, prototipado y evaluación heurística. Además, aprende diseño de interfaces con Sketch y diseño de interacciones para prototipar interfaces de aplicaciones web y móviles. Ofrecemos dos modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Conviértete en un maestro de análisis de datos. Aprende a limpiar y a explorar distintos de conjuntos de datos. Sumérgete a fondo usando Pandas para convertir tus datos en visualizaciones increíbles capaces de contar historias. Termina con Machine Learning y aplicando tu aprendizaje con conjuntos de datos reales! Ofrecemos una modalidad:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        }
      ]
    },
    testimonials: {
      title: 'Otros ganadores de becas',
      items: [
        {
          image: 'deliveroo/sonia-ruiz.jpg',
          name: 'Sonia Ruiz',
          position: 'Web Development Full-Time',
          description:
            'Ironhack me ayudó a crecer tanto profesional como personalmente, dándome la posibilidad de especializarme en tecnologías actuales y a descubrir qué es lo que me apasiona. Gracias a ello, pude empezar a trabajar como desarrolladora Frontend.'
        },
        {
          image: 'deliveroo/soledad-abad.jpg',
          name: 'Soledad Abad',
          position: 'UX/UI Design Full-Time',
          description:
            'La beca de Ironhack me ayudó a estar un poco más cerca de mi objetivo: dar un nuevo rumbo a mi formación académica introduciéndome en el sector digital'
        }
      ]
    },
    companyTestimonial: {
      image: 'deliveroo/gustavo-brusilovsky.jpg',
      name: 'Gustavo García Brusilovsky',
      position: 'Director General de Deliveroo en España',
      description:
        'Contar con conocimientos tecnológicos será muy pronto una necesidad imprescindible para el desarrollo de casi cualquier carrera profesional. Como compañía tecnológica, somos conscientes de que hay una gran necesidad formativa en este sentido. Por ello, hemos querido poner en marcha este programa de becas que nos permitirá identificar y desarrollar talento digital en colaboración con una compañía referente como es Ironhack. Nuestro propósito como compañía es transformar la forma en la que las personas comen y para alcanzarlo la tecnología juega un papel fundamental.'
    },
    careerSupport: {
      title: 'Dónde trabajan nuestros estudiantes',
      text:
        'En nuestro bootcamp aprendes las habilidades más demandadas. Es por eso que la mayoría de nuestros graduados han sido contratados en grandes compañías:',
      logos: [
        {
          name: 'Deliveroo',
          image: 'deliveroo.png'
        },
        {
          name: 'Twitter',
          image: 'twitter.png'
        },
        {
          name: 'Cabify',
          image: 'cabify.png'
        },
        {
          name: 'BBVA',
          image: 'bbva.jpg'
        },
        {
          name: 'Orange',
          image: 'orange.svg'
        },
        {
          name: 'BCG',
          image: 'bcg.png'
        },
        {
          name: 'El Corte Inglés',
          image: 'el-corte-ingles.jpg'
        },
        {
          name: 'Telefonica',
          image: 'telefonica.png'
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: '¿Qué cursos puedo estudiar?',
          answer: [
            'Tú eliges. Mira bien qué programa encaja mejor contigo, tienes los temarios disponibles en nuestra web. Puedes optar a los cursos de Desarrollo Web, Diseño UX/UI o Data Analytics. Además, puedes cursar tu beca en Ironhack Madrid o en Ironhack Barcelona y escoger entre la modalidad a tiempo completo o a tiempo parcial.'
          ]
        },
        {
          question: '¿Qué pasa si obtengo la beca?',
          answer: [
            '¡Prepárate para una experiencia de aprendizaje intensiva! Gracias a los cursos de Ironhack adquirirás los conocimientos necesarios para acceder a trabajar en aplicaciones referentes en el entorno tecnológico como Deliveroo. Serán unos meses intensos pero ¡saldrás preparado para comerte el mundo!'
          ]
        },
        {
          question: '¿Puedo elegir las fechas del curso?',
          answer: [
            'En la primera fase de la aplicación tienes que elegir campus, curso y la fecha en la que quieres realizarlo. Estas becas son sólo aplicables para los campus de Madrid y Barcelona.'
          ]
        },
        {
          question: '¿Cómo funciona el proceso de selección?',
          answer: [
            'Deberás inscribirte antes del 16 de octubre para acceder al proceso. Los preseleccionados realizarán una entrevista personal y técnica. No se requiere experiencia previa, solo que tengas potencial para aprender. Para la realización de la prueba técnica, previamente se te enviarán unos materiales para que puedas preparártela con tiempo. Recuerda que esta parte del proceso es presencial en el Campus de Madrid o Barcelona.'
          ]
        },
        {
          question: '¿Quién puede solicitarla?',
          answer: [
            'Si tienes más de 18 años, puedes presentar tu solicitud. ¡No necesitas experiencia previa! Sólo un montón de ganas y motivación.'
          ]
        }
      ]
    },
    campus: {
      title: 'Descubre nuestros campus',
      campus: [
        {
          name: 'Ironhack Barcelona',
          video: 'https://www.youtube.com/embed/s2cv8AV-lFs',
          address1: 'Carrer de Pamplona, 96, 08018 Barcelona',
          email: 'bcn@ironhack.com',
          whatsapp: '936 39 65 43'
        },
        {
          name: 'Ironhack Madrid',
          video: 'https://www.youtube.com/embed/XrBoQjm0WKE',
          address1: 'Carrer de Pamplona, 96, 08018 Barcelona',
          email: 'mad@ironhack.com',
          whatsapp: '636 17 63 82'
        }
      ]
    },
    termsAndConditions: {
      text: 'Bases legales',
      href:
        'https://drive.google.com/file/d/1W2_cBH21cNFLABd2f-9cd0YYB9hxeqMc/view'
    }
  }
};
