import React from 'react';
import uniqid from 'uniqid';
import Props, { Item } from '../interfaces/ListComponent';

function ListComponent(props: Props): JSX.Element {
  const { listItems, ChildrenComponent } = props;
  return (
    <React.Fragment>
      {listItems.map((item: Item) => {
        return <ChildrenComponent key={uniqid()} {...item} />;
      })}
    </React.Fragment>
  );
}

export default ListComponent;
