module.exports = {
  template: 'electronicArts',
  context: {
    url: '/es/impacto-social/beca-ea-thesims',
    lang: 'es',
    breadcrumb: 'Beca The Sims',
    seo: {
      title: 'Toma las riendas de tu futuro con The Sims y Ironhack',
      description:
        'Aprende habilidades tecnológicas con The Sims y Ironhack. Aplica y gana una beca para participar en un bootcamp tecnológico en uno de nuestros 9 campus.'
    },
    header: {
      title: 'Toma las riendas de tu futuro con',
      titleHighlighted: 'The Sims y Ironhack',
      backgroundImage: 'electronic-arts/header.png',
      alternativeLogo: 'electronic-arts/logo.png',
      alternativeLogoAltText: 'Logo Electronic Arts',
      alternativeLogoClass: 'electronic-arts',
      subtitle: '',
      paragraph:
        'En honor al lanzamiento de The Sims™ 4 Discover University, Ironhack quiere darte la oportunidad de que vuelvas a estudiar algo que te apasione. Igual que tus Sims estudian en el Instituto Foxbury, tu podrás hacer lo mismo en uno de los 9 campuses que tiene Ironhack alrededor del mundo en sus cursos de Desarrollo Web, Diseño UX/UI o Data Analytics. Tendrás la oportunidad de aprender algunas de las competencias más demandadas por las empresas tecnológicas y también por la industria de los videojuegos.'
    },
    navbar: {
      text: [
        'Escoge tu camino, aprende nuevas competencias y aplica para ganar una de las becas en cualquiera de los campuses de Ironhack.'
      ],
      linkHref: '',
      linkText: 'Aplica ya'
    },
    video: {
      href: '/landings/electronic-arts/landing-image.png',
      isVideo: false
    },
    valueProposition: {
      title:
        '800 000€ en becas<em>para aprender sobre tecnología y tomar las riendas de tu futuro</em>',
      content: [
        {
          title: '¿Que cursos estan incluídos?',
          text: [
            'El programa de becas The Sims y Ironhack te permitirá adentrarte en una de las áreas más demandadas del mundo tech: programación web, diseño UX/UI o Data Analytics. Escoge la fecha y el Campus que más se ajuste a tus necesidades, incluyendo los programas intensivos y los de tiempo parcial.'
          ]
        },
        {
          title: '¿Quíen es elegible para la beca?',
          text: [
            'Cualquier persona mayor de 18 años puede solicitarla. No necesitas experiencia previa, solo muchas ganas de aprender y motivación alta.'
          ]
        },
        {
          title: '¿Como puedo convertirme en estudiante?',
          text: [
            'Puedes aplicar hasta el 17 de Diciembre. Los preseleccionados realizarán una entrevista personal y técnica que puede hacerse online. No se requiere experiencia previa, solo que tengas potencial para aprender. Para la realización de la prueba técnica, previamente se te enviarán unos materiales para que puedas preparártela con tiempo.',
            'Los ganadores de las becas podrán disfrutar de una beca por la totalidad del coste del programa o de una beca parcial de entre 1000€ y 8000€ dependiendo del campus al que hayas aplicado.'
          ]
        },
        {
          title: '¿Puedo aplicar a cualquier campus de Ironhack?',
          text: [
            'Independientemente de donde vivas, puedes aplicar en cualquiera de nuestros 9 campuses. Eso sí, tendrás que tener en cuenta que el visado, el alojamiento y transporte son responsabilidad tuya.'
          ]
        }
      ]
    },
    courses: {
      title: 'Los Cursos',
      courses: [
        {
          name: 'Desarrollo Web',
          description:
            'Conviértete en Programador Web Full-Stack. Aprende diseño front-end y arquitectura back-end, además de tecnologías punteras como HTML5, CSS3, JavaScript, React, Node.js, Express y MongoDB. Ofrecemos modalidades tanto a tiempo completo como parcial.',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Programa Bootcamp',
              schedule:
                '9 semanas intensivas, de lunes a viernes de 9:00 a 18:00'
            },
            {
              name: 'Programa Part-Time',
              duration: '24 SEMANAS // TIEMPO PARCIAL',
              schedule:
                '24 semanas. Martes y jueves de 18:30 a 21:30 y sábado de 9:00 a 15:00'
            }
          ]
        },
        {
          name: 'Diseño UX/UI',
          description:
            'Conviértete en un diseñador UX/UI. Aprende diseño centrado en el usuario aplicando el proceso de Design Thinking y valida tus ideas a través de la investigación del usuario, prototipos, pruebas de usuario y evaluación heurística. Aprende UI e IxD para exponer tus ideas usando técnicas de diseño visual e interacción. Ofrecemos modalidades tanto a tiempo completo como parcial.',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Programa Bootcamp',
              schedule:
                '9 semanas intensivas, de lunes a viernes de 9:00 a 18:00'
            },
            {
              name: 'Programa Part-Time',
              duration: '24 SEMANAS // TIEMPO PARCIAL',
              schedule:
                '24 semanas. Martes y jueves de 18:30 a 21:30 y sábado de 9:00 a 15:00'
            }
          ]
        },
        {
          name: 'Análisis de Datos',
          description:
            'Conviértete en un maestro de análisis de datos. Aprende a limpiar y a explorar distintos de conjuntos de datos. Sumérgete a fondo usando Pandas para convertir tus datos en visualizaciones increíbles capaces de contar historias. Termina con Machine Learning y aplicando tu aprendizaje con conjuntos de datos reales! Te ofrecemos esto de dos maneras: a tiempo parcial o completo.',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Programa Bootcamp',
              schedule:
                '9 semanas intensivas, de lunes a viernes de 9:00 a 18:00'
            },
            {
              name: 'Programa Part-Time',
              duration: '24 SEMANAS // TIEMPO PARCIAL',
              schedule:
                '24 semanas. Martes y jueves de 18:30 a 21:30 y sábado de 9:00 a 15:00'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Proceso de admisión',
      steps: [
        {
          title: 'Paso 1: Rellena el formulario de admisión',
          description: 'Candidaturas abiertas hasta el 17 de diciembre de 2019'
        },
        {
          title: 'Paso 2: Completa el test técnico',
          description:
            'Recibirás un email con todos los materiales necesarios para prepararte para una pequeña prueba online relacionada con la temática en la que te quieras formar. Prepárate bien los materiales, y cuando te sientas preparado, comienza la prueba. TIenes hasta el 5 de enero de 2020 para completarlo.'
        },
        {
          title: 'Paso 3: Entrevista Personal',
          description:
            'Si la parte técnica ha ido bien, te invitaremos a una entrevista personal de unos 10-15 minutos, puede ser por teléfono o en persona, si vives cerca de nuestro campus. Todas las entrevistas personales serán hechas antes del 24 de enero.'
        },
        {
          title: 'Paso 4: Resultados',
          description:
            'Los ganadores serán avisados durante la semana del 24 de enero de 2020.'
        },
        {
          title: '¡He sido seleccionado para una beca! ¿Ahora qué?',
          description:
            'Si eres seleccionado para una de las becas parciales tendrás que confirmar tu asistencia al curso en nuestro campus completando el pago del depósito para reservar tu plaza. Si has sido el ganador de una beca total, también necesitamos que confirmes tu asistencia al curso.'
        }
      ]
    },
    campuses: {
      selectAnother: 'Selecciona otro campus',
      noCampusTitle: 'Escoge tu país - Campus',
      campusTitle: 'Has seleccionado',
      discover: '¡Descubre nuestro campus!',
      apply: 'Aplica ya',
      items: [
        {
          code: 'ams',
          city: 'Amsterdam',
          name: 'Amsterdam, Netherlands',
          address: 'Prinsengracht 437<br />1016 HM<br />Amsterdam',
          ft: 'Cursos impartidos en inglés',
          pt: 'Cursos impartidos en inglés',
          amounts:
            'Cuantía de la becas nacionales: <b>7000€, 3000€, 2000€ and 1000€</b><br />Cuantía de la becas internacionales: <b>3500€, 2500€ and 1500€</b>'
        },
        {
          code: 'bcn',
          city: 'Barcelona',
          name: 'Barcelona, Spain',
          address: 'Carrer de Pamplona 96<br />08018 Barcelona',
          ft:
            'Curso en español, contenido pedagógico en inglés y curso en inglés contenido en inglés',
          pt: 'Curso siempre en español, contenido pedagógico en inglés',
          amounts:
            'Cuantía de la becas nacionales: <b>1250€</b><br />Cuantía de la becas internacionales: <b>2000€ and 7000€</b>'
        },
        {
          code: 'ber',
          city: 'Berlin',
          name: 'Berlin, Germany',
          address: 'c/o Techspace<br />Lobeckstraße 36-40<br />10969 Berlin',
          ft: 'Cursos impartidos en inglés',
          pt: 'Cursos impartidos en inglés',
          amounts:
            'Cuantía total de las becas: <b>1125€, 1875€, 3750€ and 7500€</b>'
        },
        {
          code: 'lis',
          city: 'Lisbon',
          name: 'Lisbon, Portugal',
          address:
            'Heden Waterfront<br />Doca Jardim do Tabaco<br />Terminal de Cruzeiros de Lisboa<br />Edifício NW, 1º Piso, 1100-651 Lisboa',
          ft: 'Cursos impartidos en inglés',
          pt: 'Cursos impartidos en inglés',
          amounts: 'Cuantía total de las becas: <b>6000€ and 2000€</b>'
        },
        {
          code: 'mad',
          city: 'Madrid',
          name: 'Madrid, Spain',
          address: 'Paseo de la Chopera, 14<br />28045 Madrid',
          ft: 'Curso en español, contenido pedagógico en inglés',
          pt: 'Curso en español, contenido pedagógico en inglés ',
          amounts:
            'Cuantía de la becas nacionales: <b>1250€</b><br />Cuantía de la becas internacionales: <b>1500€</b>'
        },
        {
          code: 'mex',
          city: 'Mexico City',
          name: 'Mexico City, Mexico',
          address:
            'Tonalá 10<br />Colonia Roma Norte<br />06700, Ciudad de México',
          ft: 'Curso impartido en español',
          pt: 'Curso impartido en español',
          amounts:
            'Cuantía de la becas nacionales: <b>$15,000MXN and $45,000MXN</b><br />Cuantía de la becas internacionales: <b>$20,000MXN and $45,000MXN</b>'
        },
        {
          code: 'mia',
          city: 'Miami',
          name: 'Miami, United States',
          address: '120 SW 8th St<br />Miami FL 33130<br />United States',
          ft: 'Cursos impartidos en inglés',
          pt: 'Cursos impartidos en inglés',
          amounts:
            'Cuantía de la becas nacionales: <b>$2,000 - $12,000</b><br />Cuantía de la becas internacionales: <b>$3,000 - $12,000</b>'
        },
        {
          code: 'par',
          city: 'Paris',
          name: 'Paris, France',
          address: '226 Boulevard Voltaire<br />75011 Paris',
          ft: 'Cursos impartidos en inglés, contenido pedagógico en inglés',
          pt: 'Cursos impartidos en francés, contenido pedagógico en inglés',
          amounts:
            'Cuantía de la becas nacionales: <b>1500€, 2500€ and 8000€</b><br />Cuantía de la becas internacionales: <b>2500€, 3500€ and 8000€</b>'
        },
        {
          code: 'sao',
          city: 'São Paulo',
          name: 'São Paulo, Brazil',
          address:
            'Alameda Jaú, 1301 - Jardim Paulista<br />São Paulo - SP, 01420-001',
          ft: 'Cursos impartidos en Portugués, contenido pedagógico en inglés',
          pt: 'Cursos impartidos en Portugués, contenido pedagógico en inglés',
          amounts:
            'Cuantía de la becas nacionales: <b>$3.500, $5.000 and $7.000</b><br />Cuantía de la becas internacionales: <b>$5.500, $7.000 and $9.000</b>'
        }
      ],
      courses: {
        title: 'Próximos cursos',
        noCohorts: 'El campus seleccionado no tiene cursos'
      }
    },
    pardotForm: {
      sectionId: 'pardot-form',
      title: 'Solicita una beca',
      formUrl: 'https://www2.ironhack.com/l/688303/2019-11-28/82vsb'
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: '¿Por qué esta colaboración entre Los Sims e Ironhack?',
          answer: [
            'Con el nuevo pack de expansión Discover University de Los Sims 4™, los Sims pueden ir a la universidad y aprender todo lo necesario para su futura carrera hacia el éxito. Esta colaboración te permite hacer lo mismo con tu propio futuro. Ofrecemos a jugadores de todas partes del mundo la oportunidad de llevar el aprendizaje online al mundo real, introduciendo la industria de videojuegos y tecnología a traves de cursos en Web Development, UX/UI Design y Data Analytics.'
          ]
        },
        {
          question:
            '¿Cuál es el criterio de selección? ¿Es necesario tener experiencia pervia para acceder a un curso?',
          answer: [
            'Aunque siempre ayuda, sabemos que no es necesario tener experiencia previa en el sector al que te apuntes. Sin embargo, evaluaremos tus capacidades de aprendizaje, tu proceso lógico y creativo para asegurarnos que seas un buen pefil para este modelo de educación. Eso si, es necesario ser 18+.'
          ]
        },
        {
          question: '¿Qué campus puedo escoger para estudiar en estas becas?',
          answer: [
            'Puedes elegir entre todos los campus de Ironhack: Madrid, Barcelona, París, Amsterdam, Berlín, Lisboa, Miami, México y Sao Paulo. Dependiendo del campus que elijas, las fechas de los cursos y el idioma en el que están impartidos puede variar. Recuerda que los cursos son presenciales, por lo que necesitarás estar fisicamente en la ciudad del curso que elijas durante su duración.'
          ]
        },
        {
          question: '¿Cómo participo?',
          answer: [
            '1: Rellena el cuestionario inicial',
            '2: Completa una prueba técnica online',
            '3: Realiza una entrevista personal'
          ]
        },
        {
          question:
            'Si estoy trabajando, ¿tengo que dejarlo para poder hacer el curso?',
          answer:
            'La respuesta breve es: ¡No! Y la larga: depende de la modalidad de curso que elijas. Los cursos Part-Time fueron creados para trabajadores - tendrás que estar presencialmente dos días a la semana de 6:30 a 9:30 y los sábados de 9:00 a 15:00. Para realizar el formato bootcamp es indispensable no estar comprometido con ningún otro trabajo/estudio durante la duración.'
        },
        {
          question: '¿Qué apoyo ofrecéis en la búsqueda de empleo?',
          answer:
            'Durante los pasados 5 años hemos conseguido que más de 3000 estudiantes cambien de carrera y empiecen un nuevo trabajo en el sector tecnológico. El equipo especializado de Ironhack está comprometido a ayudarte a encontrar un nuevo trabajo después de terminar tu bootcamp. Con tu trabajo duro y nuestra ayuda, estamos seguros de que puedes cumplir tus objetivos profesionales.'
        },
        {
          question:
            '¿Provéeis visado de estudiantes y otras formas de apoyo en visa?',
          answer:
            'Desafortunadamente, no proveemos soporte en temas de visado más allá de recomendaciones, ni podemos facilitar la documentación para ello. Los estudiantes internacionales tienen que asegurarse de tener los documentos necesarios.'
        },
        {
          question: '¿Facilitáis alojamiento durante el curso?',
          answer:
            'No nos encargamos del alojamiento de estudiantes durante los bootcamps. Cada participante tiene que organizar su estancia en la ciudad.'
        },
        {
          question:
            '¿He ganado una beca parcial, puedo combinar la cantidad con otro descuento o beca?',
          answer:
            'Los importe de las becas no son acumulables con ningún otro descuento o beca de Ironhack.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Términos y Condiciones',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/electronic-arts-scholarship/terms-and-conditions-es.pdf'
    }
  }
};
