import styled from 'styled-components';
import config from '../config';

export default styled.a`
  border-radius: 0.6rem;
  border: 0.2rem solid #2d354c;
  color: ${config.palette.darkBlue};
  display: inline-block;
  font-size: 1.5rem;
  min-width: 50%;
  padding: 1.5rem;
  text-align: center;
  text-decoration: none;
  & em {
    font-style: initial;
  }
  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;
