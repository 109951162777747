import React from 'react';
import Layout from '../atoms/layout';
import Eyebrow from '../atoms/titles/eyebrow';
import { Text4 } from '../atoms/body';
import {
  SectionTitle,
  SectionButtons,
  Contextual,
  EyebrowCss,
  Contact
} from './styles';
import Props from '../interfaces/ContextualCta';
import config from '../config';
import { getLinkByPageName } from '../../lib/links';
import { withTranslation } from 'react-i18next';

function ContextualCtaSocial(props: Props): JSX.Element {
  const { t } = props;
  return (
    <Layout extend={Contextual} background={config.palette.skyBlue}>
      <SectionTitle>
        <Eyebrow extend={EyebrowCss}>
          {t('eyebrow:eyebrow.joinIronhack')}
        </Eyebrow>
        <b>{t('contextual-cta:contextualCta.fundScholarship.title')}</b>
        <Text4 as="p">
          {t('contextual-cta:contextualCta.fundScholarship.description')}
        </Text4>
      </SectionTitle>
      <SectionButtons>
        <Contact href={getLinkByPageName('contact')}>
          {t('contextual-cta:contextualCta.fundScholarship.cta')}
        </Contact>
      </SectionButtons>
    </Layout>
  );
}

export default withTranslation()(ContextualCtaSocial);
