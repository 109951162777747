import React from 'react';
import Props from '../../../interfaces/Titles';
import { Component } from './Component';

function Eyebrow(props: Props) {
  const { children, ...others } = props;
  return <Component {...others}>{children}</Component>;
}

export default Eyebrow;
