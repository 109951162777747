module.exports = {
  context: {
    url: '/en/social-impact/bunq-scholarship',
    lang: 'en',
    breadcrumb: 'bunq',
    seo: {
      title: 'Boost your career with a bunq and Ironhack Scholarship',
      description:
        "Learn new Tech skills you need to boost your career with bunq and Ironhack. Apply here to win a scholarship for a tech bootcamp at Ironhack's Berlin or Amsterdam campus "
    },
    hreflangs: {},
    header: {
      title: 'Boost your career with a bunq and Ironhack Scholarship',
      backgroundImage: 'bunq/header.png',
      paragraph:
        'With the rapidly changing pace of technology, every day we are seeing new mobile apps empower our lives.  While tech teams continue to grow, statistics have shown women only make up 25% of computing roles. It’s time to act!'
    },
    navbar: {
      text: [
        'Are you ready for an inspiring change? We’d love to see you in the tech of tomorrow.'
      ],
      linkHref: 'https://ironhack.typeform.com/to/MTBednSB',
      linkText: 'Join the tech community today'
    },
    video: {
      href: 'https://www.youtube.com/embed/KNxfcUUTK6Y'
    },
    valueProposition: {
      pretitle:
        'bunq has partnered with us to support women in tech. We offer 85K in scholarships to help women change careers and join one of Ironhack’s tech bootcamps. We believe that your financial status or your background should not keep you away from your dreams. Both bunq and IronHack were born to challenge what’s expected and build something better. That’s why it’s important for us to make sure that women in tech is the new normal. And if you’re up for the challenge, we’ll help you cover the finances!',
      content: [
        {
          title: 'Which courses are included?',
          text: [
            'bunq and Ironhack scholarship program allows you to take the Web Development, UX/UI Design, or Data Analytics courses, provided by the campus of your choice. You can choose any starting date available and the format that suits you best: part-time or full-time course.'
          ]
        },
        {
          title: 'Am I eligible for this scholarship?',
          text: [
            'If you are 18 years or older, you are welcome to apply. You don’t need to have any previous experience in a specific field, only a lot of motivation and enthusiasm to learn new tech skills!'
          ]
        },
        {
          title: 'How can I become a student?',
          text: [
            'For the July Cohorts you can apply for a scholarship until 14th of July. For later start dates you can apply until the 7th of August. The application process includes a technical and a personal interview to assess your motivation and ability to learn. The application process can be done remotely.',
            'Selected participants will win partial or full scholarships for an Ironhack bootcamp. Scholarships can range from €1500 to EUR €4000 depending on country and city of your choice. For more details on scholarship amounts, select the campus you are interested in.'
          ],
          isList: true
        },
        {
          title:
            "Can I apply if I don't live in one of the countries with an Ironhack Campus?",
          text: [
            'Yes, you can apply to the country and campus of your choice! However, for international candidates, in case you are awarded a scholarship, please keep in mind you will need to organize your own travel visa application, accommodation and transportation.'
          ]
        }
      ]
    },
    courses: {
      title: 'Course Tracks',
      description: 'Learn more about the content of our different courses',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, Node.js, Express, MongoDB. Learn by practicing in a stimulating environment, and on top of classes, you will complete three projects, go through pair programming sessions, and have chance to build a full-stack web app . We offer this track in two formats: full time or part time.',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full-Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm.'
            },
            {
              duration: '24 WEEKS // PART TIME',
              name: 'Part-Time Program',
              schedule:
                'Two weekdays from 6.30pm to 9.30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm.'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Become a UX/UI designer. Learn user-centered design applying the Design Thinking process and validate your ideas through user research, prototyping, user testing, and heuristic evaluation. Learn UI and IxD to prototype your solutions using visual and interaction design techniques. We offer this track in two modalities: full time or part time.',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full-Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm.'
            },
            {
              duration: '24 WEEKS // PART TIME',
              name: 'Part-Time Program',
              schedule:
                'Two weekdays from 6.30pm to 9.30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm.'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Learn how to clean and explore a wide variety of datasets. Dive deep into serious works in data analytics using Pandas to turn your data into amazing visualizations for storytelling purposes. Wrap everything up with machine learning and apply your learnings to datasets in the real world.',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full-Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9am to 6pm.'
            },
            {
              duration: '24 WEEKS // PART TIME',
              name: 'Part-Time Program',
              schedule:
                'Two weekdays from 6.30pm to 9.30pm (either Monday & Wednesday or Tuesday & Thursday, depending on the campus) and Saturday from 10am to 5pm.'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Application Process for the bunq and Ironhack Scholarship',
      steps: [
        {
          title: 'Step 1: Fill in the application form',
          description:
            'Applications are open until the 14th of July for all the cohorts starting in July. For later cohorts you can apply until the 7th of August.'
        },
        {
          title: 'Step 2: Take the technical test',
          description:
            "You will receive an email with an invitation to take our Technical Test. No worries, you don't need to study for this. Just make sure you take the test within 3 days after receiving the email."
        },
        {
          title: 'Step 3: Personal interview',
          description:
            'If successful, you will be invited to record a 5 minute video to tell us all about your motivation to win an bunq x Ironhack scholarships.'
        },
        {
          title: 'Step 4: Results',
          description:
            'Winners for the July cohorts will be notified on the 16th of July. Winners of Scholarships for other cohorts will be notified on the 11th of August.'
        },
        {
          title: 'I won a scholarship! Now what?',
          description: [
            'If you win a partial scholarship, you will need to decide if you want to attend the course and confirm your attendance by paying a deposit, which will be deducted from the remaining tuition fees.',
            'If you win a full scholarship, you will need to decide if you want to attend the course and confirm your attendance by written confirmation. Once you have confirmed your attendance to a bootcamp, get ready for an exciting, intense, and full-on learning experience!'
          ]
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Why are bunq and Ironhack partnering?',
          answer: [
            'With the rapidly changing pace of technology, every day we are seeing new apps empower our lives.  While tech teams continue to grow, statistics have shown women only make up 25% of computing roles. It’s time to act!',
            'We think that finances or the status quo should not keep you away from your dreams. Both bunq and IronHack were born to challenge what’s expected and build something better. That’s why it’s important for us to make sure that women in tech is the new normal. And if you’re up for the challenge, we’ll help cover the finances.'
          ]
        },
        {
          question:
            'What are the selection criteria? Do I need previous experience/knowledge to access the courses?',
          answer:
            "You don't need any previous experience; however, we will evaluate your ability to learn as well as your logic and creative skills to assess if you would be successful in this type of learning environment. We will also assess your motivation and reasons to join to ensure success in an intense learning environment. You need to be over 18 years old to apply."
        },
        {
          question: 'For which courses can I apply?',
          answer:
            'You can apply for parttime and fulltime courses in Berlin and Amsterdam. For Amsterdam: UXUI Design, Web Development & Data Analytics. For Berlin: UXUI Design, Web Development & Data Analytics.'
        },
        {
          question: 'Do I have to quit my job to take the course?',
          answer:
            'Short answer: no! Long answer: it depends on the course format you choose. The part-time courses are designed if you are already working—you will have classes on two evenings during the week as well as three Saturdays per month. For the bootcamps, you will have to be available full-time. However, many alumni get approval from their employer to take time off and return after the bootcamp.'
        },
        {
          question: 'Which Career Services do you offer?',
          answer:
            "During our 5+ years helping students achieve success, we've developed a thorough understanding of what it takes to land a job in the digital and tech ecosystem. Behind our success is a strong network of hiring partners, a dedicated outcomes team, and a deep knowledge of the tech job market and how to navigate it. We will help you get job-ready with one-on-one mentorship, workshops on CV building, interview trainings and more. Our outcomes team will support you even after your graduation to help you land your dream job."
        },
        {
          question:
            'I won a partial scholarship, can I combine this with another discount or scholarship?',
          answer:
            'All the scholarships awarded are not cumulative with any other discount or scholarship for an Ironhack course.'
        },
        {
          question: 'Do you provide accommodation during the course?',
          answer:
            'We do not provide accomodation or accomodation help during the bootcamps. Each candidate is advised to arrange their accommodation on their own according to their needs.'
        },
        {
          question:
            'Do you provide student visas, or any sort of visa support?',
          answer:
            'Unfortunately, we do not provide visa support and cannot provide paperwork to obtain student visas. International students have to make their visa arrangement and plan visa obtention ahead.'
        }
      ]
    },
    campus: {
      title: 'Discover Our Campus:',
      campus: [
        {
          name: 'Amsterdam Campus',
          address1: 'Prinsengracht 437, 1016HM, Amsterdam',
          email: 'amsterdam@ironhack.com'
        },
        {
          name: 'Berlin Campus',
          address1: 'Lobeckstr. 36-40 — Techspace, 10969 - Berlin',
          email: 'berlin@ironhack.com'
        }
      ]
    },
    moreInfo: {
      text: 'For more information, contact our team!',
      linkText: 'Contact us',
      linkHref: 'mailto:admissions@ironhack.com'
    }
  }
};
