import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';

import { webLanguage } from './datoUtilities';

interface Stat {
  title: string;
  value: string;
}

export default class DatoStats {
  private stats: Record<string, Stat>;
  public constructor() {
    this.stats = this.getAllStats();
  }

  public getLocaleStats(locale: string): Array<Stat> {
    return _.get(this.stats, locale, []);
  }

  private getAllStats(): Record<string, Stat> {
    const { allDatoCmsStat } = useStaticQuery(
      graphql`
        query {
          allDatoCmsStat {
            edges {
              node {
                title
                value
                key
                locale
              }
            }
          }
        }
      `
    );
    return allDatoCmsStat.edges
      .map(node => node.node)
      .filter(stat => stat.value)
      .reduce((byLocale, { locale, title, value, key }) => {
        const statLocale = webLanguage(locale);
        _.update(byLocale, statLocale, stats =>
          _.concat(_.defaultTo(stats, []), { title, value, key })
        );
        return byLocale;
      }, {});
  }
}
