module.exports = {
  template: 'electronicArts',
  context: {
    url: '/br/impacto-social/ea-thesims-bolsas',
    lang: 'br',
    breadcrumb: 'The Sims Scholarship',
    seo: {
      title: 'Tome controle da sua vida com The Sims e a Ironhack',
      description:
        'Aprenda novos skills técnicos com The Sims e Ironhack. Concorra a bolsas de estudos parciais para participar em qualquer um de nossos cursos em 2020.'
    },
    header: {
      title: 'Tome controle da sua vida com',
      titleHighlighted: 'The Sims e a Ironhack',
      backgroundImage: 'electronic-arts/header.png',
      alternativeLogo: 'electronic-arts/logo.png',
      alternativeLogoAltText: 'Electronic Arts logo',
      alternativeLogoClass: 'electronic-arts',
      subtitle: '',
      paragraph:
        'Para celebrar o lançamento do The Sims™ 4 Discover University, The Sims e a Ironhack uniram-se para te enviar de volta para a escola! É possível concorrer para uma de nossas bolsas parciais para os bootcamps e cursos meio períodos em 2020. Enquanto os seus Sims estudam no Instituto Foxbury, você poderá fazer o mesmo com a gente, em um de nossos cursos de Web Development, UX/UI Design ou Data Analytics. Terá a oportunidade de aprender algumas das habilidades com mais demanda pelas empresas de tecnologia, como a industria de vídeo games.'
    },
    navbar: {
      text: [
        'Tome controle da sua própria vida, aprenda novas habilidades e concorra a bolsas de estudo para qualquer um de nossos 9 campus.'
      ],
      linkHref: '',
      linkText: 'Aplique já!'
    },
    video: {
      href: '/landings/electronic-arts/landing-image.png',
      isVideo: false
    },
    valueProposition: {
      title:
        'Mais de €800.000 em bolsas<em>para aprender tudo na área tecnologia e mudar o seu futuro.</em>',
      content: [
        {
          title: 'Quais cursos estão incluídos?',
          text: [
            'Estão incluídos os cursos de Web Development, UX/UI Design e Data Analytics, tanto em formato integral como parcial para o ano de 2020. É possível aplicar para qualquer um dos campus da Ironhack.'
          ]
        },
        {
          title: 'Quem pode concorrer?',
          text: [
            'Qualquer pessoa com mais de 18 anos. Não é necessário ter experiência prévia, só muita vontade aprender! Não precisa ser brasileiro ou viver no Brasil, mas o visto é de sua responsabilidade. Na maioria dos casos pode fazer o curso com o visto de turista.'
          ]
        },
        {
          title: 'Estou interessado, o que tenho que fazer?',
          text: [
            'Pode aplicar até 17 de Dezembro de 2019. Os pré-selecionados realizam uma entrevista técnica e pessoal que pode ser feita online. Não pedimos experiência prévia, só vontade e potencial para aprender! Para realizar a prova técnica, nós te enviamos o material de estudo. Você terá 1 semana para realizar a prova, depois de receber o material.',
            'O valor das bolsas varia entre campus, no caso de São Paulo temos bolsas de $3.500 a $9.000 reais.'
          ]
        },
        {
          title: 'Posso aplicar para qualquer campus Ironhack?',
          text: [
            'Sim! Pode aplicar a qualquer um des nossos campus independentemente da sua nacionalidade ou do país em que vive. Todos os temas relacionados com visto, alojamento, etc., são da sua responsabilidade. Na maioria dos casos, um visto de turista é suficiente para realizar o curso.'
          ]
        }
      ]
    },
    courses: {
      title: 'Os Cursos',
      courses: [
        {
          name: 'Web Development',
          description:
            'Vire um Programador Web Full-Stack. Aprenda a programar front e back end,  além de tecnologias de ponta como HTML5, CSS3, JavaScript, React, Node.js, Express e MongoDB. Oferecemos o curso nos formatos de tempo integral e parcial.',
          courseFormats: [
            {
              duration: '10 SEMANAS // TEMPO INTEGRAL',
              name: 'Bootcamp Tempo Integral',
              schedule:
                '10 semanas intensivas com aulas de segunda a sexta das 9:00 às 18:00'
            },
            {
              duration: '24 SEMANAS // TEMPO PARCIAL',
              name: 'Programa Tempo Parcial',
              schedule:
                '24 semanas Terças, Quartas e Quintas das 19:30 às 22:30; 2 Sábados por mês das 9:00 às 18:00'
            }
          ]
        },
        {
          name: 'UX/UI Design',
          description:
            'Vire um UX/UI Designer. Aprenda design centrado no usuário aplicando processos de Design Thinking, e valide suas idéias por meio de pesquisas quantitativas e qualitativas com usuários, protótipos, testes de usabilidade e avaliações heurísticas. Aprenda UI e IxD para colocar suas ideias no papel utilizando técnicas de de desenho visual e interacção por meio de prototipação. Oferecemos o curso nos formatos de tempo integral e parcial.',
          courseFormats: [
            {
              duration: '10 SEMANAS // TEMPO INTEGRAL',
              name: 'Bootcamp Tempo Integral',
              schedule:
                '10 semanas intensivas com aulas de segunda a sexta das 9:00 às 18:00'
            },
            {
              duration: '24 SEMANAS // TEMPO PARCIAL',
              name: 'Programa Tempo Parcial',
              schedule:
                '24 semanas Terças, Quartas e Quintas das 19:30 às 22:30; 2 Sábados por mês das 9:00 às 18:00'
            }
          ]
        },
        {
          name: 'Data Analytics',
          description:
            'Aprenda a limpar, explorar e gerar insights com diferentes tipos de datasets. Utilize Python para contar histórias baseadas em dados. Termine aprendendo os conceitos básicos de Machine Learning e aplique o que aprendeu em um projeto utilizando dados reais. Oferecemos o curso nos formatos de tempo integral e parcial.',
          courseFormats: [
            {
              duration: '10 SEMANAS // TEMPO INTEGRAL',
              name: 'Bootcamp Tempo Integral',
              schedule:
                '10 semanas intensivas com aulas de segunda a sexta das 9:00 às 18:00'
            },
            {
              duration: '24 SEMANAS // TEMPO PARCIAL',
              name: 'Programa Tempo Parcial',
              schedule:
                '24 semanas Terças, Quartas e Quintas das 19:30 às 22:30; 2 Sábados por mês das 9:00 às 18:00'
            }
          ]
        }
      ]
    },
    applicationProcess: {
      title: 'Processo de admissão',
      steps: [
        {
          title: 'Passo 1: Preencha o Formulário de Inscrição',
          description: 'Candidaturas abertas até 17 de Dezembro'
        },
        {
          title: 'Passo 2: Realize a Prova Técnica',
          description:
            'Depois de realizar a inscrição, irá receber um email com todos os materiais necessários para estudo. A parte técnica consta em uma prova online relacionada ao curso que está aplicando. Estude bem utilizando os materiais recebidos, e quando estiver pronto, faça a prova!  É necessário realizar as provas até dia 5 de Janeiro de 2020.'
        },
        {
          title: 'Passo 3: Entrevista Pessoal',
          description:
            'Se for aprovado na parte técnica, vamos te convidar para uma entrevista pessoal rápida, de 15 minutos no máximo.  Esta parte pode ser feita online por google hangouts ou em pessoa no nosso campus. Todas as entrevistas pessoais têm que ser realizadas antes de 24 de Janeiro de 2020.'
        },
        {
          title: 'Passo 4: Resultados',
          description:
            'Os vencedores vão ser notificados durante a semana de 24 de Janeiro de 2020'
        },
        {
          title: 'Ganhei uma bolsa! E agora?',
          description:
            'Terá que confirmar o interesse em participar no curso pagando um depósito caução, que será depois deduzido do valor do curso.'
        }
      ]
    },
    campuses: {
      selectAnother: 'Escolha o campus',
      noCampusTitle: 'Escolha o seu país - Campus',
      campusTitle: 'Você escolheu',
      discover: 'Visit our Campus!',
      apply: 'Aplique já!',
      items: [
        {
          code: 'ams',
          city: 'Amsterdam',
          name: 'Amsterdam, Netherlands',
          address: 'Prinsengracht 437<br />1016 HM<br />Amsterdam',
          ft: 'All courses are in English',
          pt: 'All courses are in English',
          amounts:
            'Quantia de bolsas nacionais: <b>7000€, 3000€, 2000€ and 1000€</b><br />Quantia de bolsas internacionais: <b>3500€, 2500€ and 1500€</b>'
        },
        {
          code: 'bcn',
          city: 'Barcelona',
          name: 'Barcelona, Spain',
          address: 'Carrer de Pamplona 96<br />08018 Barcelona',
          ft:
            'Both courses in English, content in English and courses in Spanish, content in English',
          pt: 'Course always in Spanish, content in English',
          amounts:
            'Quantia de bolsas nacionais: <b>1250€</b><br />Quantia de bolsas internacionais: <b>2000€ and 7000€</b>'
        },
        {
          code: 'ber',
          city: 'Berlin',
          name: 'Berlin, Germany',
          address: 'c/o Techspace<br />Lobeckstraße 36-40<br />10969 Berlin',
          ft: 'All courses and curriculum are in English',
          pt: 'All courses and curriculum are in English',
          amounts:
            'Quantia total de bolsas: <b>1125€, 1875€, 3750€ and 7500€</b>'
        },
        {
          code: 'lis',
          city: 'Lisbon',
          name: 'Lisbon, Portugal',
          address:
            'Heden Waterfront<br />Doca Jardim do Tabaco<br />Terminal de Cruzeiros de Lisboa<br />Edifício NW, 1º Piso, 1100-651 Lisboa',
          ft: 'All courses are in English',
          pt: 'All courses are in English',
          amounts: 'Quantia total de bolsas: <b>6000€ and 2000€</b>'
        },
        {
          code: 'mad',
          city: 'Madrid',
          name: 'Madrid, Spain',
          address: 'Paseo de la Chopera, 14<br />28045 Madrid',
          ft: 'Course in Spanish, contents in English',
          pt: 'Course in Spanish, contents in English',
          amounts:
            'Quantia de bolsas nacionais: <b>1250€</b><br />Quantia de bolsas internacionais: <b>1500€</b>'
        },
        {
          code: 'mex',
          city: 'Mexico City',
          name: 'Mexico City, Mexico',
          address:
            'Tonalá 10<br />Colonia Roma Norte<br />06700, Ciudad de México',
          ft: 'Curso impartido en español',
          pt: 'Curso impartido en español',
          amounts:
            'Quantia de bolsas nacionais: <b>$15,000MXN and $45,000MXN</b><br />Quantia de bolsas internacionais: <b>$20,000MXN and $45,000MXN</b>'
        },
        {
          code: 'mia',
          city: 'Miami',
          name: 'Miami, United States',
          address: '120 SW 8th St<br />Miami FL 33130<br />United States',
          ft: 'All courses and curriculum are in English',
          pt: 'All courses and curriculum are in English',
          amounts:
            'Quantia de bolsas nacionais: <b>$2,000 - $12,000</b><br />Quantia de bolsas internacionais: <b>$3,000 - $12,000</b>'
        },
        {
          code: 'par',
          city: 'Paris',
          name: 'Paris, France',
          address: '226 Boulevard Voltaire<br />75011 Paris',
          ft: 'Course in English, content in English',
          pt: 'Course in French, content in English',
          amounts:
            'Quantia de bolsas nacionais: <b>1500€, 2500€ and 8000€</b><br />Quantia de bolsas internacionais: <b>2500€, 3500€ and 8000€</b>'
        },
        {
          code: 'sao',
          city: 'São Paulo',
          name: 'São Paulo, Brazil',
          address:
            'Alameda Jaú, 1301 - Jardim Paulista<br />São Paulo - SP, 01420-001',
          ft: 'Course in Portuguese, content in English',
          pt: 'Course in Portuguese, content in English',
          amounts:
            'Quantia de bolsas nacionais: <b>$3.500, $5.000 and $7.000</b><br />Quantia de bolsas internacionais: <b>$5.500, $7.000 and $9.000</b>'
        }
      ],
      courses: {
        title: 'Upcoming Cohorts',
        noCohorts: 'The campus has no available cohorts'
      }
    },
    pardotForm: {
      sectionId: 'pardot-form',
      title: 'Aplique para concorrer a bolsas e descontos',
      formUrl: 'https://www2.ironhack.com/l/688303/2019-12-02/8vmw6'
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Porquê esta colaboração entre os Sims e a Ironhack?',
          answer: [
            'Com o novo pack de expansão Discover University dos The Sims 4™, os Sims voltam à escola para aprender os skills necessários para garantir uma carreira de sucesso. Com esta colaboração, irá poder fazer o mesmo! Oferecemos aos jogadores de todo o mundo a oportunidade de levar a aprendizagem do mundo virtual para o mundo real! Estas bolsas querem incentivar a aprender e a descobrir opções de carreira dentro da área de tecnologia/ videogames, frequentando cursos de Web Development, UX/UI Design e Data Analytics.',
            'Queremos te ajudar a controlar e escolher o seu futuro, dentro e fora do jogo - concorra a uma bolsa e garanta uma chance para fazer isso.'
          ]
        },
        {
          question:
            'Quais os critérios de seleção? É necessário ter experiência prévia na área do curso?',
          answer: [
            'Não pedimos experiência prévia. Mas vamos avaliar a sua capacidade de aprender, assim como as suas capacidades lógicas e criativas. Assim, podemos avaliar a probablilidade de ser bem sucedido em um ambiente de ensino como o nosso. Vamos também avaliar a sua motivação para ingressar em um ambiente de ensino tão exigente e intenso.',
            'Só aceitamos candidatos que na data de início do curso tenham pelo menos 18 anos!'
          ]
        },
        {
          question: 'Que campus posso escolher para utilizar as bolsas?',
          answer: [
            'Pode escolher qualquer um dos campus da Ironhack: São Paulo, Madrid, Barcelona, Paris, Amsterdam, Berlin, Lisboa, Miami e Cidade do México. As datas de início do curso e o idioma em que os cursos são leccionados depende do campus específico. Lembre-se que todos os cursos são presenciais, ou seja, é necessário estar presente nos cursos.',
            'Importante, é mandatório aplicar ao campus que deseja estudar! Não pods fazer o processo com o campus de São Paulo e depois decidir utilizar a bolsa em outro campus.'
          ]
        },
        {
          question: 'Como participo?',
          answer: [
            '1) Complete a inscrição online',
            '2) Complete a prova técnica online',
            '3) Realize um entrevista pessoal de 15 minutos'
          ]
        },
        {
          question: 'Posso frequentar o curso se estiver trabalhando?',
          answer:
            'Depende do formato de curso.  Os cursos em tempo parcial são direccionados a pessoas que estão trabalhando. Os bootcamps intensivos em tempo integral não são consiliáveis com um emprego, além das 40 horas de aulas presenciais por semana, os cursos exigem ainda dedicação adicional fora do horário das aulas.'
        },
        {
          question:
            'Que apoio disponibilizam no processo de procura de emprego?',
          answer:
            'Durante os últimos 6 anos conseguimos que mais de 3.000 estudantes em todo o mundo mudem de carreira e comecem um trabalho no sector tecnológico. O nosso time está comprometido em te ajudar a encontrar um trabalho depois de terminar o bootcamp. Não podemos garantir nada, mas estamos convencidos que com o seu trabalho duro e a nossa ajuda, irá conseguir atingir todos os seus objetivos profissionais. Só estamos no Brasil há 1 ano, mas os nossos resultados neste ponto específico têm sido muito animadores.'
        },
        {
          question: 'Disponibilizam algum tipo de apoio na obtenção de visto?',
          answer:
            'Infelizmente não, o máximo que conseguimos fazer é indicar-te alguma empresa espcializada nesse tema. No entanto no caso do Brasil, dependendo da tua nacionalidade, o visto de turista é suficiente para realizar o curso.'
        },
        {
          question: 'Disponilizam de alojamento durante o curso?',
          answer:
            'Não, mas temos preços especiais em alguns alojamentos na zona da escola. A maioria de nossos alunos de fora de São Paulo terminam optando por ficar em AirBnBs na proximidade da escola, estamos na zona da Paulista e existem muitas opções disponíveis.'
        },
        {
          question:
            'Me foi atribuída uma bolsa parcial, é cumulativa com algum outro tipo de desconto ou bolsa?',
          answer: 'Não. A única excepção é a opção de pagamento à vista.'
        }
      ]
    },
    termsAndConditions: {
      text: 'Termos e Condições',
      href:
        'https://ih-web.s3-eu-west-1.amazonaws.com/electronic-arts-scholarship/terms-and-conditions-br.pdf'
    }
  }
};
