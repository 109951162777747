import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import ListComponent from '../ListComponent';
import Stat from '../Stat';
import Section from './StatsList';
import DatoStats from '../../services/datoStats';

interface Props {
  context: string;
  columns?: number;
}

const contextMap = {
  home: ['alumni', 'campuses', 'companies', 'nationalities'],
  enterprise: ['alumni', 'campuses', 'companies', 'nationalities'],
  social_impact: ['alumni', 'campuses', 'companies', 'nationalities'],
  /*
   * updating per https://app.clickup.com/t/1ja5zxq
   * career_services: ['alumni', 'companies', 'placement_rate'],
   */
  career_services: ['alumni', 'companies'],
  women_in_tech: ['women_alumni', 'women_granted_scolarships'],
};

function StatsList(props: Props): JSX.Element {
  const { context, columns } = props;
  const i18n = useTranslation().i18n;
  const stats = new DatoStats().getLocaleStats(i18n.language);
  const statKeys = _.getOr([], context, contextMap);
  const statsByKey = _.flow(_.groupBy('key'), _.mapValues(_.head))(stats);

  const statsToShow = statKeys.map((statKey) => statsByKey[statKey]);

  return (
    <Section length={columns || statsToShow.length}>
      <ListComponent ChildrenComponent={Stat} listItems={statsToShow} />
    </Section>
  );
}

export default StatsList;
