import styled, { css } from 'styled-components';
import { ComponentProps } from '../interfaces/StatsList';
import config from '../config';

const size = (length: number) => 200 / length;

export default styled.section<ComponentProps>`
  display: flex;
  flex-wrap: wrap;
  width: 50%;

  & > section {
    width: ${props => size(props.length)}%;
    & h1 {
      font-size: 4rem;
    }
    & b {
      font-size: 1.6rem;
    }
  }
  & > section > section:nth-child(-n + 2) {
    margin-bottom: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 3.2rem;
    width: 100%;
    & > section {
      width: ${props => (props.length > 3 ? 50 : size(props.length))}%;
    }
  }

  ${props => props.extend};
`;

export const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;

  h3 {
    width: 100%;
  }

  p {
    opacity: 0.6;
    margin-top: 1.6rem;
  }

  section {
    margin-right: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;

export const SocialStats = css`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 12rem;
  margin-top: 3.2rem;
  align-items: center;

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column-reverse;
    margin-bottom: 3.2rem;
  }
`;
