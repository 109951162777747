import React from 'react';
import withI18next from '../../i18n/TemplateI18n';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import OurImpact from '../../components/OurImpact';
import Partner from '../../components/Partner';
import Footer from '../../components/Footer';
import ContextualCtaSocial from '../../components/ContextualCtaSocial';
import ContextualCtaGeneric from '../../components/ContextualCtaGeneric';
import OurSocialActions from '../../components/OurSocialActions';
import HireDigitalTalentProps from '../../interface/template';
import { LayoutCards } from './styles';
import { graphql } from 'gatsby';
import { landingsForLanguage } from './utils';
import { getLinkByPageName } from '../../lib/links';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const HireDigitalTalent: React.SFC<any> = (props: HireDigitalTalentProps) => {
  const { t, pageContext } = props;
  const { url, locale, pageName } = pageContext;
  const landings = landingsForLanguage(locale);
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header url={url} showBackground pageName={pageName}>
        <PageTitle
          title={t('social-impact:socialImpact.mainHeadline.title')}
          subtitle={t('social-impact:socialImpact.mainHeadline.subtitle')}
        />
      </Header>
      <OurImpact />
      <ContextualCtaGeneric
        className="womenInTech"
        eyebrow={t('eyebrow:eyebrow.womenInTech')}
        title={t('contextual-cta:contextualCta.womenInTech.title')}
        description={t('contextual-cta:contextualCta.womenInTech.description')}
        linkText={t('contextual-cta:contextualCta.womenInTech.cta')}
        linkHref={getLinkByPageName('womenInTech')}
      />
      <OurSocialActions
        title={t('social-impact:socialImpact.socialImpact.title')}
        subtitle={t('social-impact:socialImpact.socialImpact.subtitle')}
        buttonCta={t('social-impact:socialImpact.socialImpact.cta.title')}
        extend={LayoutCards}
        scolarships={landings}
      />
      <Partner
        title={t('social-impact:socialImpact.partners.title')}
        subtitle={t('social-impact:socialImpact.partners.description')}
        viewButton={false}
        viewChecksBox
      />
      <ContextualCtaSocial />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(social-impact)|(contextual-cta)|(eyebrow)|(menu)|(seo)|(breadcrumbs)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(HireDigitalTalent);
