module.exports = {
  context: {
    url: '/es/impacto-social/bnext',
    lang: 'es',
    breadcrumb: 'Becas BNext',
    customColor: '#ff0068',
    partnershipType: 'partnership-2',
    seo: {
      title:
        'Fórmate en tecnología con las mejores condiciones de financiación de Bnext y Ironhack',
      description:
        'Estudia en Ironhack para desarrollar tus competencias digitales. Aprende Desarollo web, UX/UI Design o Análisis de datos'
    },
    hreflangs: {},
    header: {
      title: 'Buscamos ayudar a <em>150 talentos</em> a formarse en tecnología',
      backgroundImage: 'bnext/header.jpg',
      alternativeLogo: 'bnext/logo.svg',
      alternativeLogoAltText: 'Logo BNext',
      paragraph:
        'Bnext y Ironhack se unen para ofrecer, de manera exclusiva, las mejores condiciones de financiación para formarse en tecnología. Buscamos ayudar a impulsar a los próximos 150 creadores digitales: diseñadores UX/UI, desarrolladores web y analistas de datos del futuro.'
    },
    navbar: {
      text: [
        '150 personas tendrán acceso a unas condiciones de financiación exclusivas que les permitirá formarse en tecnología: todos los ganadores optarán a la financiación a plazos y los 30 mejores, además, tendrán la posibilidad de no pagar nada del curso hasta que encuentren trabajo.'
      ],
      linkText: 'Solicita tu plaza',
      linkHref: 'https://ironhack.typeform.com/to/c7ZJft?source=landing'
    },
    video: {
      href: 'https://www.youtube.com/embed/1jEIuZFinmU'
    },
    valueProposition: {
      content: [
        {
          title:
            'Bnext te ofrece unas condiciones exclusivas de financiación para estudiar en Ironhack',
          text: [
            'Bnext nació con el propósito de revolucionar la banca tradicional en España, y quiere ayudar desarrollando nuevos talentos que puedan revolucionar otros sectores.',
            'Apostamos por la innovación tecnológica como motor del cambio de la industria y de la sociedad, por ello, lanzamos este programa con exclusivas condiciones de financiación para que 150 personas puedan cambiar o impulsar su carrera en tecnología.',
            'Fórmate en una de las áreas más punteras y más solicidadas por empresas tecnológicas: Desarrollo Web, Diseño UX/UI o Data Analytics en unas de las mejores escuelas tecnológicas a nivel mundial, Ironhack.'
          ]
        },
        {
          title: '¿Cómo funciona?',
          text: [
            '1 - Rellena el formulario para poder optar a una de las opciones de financiación ofrecidas por BNEXT.',
            'Financiación a plazos: Estudia en Ironhack pagando el curso en cómodas cuotas, hasta 36 plazos con un interés nominal entre del 4,5% (en Madrid) y 5% (en Barcelona) en exclusiva. Esto significa que solamente pagarás algo más de 300€ adicionales al coste total del bootcamp, con la ventaja de poder pagarlo por mensualidades en un periodo de hasta 3 años.',
            'Acuerdo de ingresos compartidos (ISA): Estudia en Ironhack y no pagues hasta que encuentras trabajo. Nuestra entidad colaboradora, Student Finance, se hace cargo del coste total del curso hasta que tus ingresos superen los 18.000€ anuales. Una vez que encuentres trabajo y tus ingresos sean superiores a esa cifra, pagarás un porcentaje fijo de tus ingresos durante los siguientes 48 meses o hasta que alcances el Pago Máximo.',
            '2 - Una vez hayas aplicado al programa que mejor se ajuste a ti, estudiaremos cada caso de forma individualizada. El 19 de noviembre anunciaremos los seleccionados que pasan a la siguiente fase. Entonces, tendrán que hacer una prueba presencial que consistirá en una entrevista personal y otra técnica. Os enviaremos los materiales para que puedas preparar la prueba.',
            '3 - Los días de entrevistas serán entre el 25 y el 29 de noviembre. Recuerda traer bien preparada la prueba técnica y mirar bien el temario del programa antes de enviar tu solicitud.',
            '4 - El 2 de diciembre se anunciarán los 150 ganadores que tendrán la oportunidad de aprovecharse de las condiciones de financiación únicas ofrecidas por BNEXT para formarse en tecnología con Ironhack.'
          ]
        },
        {
          title: '¿Por qué estudiar en Ironhack?',
          text: [
            'Ironhack es una de las escuelas líderes a nivel mundial en formación tecnológica con presencia en 8 países.',
            'Desde 2013 hemos formado a más de 3000 estudiantes con una tasa de colocación del 88% a los 3 meses de terminar el bootcamp',
            'Ofrecemos cursos en formato Full-Time: 9 semanas intensivas y en formato Part-Time: 6 meses compaginandolo con tu trabajo u otros estudios.'
          ]
        },
        {
          title: '¿Qué es Bnext?',
          text: [
            'Bnext es el marketplace de productos financieros y primera fintech española en ofrecer una cuenta y un medio de pago no vinculados a una entidad bancaria.',
            'La alternativa a la banca tradicional. A través de una App y desde el móvil, con una sola cuenta completamente gratuita, se pueden contratar de manera 100% digital los productos financieros y de seguros que más se adapten a las necesidades de cada persona.'
          ]
        }
      ]
    },
    courses: {
      title: 'Nuestros cursos',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Conviértete en Programador Web Full-Stack. Aprende diseño front-end y arquitectura back-end, además de tecnologías punteras como React, Express, MongoDB y metodologías ágiles. Ofrecemos estas dos modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Conviértete en un diseñador UX/UI. Aprende User Centered Design aplicando técnicas de Design Thinking. Estudia cómo validar tus ideas a través de la investigación de usuarios, prototipado y evaluación heurística. Además, aprende diseño de interfaces con Sketch y diseño de interacciones para prototipar interfaces de aplicaciones web y móviles. Ofrecemos dos modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Conviértete en un maestro de análisis de datos. Aprende a limpiar y a explorar distintos de conjuntos de datos. Sumérgete a fondo usando Pandas para convertir tus datos en visualizaciones increíbles capaces de contar historias. Termina con Machine Learning y aplicando tu aprendizaje con conjuntos de datos reales! Ofrecemos una modalidad:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 SEMANAS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        }
      ]
    },
    testimonials: {
      title: 'Algunos bnexters que estudiaron en Ironhack',
      items: [
        {
          image: 'bnext/alba-diaz.jpg',
          name: 'Alba Díaz',
          position: 'Product Designer',
          description:
            'Cuando descubrí el mundo de la experiencia de usuario tuve claro que era a lo que me quería dedicar. Decidí hacer el Part-Time UX/UI en Ironhack, donde obtuve los conocimientos necesarios para poder hacerlo posible, mientras continuaba con mi trabajo. Fue una experiencia muy positiva, sobre todo por los compañeros y profesores.'
        },
        {
          image: 'bnext/carlos-horno.jpg',
          name: 'Carlos Horno Santacruz',
          position: 'Marketplace Product Manager',
          description:
            'El Bootcamp de Data me ha permitido ampliar mis conocimientos acerca de data science, en concreto sobre ETL y pipelines, aportándome las capacidades técnicas necesarias para entender, dar seguimiento y liderar el proyecto de desarrollo de integraciones del Marketplace de Bnext. Sin duda, este Bootcamp ha influido notablemente en mi crecimiento profesional.'
        }
      ]
    },
    careerSupport: {
      title: 'Dónde trabajan nuestros estudiantes',
      text:
        'En nuestro bootcamp aprendes las habilidades más demandadas. Es por eso que la mayoría de nuestros graduados han sido contratados en grandes compañías:',
      logos: [
        {
          name: 'Bnext',
          image: 'bnext.png'
        },
        {
          name: 'Twitter',
          image: 'twitter.png'
        },
        {
          name: 'Cabify',
          image: 'cabify.png'
        },
        {
          name: 'Accenture',
          image: 'accenture.png'
        },
        {
          name: 'Deliveroo',
          image: 'deliveroo.png'
        },
        {
          name: 'BCG',
          image: 'bcg.png'
        },
        {
          name: 'Google',
          image: 'google.png'
        },
        {
          name: 'Telefonica',
          image: 'telefonica.png'
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: '¿Qué cursos puedo estudiar?',
          answer: [
            'Tú eliges. Mira bien qué programa encaja mejor contigo, tienes los temarios disponibles en nuestra web. Puedes optar a los cursos de Desarrollo Web, Diseño UX/UI o Data Analytics. Solo podrás cursarlo en los Campus de Ironhack Madrid y Ironhack Barcelona, escogiendo entre la modalidad a tiempo completo o a tiempo parcial.'
          ]
        },
        {
          question: '¿Puedo elegir las fechas del curso?',
          answer: [
            'En la primera fase de la aplicación tienes que elegir campus, curso y la fecha en la que quieres realizarlo. Estas becas son sólo aplicables para los campus de Madrid y Barcelona.'
          ]
        },
        {
          question: '¿Cómo funciona el proceso de selección?',
          answer: [
            'Deberás inscribirte antes del 19 de noviembre para acceder al proceso. Los preseleccionados realizarán una entrevista personal y técnica. No se requiere experiencia previa, solo que tengas potencial para aprender. Para la realización de la prueba técnica, previamente se te enviarán unos materiales para que puedas preparártela con tiempo. Recuerda que esta parte del proceso es presencial.'
          ]
        },
        {
          question: '¿Qué pasa si obtengo una de las financiaciones?',
          answer: [
            '¡Prepárate para una experiencia de aprendizaje intensiva! Gracias a los cursos de Ironhack adquirirás los conocimientos necesarios para acceder a trabajar en empresas referentes en el entorno tecnológico. Serán unos meses intensos pero ¡saldrás preparado para comerte el mundo!'
          ]
        },
        {
          question: '¿Qué es un Acuerdo de Ingresos Compartidos (ISA)?',
          answer: [
            'ISA es un acuerdo por el que los estudiantes reciben financiación para sus estudios con una entidad con la que colaboramos: Student Finance. A cambio, una vez estén trabajando, abonan un porcentaje fijo de su sueldo durante un número predeterminado de años. Student Finance se hace cargo del coste del curso hasta que tus ingresos superen los 18.000€ anuales. Este tipo de financiación solo es aplicable para el formato Full Time.'
          ]
        },
        {
          question: '¿Qué es la financiación a plazos?',
          answer: [
            'Es un tipo de financiación que cubre el 100% del importe del curso. El tipo de interés de esta financiación es de un 4,5% nominal en Madrid y un 5% Barcelona. Por poner un ejemplo, en el caso de Madrid solamente pagarías 315€ adicionales si escoges el formato Full Time y en el Part time 340€ de más, en un plazo de hasta 3 años pagandolo en cuotas mensuales y sin comisión de apertura.'
          ]
        },
        {
          question:
            '¿Cuáles son las ventajas de Bnext como alternativa a la banca tradicional?',
          answer: [
            'Con la App de Bnext podrás solicitar una tarjeta VISA prepago gratuita, sin condiciones y que te llegará a casa en 24-48h laborables. Al utilizarla, tendrás las siguientes ventajas:',
            'Devolución de comisiones al pagar en cualquier comercio del mundo. Hasta 2.000€  por mes natural. Se aplica el cambio oficial VISA sin comisiones extra. Consultar toda la info de la devolución de comisiones aquí',
            'Podrás retirar dinero de cajeros de todo el mundo hasta 3 veces por mes natural (máx. 500€ en esas tres retiradas). Bnext te devolverá las comisiones que otros te cobran.',
            'Se te devolverán las comisiones de cualquiera de los cajeros de España 3 veces por mes natural',
            'Pagarás con el tipo de cambio de VISA, ahorrandote más de un 3-5% respecto las tarjetas de la mayoría de los bancos.',
            'Puedes registrarte y encontrar más información en su web.'
          ]
        }
      ]
    },
    campus: {
      title: 'Descubre nuestros campus',
      campus: [
        {
          name: 'Ironhack Barcelona',
          video: 'https://www.youtube.com/embed/s2cv8AV-lFs',
          address1: 'Carrer de Pamplona, 96, 08018 Barcelona',
          email: 'bcn@ironhack.com',
          whatsapp: '936 39 65 43'
        },
        {
          name: 'Ironhack Madrid',
          video: 'https://www.youtube.com/embed/XrBoQjm0WKE',
          address1: 'Carrer de Pamplona, 96, 08018 Barcelona',
          email: 'mad@ironhack.com',
          whatsapp: '636 17 63 82'
        }
      ]
    },
    termsAndConditions: {
      text: 'Bases legales',
      href: 'https://ih-web.s3-eu-west-1.amazonaws.com/becas-bnext-spain.pdf'
    }
  }
};
