module.exports = {
  context: {
    url: '/es/impacto-social/becas-rappi',
    lang: 'es',
    breadcrumb: 'Becas Rappi',
    seo: {
      title: 'Ironhack Web Development Bootcamp & UX/UI Design Bootcamp',
      description:
        'Learn to code and UX/UI design through one of our full time or part time courses. Web Development Bootcamp and UX/UI Design Bootcamp in Madrid, Barcelona, Miami, Paris, Mexico City, Berlin and Lisbon.'
    },
    hreflangs: {
      br: '/br/impacto-social/bolsas-rappi',
      es: '/es/impacto-social/becas-rappi'
    },
    header: {
      title: 'Becas Rappi +',
      titleHighlighted: 'Ironhack',
      backgroundImage: 'rappi/header.png',
      alternativeLogo: 'rappi/rappi.png',
      alternativeLogoAltText: 'Rappi logo',
      paragraph:
        'Rappi y Ironhack se unen para ofrecer 2 millones de pesos en becas para la formación en Desarrollo Web, Diseño UX/UI o Data Analytics. Apostamos por el desarrollo de grandes profesionales en áreas técnicas y queremos promover la inclusión del talento mexicano en el mundo tecnológico.'
    },
    navbar: {
      text: [
        'Ironhack y Rappi se unen para promover la inclusión del talento mexicano en el mundo tecnológico otorgando 2 millones de pesos en becas para los cursos de Ironhack'
      ],
      linkText: 'Solicita tu beca',
      linkHref: 'https://ironhack.typeform.com/to/Syjx0O'
    },
    video: {
      href: 'https://www.youtube.com/embed/fQn3ZCfOQbg'
    },
    valueProposition: {
      content: [
        {
          title: '¿Por qué Rappi y Ironhack quieren promover tu educación?',
          text: [
            'Con esta alianza puedes aplicar a una beca y capacitarte en Desarrollo Web, Diseño UX/UI o Data Analytics.',
            'En Ironhack estamos convencidos que cualquier persona debería tener la posibilidad de cumplir sus sueños y reinventarse, sin importar su trasfondo profesional y académico. Queremos darte la oportunidad de cambiar de carrera en solo 9 semanas o en 24 semanas a tiempo parcial y así diseñar un futuro profesional que te guste y te apasione.'
          ]
        },
        {
          title: '+ de 2 millones de pesos en becas',
          text: [
            '3 ganadores recibirán una beca completa (de un valor de 81,200 MXN cada una ) y otorgaremos 90 becas parciales (becas de 23,000 y 18,000 MXN).'
          ]
        },
        {
          title: '¿Cómo funciona?:',
          text: [
            '1. Descarga la aplicación de Rappi',
            '2. Abre la aplicación y presiona el botón "Becas Ironhack"',
            '3. Presiona sobre "Solicita tu beca" y llena el formulario con todos los datos indicados incluyendo el curso y la fecha de tu interés',
            '4. Enviaremos por correo tu acceso al material de estudio y una prueba técnica para empezar con el proceso'
          ],
          isList: true
        }
      ]
    },
    courses: {
      title: 'Nuestros cursos',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Conviértete en un desarrollador full-stack. Aprende diseño front-end y arquitectura de back-end, además de tecnologías punteras como React, Express, MongoDB y metodologías ágiles. Ofrecemos dos modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Conviértete en un diseñador UX/UI. Aprende User Centered Design aplicando técnicas de Design Thinking. Estudia cómo validar tus ideas a través de la investigación de usuarios, prototipado y evaluación heurística. Además, aprende diseño de interfaces con Sketch y diseño de interacciones para prototipar interfaces de aplicaciones web y móviles. Ofrecemos dos modalidades:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Conviértete en un maestro de análisis de datos. Aprende a limpiar y a explorar distintos de conjuntos de datos. Sumérgete a fondo usando Pandas para convertir tus datos en visualizaciones increíbles capaces de contar historias. Termina con Machine Learning y aplicando tu aprendizaje con conjuntos de datos reales! Ofrecemos una modalidad:',
          courseFormats: [
            {
              duration: '9 SEMANAS // TIEMPO COMPLETO',
              name: 'Full Time Program',
              schedule:
                '9 semanas de programa intensivo, de Lunes a Viernes de 9am a 6pm.'
            },
            {
              duration: '24 WEEKS // PART-TIME',
              name: 'Part Time Program',
              schedule:
                'Dos días entre semana de 7:00pm a 10:00pm y Sábados de 10am a 5pm.'
            }
          ]
        }
      ]
    },
    careerSupport: {
      title: 'Apoyo profesional',
      text:
        'Ironhack está comprometido a ayudarte a encontrar un nuevo trabajo después de terminar tu bootcamp. Con tu trabajo duro y nuestra ayuda, estamos seguros de que puedes cumplir tus objetivos profesionales. Nuestra tasa de colocación es superior al 90%, 3 meses después de la graduación, y nuestros alumnos trabajan en empresas como:',
      logos: [
        {
          name: 'Clip',
          image: 'clip.jpg'
        },
        {
          name: 'Konfio',
          image: 'konfio.png'
        },
        {
          name: 'Kubo Financiero',
          image: 'kubo-financiero.jpg'
        },
        {
          name: 'AeroMexico',
          image: 'aeromexico.jpg'
        },
        {
          name: 'Volaris',
          image: 'volaris.png'
        },
        {
          name: 'BBVA Bancomer',
          image: 'bbva-bancomer.png'
        },
        {
          name: 'Nolte',
          image: 'nolte.png'
        },
        {
          name: 'bquate',
          image: 'bquate.jpg'
        }
      ]
    },
    faq: {
      title: 'Preguntas Frecuentes',
      items: [
        {
          question: '¿Qué cursos incluye?',
          answer:
            'Esta beca te permitirá estudiar alguno de nuestros bootcamps en Desarrollo Web, Diseño UX o Data Analytics. Tú eliges la fecha de alguno de los cursos programados para 2020: puedes escoger entre la modalidad de tiempo completo o de tiempo parcial.'
        },
        {
          question: '¿Qué pasa si obtengo la beca?',
          answer:
            '¡Prepárate para una experiencia de aprendizaje intensiva! Sí, tendrás que estudiar mucho, ¡pero la recompensa valdrá la pena!'
        },
        {
          question: '¿Cómo funciona el proceso de selección?',
          answer:
            'La inscripción debe realizarse a través de la app de Rappi antes del 30 de abril. Recibirás en tu correo la liga a un material de estudio y una prueba técnica que deberás completar en línea. En caso de aprobarla tendrás una entrevista personal. No se evaluará la experiencia, sino el potencial de los candidatos.'
        },
        {
          question: '¿Quién puede solicitarla?',
          answer:
            'Cualquier persona mayor de 18 años puede presentar su solicitud. ¡No se necesita experiencia previa! Sólo muchas ganas y motivación.'
        },
        {
          question: '¿Cómo se anunciarán los resultados?',
          answer: [
            'Para que el proceso sea más sencillo para ti, hemos diseñado una prueba online que podrás hacer desde tu computadora o teléfono móvil.',
            'Una vez que concluyas tu prueba técnica online, deberás agendar una entrevista con nuestro staff de admisiones, misma que podrá ser remota o presencial.'
          ]
        },
        {
          question: '¿Qué fechas límite debo tener en cuenta?',
          answer: [
            'Puedes aplicar a la beca en la app de Rappi hasta el 30 de abril.',
            'Puedes resolver tu prueba técnica hasta el 7 de mayo.',
            'Puedes agendar tu entrevista personal hasta el 14 de mayo.',
            'Anunciaremos los resultados en 3 etapas: 30 de abril, 7 de mayo y 22 de mayo. En cada uno de estos anuncios, se otorgarán alrededor de 1 beca total, 8 becas de 23,000, y 20 de 18k.',
            'Solo se puede aplicar una vez a la beca y a la prueba técnica.'
          ]
        }
      ]
    },
    campus: {
      title: 'Descubre nuestro campus',
      campus: {
        name: 'Campus Ironhack Ciudad de México',
        video: 'https://www.youtube.com/embed/qG6s6Bk3nMU',
        address1: 'Tonalá 10, 06700, Colonia Roma Norte'
      }
    },
    moreInfo: {
      text: '¿Tienes alguna pregunta más?',
      linkText: '¡Contacta con nuestro equipo!',
      linkHref: 'mailto:contactomex@ironhack.com'
    },
    termsAndConditions: {
      text: 'Consulta los Términos y Condiciones',
      href: 'https://ih-web.s3-eu-west-1.amazonaws.com/becas-rappi-2020.pdf'
    }
  }
};
