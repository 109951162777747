import styled from 'styled-components';
import config from '../../../config';
import { PropsComponent } from '../../../interfaces/Titles';

export const Component = styled('span')<PropsComponent>`
  color: ${props =>
    props.isCareerServices ? config.palette.orange : config.palette.darkBlue};
  font-family: ${config.fonts.monoFont};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 114%;
  text-transform: uppercase;
  @media (max-width: ${config.resolutions.tablet}) {
    font-size: 1.2rem;
    line-height: 130%;
  }
  @media (max-width: ${config.resolutions.mobileL}) {
    line-height: 120%;
  }

  ${props => props.extend}
`;
