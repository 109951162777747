module.exports = {
  context: {
    url: '/br/impacto-social/bolsas-rappi',
    lang: 'br',
    breadcrumb: 'Bolsas Rappi',
    seo: {
      title: 'Ironhack Web Development Bootcamp & UX/UI Design Bootcamp',
      description:
        'Learn to code and UX/UI design through one of our full time or part time courses. Web Development Bootcamp and UX/UI Design Bootcamp in Madrid, Barcelona, Miami, Paris, Mexico City, Berlin and Lisbon.'
    },
    hreflangs: {
      br: '/br/impacto-social/bolsas-rappi',
      es: '/es/impacto-social/becas-rappi'
    },
    header: {
      title: 'Bolsas de Estudo +',
      titleHighlighted: 'Ironhack',
      backgroundImage: 'rappi/header.png',
      alternativeLogo: 'rappi/rappi.png',
      alternativeLogoAltText: 'Rappi logo',
      paragraph:
        'Rappi e Ironhack se uniram para oferecer mais de R$300 Mil em bolsas de estudo para formar profissionais nos premiados cursos de Desenvolvimento Web, UX/UI Design e Data Analytics da Ironhack! Investimos no desenvolvimento de profissionais em carreiras técnicas e queremos promover a inclusão de talentos no mercado de trabalho de tecnologia no Brasil.'
    },
    navbar: {
      text: [
        'Ironhack e Rappi se unem para promover a inclusão de talentos brasileiros no mercado de tecnologia oferecendo mais de R$300 Mil em bolsas para os cursos da Ironhack'
      ],
      linkText: 'Se inscreva para a bolsa!',
      linkHref: 'https://ironhack.typeform.com/to/HGpTRr'
    },
    video: {
      href: 'https://www.youtube.com/embed/KNxfcUUTK6Y'
    },
    valueProposition: {
      content: [
        {
          title: 'Porque Ironhack e Rappi querem promover a educação?',
          text: [
            'Com esta parceria você poderá participar do processo seletivo para ganhar uma bolsa de estudos, e assim se tornar um Desenvolvedor Web, UX / UI Designer ou Analista de Dados. Estamos oferecendo várias bolsas integrais, no valor de R$ 17 Mil e parciais que variam entre 75% e 10%.',
            'Na Ironhack estamos convencidos que todos merecem a oportunidade de alcançar seus sonhos e se reinventar, independentemente de sua experiência profissional ou acadêmica anterior. Queremos te dar a chance de mudar de carreira em algumas semanas e assim criar um novo e apaixonante futuro para sua vida.'
          ]
        },
        {
          title: 'Mais de 300 mil Reais em bolsas',
          text: [
            '3 vencedores receberão a bolsa integral e mais de 60 vencedores receberão bolsas parciais entre de 75% a 10%).',
            'Com esta parceria você poderá participar do processo seletivo para ganhar uma bolsa de estudos, e assim se tornar um Desenvolvedor Web, UX / UI Designer ou Analista de Dados. Estamos oferecendo várias bolsas integrais, no valor de R$ 17 Mil e parciais que variam entre 75% e 10%'
          ]
        },
        {
          title: 'Cómo funciona?:',
          text: [
            '1. Se inscreva para a bolsa para concorrer às nossas bolsas de estudo',
            '2. Preencha o formulário com seus dados de contato, o curso e data de início desejada',
            '3. Você receberá por email o material de estudo para a prova técnica e assim poderá começar com o processo seletivo'
          ],
          isList: true
        }
      ]
    },
    courses: {
      title: 'Nossos cursos',
      courses: [
        {
          name: 'Desenvolvimento Web',
          description:
            'Vire um desenvolvedor Full-Stack em 9 semanas. Abordamos do Front-End ao Back-End usando tecnologias super requisitadas no mercado como JavaScript, React, NodeJS, Express, MongoDB, metodologias ágeis e muito mais.',
          courseFormats: [
            {
              duration: '9 SEMANAS // TEMPO INTEGRAL',
              name: 'Full Time Program',
              schedule: 'Seg - Sex, 9:00 - 18:00'
            },
            {
              duration: '28 SEMANAS // MEIO PERÍODO',
              name: 'Part Time Program',
              schedule: 'Ter - Qui, 19:30 pm - 22:30 pm, Sáb 10h00 - 17h00'
            }
          ]
        },
        {
          name: 'UX/UI Design Track',
          description:
            'Transforme sua carreira, vire um UX/UI Designer. Aprenda Design Centrado no Usuário, baseados no pensamento de Design Thinking. O curso inclui todas as áreas de conhecimento de UX, desde como investigar usuários, gerar ideias até prototipação e teste. Além disso, você aprenderá todo o processo na prática, finalizando o curso com pelo menos três projetos no portfólio. E, no fim, participa de cinco dias com empresas na nossa Hiring Week.',
          courseFormats: [
            {
              duration: '9 SEMANAS // TEMPO INTEGRAL',
              name: 'Full Time Program',
              schedule: 'Seg - Sex, 9:00 - 18:00'
            },
            {
              duration: '28 SEMANAS // MEIO PERÍODO',
              name: 'Part Time Program',
              schedule: 'Ter - Qui, 19:30 pm - 22:30 pm, Sáb 10h00 - 17h00'
            }
          ]
        },
        {
          name: 'Data Analytics Track',
          description:
            'Vire um mestre em análise de dados. Aprenda a limpar e explorar diferentes conjuntos de dados. Aprenda a usar Pandas para converter seus dados em visualizações incríveis que possam contar histórias. Termine o curso aprendendo Machine Learning e aplicando seus conhecimentos em conjuntos de dados reais!',
          courseFormats: [
            {
              duration: '9 SEMANAS // TEMPO INTEGRAL',
              name: 'Full Time Program',
              schedule: 'Seg - Sex, 9:00 - 18:00'
            },
            {
              duration: '28 SEMANAS // MEIO PERÍODO',
              name: 'Part Time Program',
              schedule: 'Ter - Qui, 19:30 pm - 22:30 pm, Sáb 10h00 - 17h00'
            }
          ]
        }
      ]
    },
    careerSupport: {
      title: 'Apoio profissional',
      text:
        'Ironhack se esforça para que você consiga um novo emprego assim que concluir o curso. Com o teu trabalho e nosso apoio temos certeza que você conseguirá alcançar teus objetivos profissionais.',
      logos: [
        {
          name: 'Accenture',
          image: 'accenture.png'
        },
        {
          name: 'C6 Bank',
          image: 'c6-bank.png'
        },
        {
          name: 'Creditas',
          image: 'creditas.jpg'
        },
        {
          name: 'Id wall',
          image: 'id-wall.png'
        },
        {
          name: 'Luiza Labs',
          image: 'luiza-labs.png'
        },
        {
          name: 'Bee Tech',
          image: 'bee-tech.png'
        },
        {
          name: 'Stefanini',
          image: 'stefanini.jpg'
        },
        {
          name: 'Tela Vita',
          image: 'telavita.png'
        }
      ]
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Que cursos estão incluídos?',
          answer:
            'Estas bolsas te permitirão estudar em qualquer um dos cursos de Desenvolvimento Web, UX/UI Design ou Data Analytics da Ironhack em São Paulo com data de início até agosto. Você escolhe a data do curso que te interessar e também pode optar pelo curso em período integral (todos os dias, durante 9 semanas, das 9h às 18h) ou meio período (3 noites por semana das 19h30 às 22h30 e 2 sábados por mês das 9h às 18h, durante 28 semanas).'
        },
        {
          question: 'O que acontece se ganho a bolsa?',
          answer: [
            'Se prepare para uma experiência incrível e intensiva!',
            'Sim, você terá que estudar muito, mas a recompensa valerá a pena!'
          ]
        },
        {
          question: 'Como funciona o processo seletivo?',
          answer: [
            '-O Processo seletivo consiste em uma prova técnica e uma entrevista pessoal',
            '-Ao clicar no link "inscreva-se na Bolsa" você será redirecionado à uma página onde você deverá escolher o curso que deseja e a data que deseja iniciar, assim como nos informar seus dados de contato',
            '-Depois disso, receberá um e-mail com materiais para estudar para a prova técnica online relacionada ao curso que escolheu',
            '-O e-mail também terá o link para você realizar a prova técnica online após estudar o material',
            '-Os professores da Ironhack irão avaliar as respostas. Os melhores classificados irão passar por uma conversa online com nosso time de Admissions',
            '-Os mais qualificados na soma das duas etapas receberão a oferta de bolsa de estudo que irá variar de acordo com a classificação'
          ]
        },
        {
          question: 'Quem pode aplicar?',
          answer:
            'Qualquer pessoa apartir de 18 anos de idade. Não é necessário ter experincia prévia para fazer os cursos! Só muita vontade e motivação.'
        },
        {
          question: 'Como serão anunciados os resultados?',
          answer:
            'Para que o processo seja o mais simples para você, desenhamos uma prova técnica online que você poderá completar usando um computador ou celular. Ao concluir a prova técnica, agende uma entrevista online com alguém do time da Ironhack.'
        },
        {
          question: 'Qual é a data limite?',
          answer: [
            'Última data para aplicar para a bolsa: 24/05.',
            'Última data para realizar a prova técnica: 24/05.',
            'Anunciaremos os vencedores em 2 etapas:',
            '· Primeira Etapa: 5/5 primeira etapa.',
            '· Segunda Etapa: 27/05.'
          ]
        }
      ]
    },
    campus: {
      title: 'Conheça nossa Hiring Week',
      campus: {
        name: 'Campus Ironhack São Paulo',
        video: 'https://www.youtube.com/embed/Nssdb6rir8U',
        address1: 'Alameda Jaú 1301, São Paulo, SP, 01420-001'
      }
    },
    moreInfo: {
      text: 'Tem alguma dúvida?',
      linkText: 'Fale Conosco!',
      linkHref: 'mailto:bolsas.rappi@ironhack.com'
    },
    termsAndConditions: {
      text: 'Termos e condições',
      href:
        'https://s3-eu-west-1.amazonaws.com/ih-web/becas-rappi-sao-paulo-2019.pdf'
    }
  }
};
