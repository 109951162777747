import React from 'react';
import Layout from '../atoms/layout';
import Eyebrow from '../atoms/titles/eyebrow';
import { Text4 } from '../atoms/body';
import {
  SectionTitle,
  SectionButtons,
  Contextual,
  EyebrowCss,
  Contact
} from './styles';

interface Props {
  eyebrow: string;
  title: string;
  description: string;
  linkText: string;
  linkHref: string;
  className?: string;
}

function ContextualCtaGeneric(props: Props): JSX.Element {
  const {
    eyebrow,
    title,
    description,
    linkText,
    linkHref,
    className = ''
  } = props;

  return (
    <Layout extend={Contextual} className={className}>
      <SectionTitle>
        <Eyebrow extend={EyebrowCss} className={className}>
          {eyebrow}
        </Eyebrow>
        <b>{title}</b>
        <Text4 as="p">{description}</Text4>
      </SectionTitle>
      <SectionButtons>
        <Contact href={linkHref} className={className}>
          {linkText}
        </Contact>
      </SectionButtons>
    </Layout>
  );
}

export default ContextualCtaGeneric;
