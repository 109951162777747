module.exports = {
  context: {
    url: '/en/web-development/selina',
    lang: 'en',
    breadcrumb: 'Selina',
    seo: {
      deindexed: true,
      title:
        'Learn Web Development in a immersive 10-week bootcamp experience, on the Mexican Pacific.',
      description:
        'Hack the digital nomad lifestyle and attend an Ironhack bootcamp while staying at Selina, Puerto Escondido',
    },
    hreflangs: {},
    header: {
      title: 'Learn Web Development in a immersive 10-week bootcamp experience',
      titleHighlighted: 'on the Mexican Pacific.',
      backgroundImage: 'selina/header.jpg',
      alternativeLogo: 'selina/logo.png',
      alternativeLogoAltText: 'Selina logo',
      paragraph:
        'Selina and Ironnhack come together to offer you an exclusive educational experience in Puerto Escondido. ',
    },
    navbar: {
      text: [
        'Learn web development on the beach June 15-Aug 21 or Aug 31-Nov 6',
      ],
      linkText: 'Get Started',
      linkHref: 'https://ironhack.typeform.com/to/m7C573',
    },
    video: {
      href: 'https://www.youtube.com/embed/6qBTAY4phVk',
    },
    valueProposition: {
      content: [
        {
          title: 'Hack the digital nomad lifestyle',
          text: [
            "Known as one of the highest-rated bootcamps in the world, Ironhack is an authentic coding experience training over 4000 graduates since 2013. Over the course of 10 weeks, you will develop high-demanded skills like problem solving, and how one goes about creating an efficient, full-stack web applications. It's ever-adapting curriculum is taught across 11 different global cities and in five different languages. The bootcamp is housed in the Selina, Puerto Escondido, an international mecca for surfers and digital nomads where you will have countless opportunities to meet new people and go on guided excursions.",
          ],
        },
        {
          title: 'Selina and Ironhack bring you the best of two worlds',
          text: [
            'The Ironhack program invites you to learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. The course modules are project-oriented, enabling students to design and build full-stack JavaScript web applications and new programming that focuses on battle-tested patterns and quality practices. Towards the end of the course, you will produce a final project in which they are required to build a full-stack application from scratch.',
            'While staying at Selina Puerto Escondido, you will enjoy authentic Oaxacan dishes at the in-house restaurant. Meat, fish, vegetarian and vegan options are available. For some fresh air, feel free to walk around the surrounding area of Zicatela and discover local coffee shops, food markets and surf shops at every turn. In the evenings, explore the array of bars by the beach where and meet friendly groups of locals, travellers and even fellow coders!',
          ],
        },
        {
          title: "Here's what your day will look like",
          text: [
            '8:00am Time to rise and shine in Selina Puerto Escondido!',
            '8:30am Head over to the yoga deck for a morning meditation and yoga session.',
            '9:15am Refuel with a healthy breakfast smoothie at the restaurant before starting class.',
            '9:30am First block of classes begin. This is the theory needed to give life to your project.',
            '11:30am Time to code. During this time you will be programing and applying the concepts learned in the first block.',
            '12:30pm Enjoy a traditional Mexican lunch made with fresh, locally-sourced ingredients.',
            '1:30pm Return to class for afternoon exercises, work sessions or group projects.',
            '5:30pm Wrap up for the day!',
          ],
          isList: true,
        },
        {
          title:
            'All of the packages include breakfast, lunch, room and Ironhack course.',
          text: [
            '<b>Ironhack @ Selina Community Room package: 15,000 USD*</b><br />Community Rooms are furnished with custom made, private bunk beds and super thick mattresses to guarantee a good night’s sleep. Personal amenities include a bedside light, shelf, and locker. Bathrooms are shared.',
            '<b>Ironhack @ Selina Micro Room - Shared Bathroom package: 16,000 USD*</b><br />Similar to a ship or train cabin, the Micro room is the most basic sleeping solution. Features includes a queen sized bed and shared bathroom with 1 or 2 other Micro rooms. Linens and towels are included.',
            '<b>Ironhack @ Selina Relax Room/Standard Room - Queen Size Bed package: 17,000 USD*</b><br />For guests who want some privacy to unwind, our Relax Rooms and Standard Rooms come with a private expansive balcony, lounge area and super sized beds.',
          ],
        },
      ],
    },
    courses: {
      title: 'Our Course Tracks',
      courses: [
        {
          name: 'Web Development Track',
          description:
            'Become a Full-Stack Web Developer. Learn the fundamentals of HTML5, CSS3, JavaScript, React, TypeScript, Express, MongoDB and Testing with agile methodologies. We offer this track in two modalities:',
          courseFormats: [
            {
              duration: '9 WEEKS // FULL TIME',
              name: 'Full Time Program',
              schedule:
                '9 weeks intensive programs Monday to Friday from 9:00 to 18:00.',
            },
          ],
        },
      ],
    },
    careerSupport: {
      title: 'Career Services',
      text: [
        "Worldwide we've helped thousands of students land their dream job in tech. Find out how we will help you.",
        "During our 5+ years helping students achieve success, we've developed a thorough understanding of what it takes to land a job in the digital ecosystem.",
        'Behind our success is a strong network of local and global hiring partners, a dedicated outcomes team, and a deep knowledge of the tech job market and how to navigate it.',
        'Our career guidance is characterized by one-on-one mentorship and exposure to our local tech ecosystems. This service includes optimizing your digital profiles, preparing you for interviews, and helping you identify suitable options. We will make sure that you are ready to enter the job market and will support you until your first day at work!',
      ],
      logos: [
        {
          name: 'AeroMexico',
          image: 'aeromexico.jpg',
        },
        {
          name: 'Bitso',
          image: 'bitso.png',
        },
        {
          name: 'Clip',
          image: 'clip.jpg',
        },
        {
          name: 'BBVA',
          image: 'bbva.jpg',
        },
        {
          name: 'Globant',
          image: 'globant.png',
        },
        {
          name: 'Konfio',
          image: 'konfio.png',
        },
        {
          name: 'Kubo Financiero',
          image: 'kubo-financiero.jpg',
        },
        {
          name: 'Rappi',
          image: 'rappi.png',
        },
      ],
    },
    faq: {
      title: 'Frequently Asked Questions',
      items: [
        {
          question: 'Do I need any programming skills or experience?',
          answer:
            "You'll need to have a genuine interest in tech and coding and be extremely motivated. The course moves very quickly. While we work tirelessly to make sure you don't fall behind, your engagement is key. It's important that you've had some introduction to coding, whether it was an intro to programming in college, or an online course on a platform like Treehouse or Codecademy.",
        },
        {
          question: 'Why do you teach full-stack JavaScript?',
          answer:
            'JavaScript is currently the most popular programming language on the planet because it can be executed in every internet browser. JavaScript also has one of the largest and most engaged developer communities in the world, making it easy to find mentors and learn from their knowledge and experience. Companies around the world like Amazon, Netflix, Spotify, and Airbnb are using JavaScript to build their apps, making it one of the most in-demand technical skills today.',
        },
        {
          question:
            'If I already know how to code, is the web development bootcamp right for me?',
          answer:
            "Yes! In fact, many of our students have computer science degrees. During the course, you'll not only learn to code, but you'll also learn to use professional tools like GitHub and the workflows that many startups and software companies are using. Additionally, you'll learn software development best practices, SOLID principles, and other technologies like Mongo, Express, React and Node.",
        },
        {
          question: 'When should I apply and what is Pre-work?',
          answer: [
            'The sooner, the better. Because we have limited availability, our classes fill up quickly. Reducing class sizes helps us to maintain an excellent teacher-student ratio and ensures the highest quality of instruction.',
            'Also, you’ll be asked to complete 40-60 hours of online pre-work before the course begins The sooner you get started with that, the better prepared you’ll be for the start of the course.',
          ],
        },
        {
          question: 'Is room and food included?',
          answer:
            'Yes, room and 2 meals a day are included in the price of the bootcamp. You can upgrade or downgrade you room package as desired, subject to availability.',
        },
        {
          question:
            'Will I be able to create my own project after the bootcamp?',
          answer:
            "Absolutely! By the time you finish your Ironhack bootcamp, you'll have the tools and skills necessary to develop either a full web application (Web Development). Plus, if your project is voted as one of the top projects in your cohort, then you'll be able to present it to a live audience at our Hackshow (Demo Day)!",
        },
        {
          question: 'What are the deadlines and important dates?',
          answer: [
            'Deadline to pay course deposit and reserve your spot is 3 weeks month before course start date, and subject to availability.',
            'Deadline to pay entire class is 3 weeks before course start date.',
            'Pre-work must be completed before course begins.',
            'Arrive to Selina at 9am the day of course start date.',
          ],
        },
      ],
    },
    moreInfo: {
      text: 'Contact us to receive more information',
      linkText: 'Contact us',
      linkHref: 'mailto:contactosmex@ironhack.com',
    },
  },
};
